import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-dialog-modal-wrapper',
    template: `
        <div
            class="w-screen max-h-svh"
            [class.h-svh]="fullScreen"
            [style.max-width]="maxWidth && !fullScreen ? maxWidth : null">
            <div
                class="flex flex-col max-h-full overflow-hidden bg-white shadow-card"
                [class.m-4]="!fullScreen"
                [class.h-full]="fullScreen"
                [class.rounded-md]="!fullScreen">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./dialog-modal-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class DialogModalWrapperComponent {
    @Input() maxWidth: string = '600px';
    @Input() fullScreen = false;
}
