import { AsyncPipe, NgForOf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClientShortProfileComponent } from '../../client/components/client-short-profile/client-short-profile.component';
import { DefaultLayoutWithNavigationComponent } from '../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { InfoService } from '../../shared/info.service';
import { SharedModule } from '../../shared/shared.module';
import { User } from '../../user/+store/user.model';
import { selectNonNullUser } from '../../user/+store/user.selectors';
import { InputEditorModalComponent } from '../../shared/custom-inputs/input-editor-modal/input-editor-modal.component';
import { ClientNotesComponent } from '../../client/components/notes/client-notes.component';

@Component({
    selector: 'app-dashboard',
    standalone: true,
    template: `
        <app-default-layout-with-navigation
            [showLogoutButton]="true"
            [showBackButton]="false"
            [centeredHorizontally]="true"
            [backgroundColorTop]="true">
            <div class="w-full bg-beige">
                <div class="max-w-[1000px] w-full px-4 py-12 mx-auto flex flex-col gap-y-5">
                    <app-profile-header [user]="user$ | async"></app-profile-header>
                    <app-main-module-selection></app-main-module-selection>
                    <app-client-notes></app-client-notes>
                </div>
            </div>
            <section class="w-full">
                <div class="max-w-[1000px] w-full mx-auto px-4 py-4">
                    <app-client-short-profile></app-client-short-profile>
                </div>
            </section>

            <!--    <div class="my-auto">-->
            <!--        <h1 class="text-center mb-[75px] font-serif">Hauptmenü</h1>-->
            <!--        <div class="flex flex-col justify-center items-center">-->
            <!--            <nav class="flex flex-row flex-wrap max-w-[810px]">-->
            <!--                <app-dashboard-card-->
            <!--                    title="Lebensgeschichte"-->
            <!--                    description="Halte dein Leben für dich und deine Familie fest."-->
            <!--                    [icon]="faBooks"-->
            <!--                    [link]="['/lebensgeschichte']"-->
            <!--                    cardClass="bg-[#518CCE]"-->
            <!--                    circleClass="text-[#518CCE]"-->
            <!--                ></app-dashboard-card>-->

            <!--                <app-dashboard-card-->
            <!--                    title="Netzwerk"-->
            <!--                    description="Finde neue Freunde - verbinde dich mit anderen Teresa Nutzern"-->
            <!--                    [icon]="faUsers"-->
            <!--                    [link]="['/channel']"-->
            <!--                    cardClass="bg-[#EB7D3C]"-->
            <!--                    circleClass="text-[#EB7D3C]"-->
            <!--                ></app-dashboard-card>-->

            <!--                <app-dashboard-card-->
            <!--                    title="Wohlbefinden"-->
            <!--                    description="Erfasse dein körperliches & emotionales Wohlbefinden."-->
            <!--                    [icon]="faHeartBeat"-->
            <!--                    [link]="['/']"-->
            <!--                    cardClass="bg-[#D34E6F]"-->
            <!--                    circleClass="text-[#D34E6F]"-->
            <!--                ></app-dashboard-card>-->

            <!--                <div class="w-full mt-16"></div>-->

            <!--                <app-dashboard-card-->
            <!--                    title="Erinnerungen"-->
            <!--                    description="Plane deine Termine und halte deine Aufgaben fest"-->
            <!--                    [icon]="faList"-->
            <!--                    [link]="['/']"-->
            <!--                    cardClass="bg-[#2DB5BF]"-->
            <!--                    circleClass="text-[#2DB5BF]"-->
            <!--                ></app-dashboard-card>-->

            <!--                <app-dashboard-card-->
            <!--                    title="Kontakte"-->
            <!--                    description="Kontaktiere deine liebsten Menschen"-->
            <!--                    [icon]="faPhone"-->
            <!--                    [link]="['/']"-->
            <!--                    cardClass="bg-[#84BE7C]"-->
            <!--                    circleClass="text-[#84BE7C]"-->
            <!--                ></app-dashboard-card>-->

            <!--                <app-home-card-profile></app-home-card-profile>-->
            <!--            </nav>-->
            <!--        </div>-->
            <!--    </div>-->
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./dashboard-index.component.scss'],
    imports: [
        SharedModule,
        AsyncPipe,
        ClientShortProfileComponent,
        ReactiveFormsModule,
        DefaultLayoutWithNavigationComponent,
        InputEditorModalComponent,
        NgForOf,
        ClientNotesComponent,
    ],
})
export class DashboardIndexComponent implements OnInit {
    user$!: Observable<User>;

    store = inject(Store);
    infoService = inject(InfoService);

    ngOnInit(): void {
        this.infoService.setCurrentPage('dashboard');
        this.user$ = this.store.pipe(selectNonNullUser());
    }
}
