import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, first, skip, switchMap } from 'rxjs/operators';
import { getNewAccessToken, invalidToken } from './+store/auth.actions';
import { selectAccessToken, selectAuthState } from './+store/auth.selectors';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private token: string | null = null;
    private refreshToken: string | null = null;
    private exp: any = null;

    constructor(private store: Store<any>) {
        this.store.pipe(select(selectAuthState)).subscribe(state => {
            this.token = state.accessToken;
            this.refreshToken = state.refreshToken;
            this.exp = state.exp;
        });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const fullUrl = req.url.startsWith('http')
            ? req.url
            : location.origin + req.url;
        if (
            this.token &&
            !req.url.includes('/auth/access-token') &&
            !req.url.includes('/auth/logout') &&
            new URL(fullUrl).pathname !== '/'
        ) {
            if (!!this.exp) {
                if (this.exp < Date.now() / 1000 + 5) {
                    this.store.dispatch(
                        getNewAccessToken({ refreshToken: this.refreshToken! }),
                    );
                    return this.store.pipe(
                        select(selectAccessToken),
                        skip(1),
                        first(),
                        switchMap(token =>
                            next.handle(this.addBearerToken(req, token)).pipe(
                                catchError(err => {
                                    if (
                                        err instanceof HttpErrorResponse &&
                                        err.status === 401
                                    ) {
                                        this.store.dispatch(invalidToken());
                                    }
                                    throw err;
                                }),
                            ),
                        ),
                    );
                }
            }
            return next.handle(this.addBearerToken(req, this.token)).pipe(
                catchError(err => {
                    if (
                        err instanceof HttpErrorResponse &&
                        err.status === 401
                    ) {
                        this.store.dispatch(invalidToken());
                    }
                    throw err;
                }),
            );
        }

        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.store.dispatch(invalidToken());
                }
                throw err;
            }),
        );
    }

    addBearerToken(
        request: HttpRequest<any>,
        token: string | null,
    ): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'X-Authorization': `Bearer ${token}`,
            },
        });
    }
}
