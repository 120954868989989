import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../app.reducer';
import { UserActions } from '../../user/+store/user.actions';
import { AuthService } from '../auth.service';
import {
    AuthActions,
    clearAll,
    clearRedirectUrl,
    getNewAccessToken,
    invalidToken,
    login,
    loginFailure,
    loginSuccess,
    setPassword,
    setPasswordFailure,
    setPasswordSuccess,
    signup,
    signupFailure,
    signupSuccess,
} from './auth.actions';
import { selectAuthState } from './auth.selectors';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(login),
            withLatestFrom(this.store.pipe(select(selectAuthState))),
            exhaustMap(([action, authState]) =>
                this.authService.login(action.credentials).pipe(
                    map(response =>
                        loginSuccess({
                            accessToken: response.accessToken,
                            refreshToken: response.refreshToken,
                        }),
                    ),
                    tap(() => {
                        if (authState.redirectUrl !== null) {
                            this.router.navigate([authState.redirectUrl]);
                            this.store.dispatch(clearRedirectUrl());
                        } else {
                            this.router.navigate(['/dashboard']);
                        }
                        this.toast.success('Eingeloggt', { duration: 2000 });
                    }),
                    catchError(err => {
                        return of(loginFailure({ error: err }));
                    }),
                ),
            ),
        ),
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginSuccess),
            map(() => UserActions.loadMe()),
        ),
    );

    signup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signup),
            withLatestFrom(this.store.pipe(select(selectAuthState))),
            exhaustMap(([action, authState]) =>
                this.authService.signup(action.email).pipe(
                    map(response => signupSuccess()),
                    catchError(error => {
                        return of(signupFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    signupSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(signupSuccess),
                tap(() => {
                    this.router.navigate(['/auth', 'email-sent']);
                    this.toast.success('Erfolgreich');
                }),
            ),
        { dispatch: false },
    );

    setPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setPassword),
            switchMap(action =>
                this.authService.setPassword(action).pipe(
                    map(response => setPasswordSuccess()),
                    tap(x => this.router.navigate(['/auth', 'login'])),
                    catchError(err => {
                        return of(setPasswordFailure({ error: err }));
                    }),
                ),
            ),
        ),
    );

    getRefreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getNewAccessToken),
            exhaustMap(action =>
                this.authService.getNewAccessToken(action.refreshToken).pipe(
                    map(response =>
                        loginSuccess({
                            accessToken: response.accessToken,
                            refreshToken: response.refreshToken,
                        }),
                    ),
                    catchError(e => {
                        return from([loginFailure({ error: e }), invalidToken()]);
                    }),
                ),
            ),
        ),
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            map(() => clearAll()),
            tap(() => this.router.navigate(['/auth', 'login'])),
        ),
    );

    invalidToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invalidToken),
            map(() => AuthActions.logout()),
        ),
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private toast: HotToastService,
        private store: Store<AppState>,
    ) {}
}
