import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Credentials } from './auth.reducer';

export const AuthActions = createActionGroup({
    source: 'AUTH',
    events: {
        Logout: emptyProps(),
    },
});

export const login = createAction('[Auth] login', props<{ credentials: Credentials }>());
export const loginSuccess = createAction(
    '[Auth] login success',
    props<{ accessToken: string; refreshToken: string }>(),
);
export const loginFailure = createAction('[Auth] login failure', props<{ error: any }>());

export const signup = createAction('[Auth]  signup', props<{ email: string }>());
export const signupSuccess = createAction('[Auth] signup success');
export const signupFailure = createAction('[Auth] signup failure', props<{ error: any }>());

export const setPassword = createAction(
    '[Auth] set password',
    props<{ password: string; token: string; caretaker?: boolean }>(),
);
export const setPasswordSuccess = createAction('[Auth] set password success');
export const setPasswordFailure = createAction('[Auth] set password failure', props<{ error: any }>());

export const invalidToken = createAction('[Auth] delete invalid token');
export const getNewAccessToken = createAction('[Auth] get new access token', props<{ refreshToken: string }>());
export const clearAuth = createAction('[Auth] clear auth state');

export const setRedirectUrl = createAction('[Auth] set redirect url', props<{ url: string }>());
export const clearRedirectUrl = createAction('[Auth] clear redirect url');

export const clearAll = createAction('[Auth] clear all');
