import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SubUserActions } from '../../+store/sub-user.actions';
import { selectSubUserSavingInProgress } from '../../+store/sub-user.selectors';
import { ClientProfileCoreFormComponent } from '../../../client/components/client-profile-core-form/client-profile-core-form.component';
import { ClientProfileInfoFormComponent } from '../../../client/components/client-profile-info-form/client-profile-info-form.component';
import { ClientProfilePhotoFormComponent } from '../../../client/components/client-profile-photo-form/client-profile-photo-form.component';
import { ClientProfileTabNavigationComponent } from '../../../client/components/client-profile-tab-navigation/client-profile-tab-navigation.component';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { User } from '../../../user/+store/user.model';
import { CaretakerRouteNames } from '../../caretaker-routing';
import { SubUserCreateControls } from '../../interfaces/subuser-create.interface';
import { Dialog } from '@angular/cdk/dialog';
import { CanDeactivateService } from '../../../shared/services/can-deactivate.service';

export enum CreateSubUserFragments {
    coreData = 'stammdaten',
    information = 'informationen',
    photo = 'foto',
}

@UntilDestroy()
@Component({
    selector: 'app-caretaker-create-subuser',
    standalone: true,
    imports: [
        AsyncPipe,
        DefaultLayoutWithNavigationComponent,
        FaIconComponent,
        NgIf,
        ReactiveFormsModule,
        SharedModule,
        ClientProfileCoreFormComponent,
        ClientProfilePhotoFormComponent,
        ClientProfileTabNavigationComponent,
        FormsModule,
        JsonPipe,
        ClientProfileInfoFormComponent,
    ],
    template: `
        <app-default-layout-with-navigation>
            <div class="flex items-center flex-col bg-white p-5 w-[1020px] max-w-full mx-auto">
                <div class="font-bold text-gray-400 text-sm self-start mb-4">
                    <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                    Profil anlegen
                </div>

                <app-client-profile-tab-navigation></app-client-profile-tab-navigation>

                <form (ngSubmit)="save()" class="flex flex-col mt-11 group w-full items-center" [formGroup]="form">
                    <app-client-profile-core-form
                        [formGroup]="form"
                        *ngIf="fragment() === fragments.coreData || !fragment()"></app-client-profile-core-form>
                    <app-client-profile-info-form
                        [formGroup]="form"
                        *ngIf="fragment() === fragments.information"></app-client-profile-info-form>
                    <app-client-profile-photo-form
                        [formGroup]="form"
                        *ngIf="fragment() === fragments.photo"
                        [user]="user"
                        infoText="Ein Profilfoto kann auch später hinzugefügt werden.">
                    </app-client-profile-photo-form>

                    <div
                        class="group-[.ng-invalid.ng-submitted]:block hidden text-center text-red-600 font-bold my-4 w-full mx-auto">
                        Bitte überprüfen Sie Ihre Eingaben in den Stammdaten.
                    </div>

                    <div
                        class="flex flex-col md:flex-row flex-col-reverse gap-y-2 items-center justify-between mt-6 mx-auto">
                        <app-button
                            color="transparent"
                            [link]="['/', careTakerRouterNames.prefix]"
                            *ngIf="isFirstStep(); else stepBack"
                            >Abbrechen
                        </app-button>

                        <ng-template #stepBack>
                            <app-button color="transparent" type="button" (click)="step('prev')">Zurück</app-button>
                        </ng-template>

                        <app-button
                            type="submit"
                            [loading]="savingInProgress$ | async"
                            [disabled]="savingInProgress$ | async"
                            *ngIf="isLastStep(); else continueButton"
                            >Nutzer erstellen
                        </app-button>
                        <ng-template #continueButton>
                            <app-button type="button" (click)="step('next')">Weiter</app-button>
                        </ng-template>
                    </div>
                </form>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrl: './caretaker-create-subuser.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaretakerCreateSubuserComponent implements OnInit {
    savingInProgress$!: Observable<boolean>;
    user?: User;
    form!: FormGroup<SubUserCreateControls>;

    careTakerRouterNames = CaretakerRouteNames;
    fragments = CreateSubUserFragments;
    formCreateSteps: string[] = Object.values(CreateSubUserFragments);

    // cdr = inject(ChangeDetectorRef);
    fb = inject(NonNullableFormBuilder);
    store = inject(Store);
    route = inject(ActivatedRoute);
    router = inject(Router);
    dialog = inject(Dialog);
    canDeactivateService = inject(CanDeactivateService);

    fragment = signal('');
    isFirstStep = computed(() => {
        return this.formCreateSteps[0] === this.fragment();
    });
    isLastStep = computed(() => {
        return this.formCreateSteps[this.formCreateSteps.length - 1] === this.fragment();
    });

    ngOnInit() {
        this.form = this.fb.group({
            firstName: ['', Validators.required],
            lastName: [''],
            birthDate: [''],
            birthPlace: [''],
            city: [''],
            faith: [''],
            avatar: new FormControl<File | string>('', { nonNullable: true }),
            gender: [''],
            languages: new FormControl<string[]>([], { nonNullable: true }),
            relationship: [''],
            family: [''],
            pets: [''],
        });

        this.savingInProgress$ = this.store.pipe(select(selectSubUserSavingInProgress));

        //todo maybe better solution? high risk of cirular function calls
        this.route.fragment.pipe(untilDestroyed(this)).subscribe(fragment => {
            if (fragment) {
                this.fragment.set(fragment);
            } else {
                this.routeToFragment(CreateSubUserFragments.coreData);
            }
        });
    }

    step(dir: 'prev' | 'next') {
        const array = this.formCreateSteps;
        const i = array.indexOf(this.fragment());
        const prevFragment = i > 0 ? array[i - 1] : null;
        const nextFragment = i < array.length - 1 ? array[i + 1] : null;

        if (dir === 'prev' && prevFragment) {
            this.routeToFragment(prevFragment);
        } else if (dir === 'next' && nextFragment) {
            this.routeToFragment(nextFragment);
        }
    }

    routeToFragment(fragment: string) {
        this.router.navigate([], { fragment, replaceUrl: true });
    }

    canDeactivate = () => this.canDeactivateService.canDeactivate(this.form.dirty);

    save() {
        if (this.form.valid) {
            const formState = this.form.getRawValue();
            this.store.dispatch(
                SubUserActions.createSubUser({
                    subUser: formState,
                }),
            );
            // we reset form to unmark as dirty to allow redirect
            this.form.reset(formState);
        }
    }
}
