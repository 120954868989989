import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

// todo rethink backdrop handling
@Component({
    selector: 'app-action-menu',
    template: `
        <div class="relative">
            <button
                type="button"
                class="rounded-full backdrop-blur-2xl aspect-square w-10"
                (click)="toggleMenu()"
                [ngClass]="menuOpen() ? 'bg-white text-primary' : 'bg-primary/50'">
                <fa-icon [icon]="['fas', 'ellipsis-vertical']" size="lg"></fa-icon>
            </button>
            <ng-container *ngIf="menuOpen()">
                <div class="bg-white rounded-2xl absolute mt-2 right-0 top-full shadow-lg z-50" (click)="toggleMenu()">
                    <ng-content></ng-content>
                </div>
                <div class="inset-0 fixed z-10" (click)="toggleMenu()"></div>
            </ng-container>
        </div>
    `,
    styleUrls: ['./action-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent {
    public menuOpen = signal(false);

    // todo think about closing logic with click bubbling in menu
    public toggleMenu() {
        this.menuOpen.set(!this.menuOpen());
    }
}
