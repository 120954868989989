import {
    ContentChild,
    Directive,
    ElementRef,
    HostListener,
} from '@angular/core';
import { DropdownToggleDirective } from './dropdown-toggle.directive';
import { DropdownDirective } from './dropdown.directive';

@Directive({
    selector: '[appDropdownContainer]',
})
export class DropdownContainerDirective {
    @ContentChild(DropdownDirective) dropdownDirective!: DropdownDirective;
    @ContentChild(DropdownToggleDirective)
    dropdownToggleDirective!: DropdownToggleDirective;

    isOpen = false;

    constructor(private el: ElementRef) {}

    toggle() {
        this.isOpen = !this.isOpen;
        this.dropdownDirective.animate();
    }

    @HostListener('window:click', ['$event'])
    outsideClick(event: PointerEvent) {
        if (
            this.isOpen &&
            !this.el.nativeElement.contains(event.target) &&
            !this.dropdownToggleDirective.el.nativeElement.contains(
                event.target,
            )
        ) {
            this.toggle();
        }
    }
}
