import { Component, computed, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { LibraryEntryActions } from '../../+store/library-entry/library-entry.actions';
import {
    selectLibraryEntryAll,
    selectLibraryEntryLoading,
    selectLibraryEntryUnfinished,
    selectLibraryEntryWithImpulse,
    selectLibraryEntryWithoutImpulse,
} from '../../+store/library-entry/library-entry.selectors';
import { InfoService } from '../../../shared/info.service';
import { selectNonNullUser } from '../../../user/+store/user.selectors';
import { LayoutSwitchComponent, PageLayout } from '../../components/page-switch/layout-switch.component';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { FilterButtonComponent } from '../../../shared/components/filter-button/filter-button.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { StoryCollectionGalleryComponent } from '../../components/story-collection-gallery/story-collection-gallery.component';
import { StoryCollectionListComponent } from '../../components/story-collection-list/story-collection-list.component';
import { StoryCollectionEmptyComponent } from '../../components/story-collection-empty/story-collection-empty.component';

export enum FilterValue {
    all,
    withImpulse,
    withoutImpulse,
    openEntries,
}

@Component({
    selector: 'app-library-index',
    standalone: true,
    template: `
        <app-default-layout-with-navigation [loading]="loading()">
            <div class="bg-[#F6F6F6] py-5">
                <app-page-title [backButtonLink]="['../']">
                    <span>Lebensgeschichte von {{ user()?.firstName }}</span>
                    <app-layout-switch
                        right-slot
                        (switch)="layout.set($event)"
                        [currentLayout]="layout()"></app-layout-switch>
                </app-page-title>

                <div class="max-w-[1100px] mx-auto px-4 flex items-center gap-x-6 mt-9">
                    <div class="flex flex-wrap gap-x-6 gap-y-3">
                        <app-filter-button
                            [count]="libraryEntries().length"
                            (click)="filter.set(filterValues.all)"
                            [active]="filter() === filterValues.all">
                            Alle Erinnerungen
                        </app-filter-button>
                        <app-filter-button
                            [count]="libraryEntriesWithoutImpulse().length"
                            (click)="filter.set(filterValues.withoutImpulse)"
                            [active]="filter() === filterValues.withoutImpulse">
                            Freies Gespräch
                        </app-filter-button>
                        <app-filter-button
                            [count]="libraryEntriesWithImpulse().length"
                            (click)="filter.set(filterValues.withImpulse)"
                            [active]="filter() === filterValues.withImpulse">
                            Basierend auf Inspirationsfragen
                        </app-filter-button>
                        <app-filter-button
                            [count]="libraryEntriesUnfinished().length"
                            (click)="filter.set(filterValues.openEntries)"
                            [active]="filter() === filterValues.openEntries">
                            Laufende Gespräche
                        </app-filter-button>
                    </div>

                    <div class="ml-auto">
                        <app-button [link]="['anlegen']" color="primary" [wrap]="false"> Neue Erinnerung</app-button>
                    </div>
                </div>
            </div>

            <section class="my-6 max-w-[1100px] mx-auto px-4 w-full">
                <ng-container *ngIf="libraryCount() > 0; else noEntries">
                    <h3 class="text-xl font-bold text-primary mb-5">
                        <ng-container [ngSwitch]="filter()">
                            <span *ngSwitchCase="filterValues.all">Alle Lebenserinnerungen</span>
                            <span *ngSwitchCase="filterValues.withImpulse">Basierend auf Inspirationsfragen</span>
                            <span *ngSwitchCase="filterValues.withoutImpulse">Freie Erzählungen</span>
                            <span *ngSwitchCase="filterValues.openEntries">Noch nicht abgeschlossen</span>
                        </ng-container>
                    </h3>

                    <app-story-collection-gallery
                        *ngIf="layout() === PageLayout.grid; else listView"
                        [libraryEntries]="filteredLibraryEntries()"></app-story-collection-gallery>

                    <ng-template #listView>
                        <app-story-collection-list
                            [libraryEntries]="filteredLibraryEntries()"></app-story-collection-list>
                    </ng-template>
                </ng-container>

                <ng-template #noEntries>
                    <div *ngIf="!loading()">
                        <app-story-collection-empty></app-story-collection-empty>
                    </div>
                </ng-template>
            </section>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./library-index.component.scss'],

    imports: [
        DefaultLayoutWithNavigationComponent,
        SharedModule,
        LayoutSwitchComponent,
        FilterButtonComponent,
        NgSwitch,
        NgSwitchCase,
        StoryCollectionGalleryComponent,
        NgIf,
        StoryCollectionListComponent,
        StoryCollectionEmptyComponent,
    ],
})
export class LibraryIndexComponent implements OnInit {
    constructor(
        private infoService: InfoService,
        private store: Store,
    ) {}

    layout = signal<PageLayout>(PageLayout.list);
    filter = signal<FilterValue>(FilterValue.all);

    loading = toSignal(this.store.pipe(selectLibraryEntryLoading()));
    libraryEntries = toSignal(this.store.pipe(select(selectLibraryEntryAll)), { initialValue: [] });
    libraryEntriesWithImpulse = toSignal(this.store.pipe(selectLibraryEntryWithImpulse()), {
        initialValue: [],
    });
    libraryEntriesWithoutImpulse = toSignal(this.store.pipe(selectLibraryEntryWithoutImpulse()), { initialValue: [] });
    libraryEntriesUnfinished = toSignal(this.store.pipe(selectLibraryEntryUnfinished()), { initialValue: [] });

    libraryCount = computed(() => this.libraryEntries().length);
    user = toSignal(this.store.pipe(selectNonNullUser()));

    filteredLibraryEntries = computed(() => {
        if (this.filter() === FilterValue.withImpulse) {
            return this.libraryEntriesWithImpulse();
        } else if (this.filter() === FilterValue.withoutImpulse) {
            return this.libraryEntriesWithoutImpulse();
        } else if (this.filter() === FilterValue.openEntries) {
            return this.libraryEntriesUnfinished();
        }
        return this.libraryEntries();
    });

    ngOnInit(): void {
        this.infoService.setCurrentPage('library-index');

        this.store.dispatch(LibraryEntryActions.loadLibraryEntries());
    }

    filterValues = FilterValue;
    protected readonly PageLayout = PageLayout;
}
