import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConversationSelectionComponent } from '../../components/conversation-selection/conversation-selection.component';
import { SharedModule } from '../../../shared/shared.module';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';

@Component({
    selector: 'app-library-entry-start-creation',
    template: `
        <app-default-layout-with-navigation>
            <div class="grow flex flex-col items-center py-5">
                <app-page-title [backButtonLink]="['/lebensgeschichte']">Gesprächsauswahl</app-page-title>
                <div class="grow flex items-center max-w-full px-4">
                    <app-conversation-selection></app-conversation-selection>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./library-entry-start-creation.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ConversationSelectionComponent, SharedModule, DefaultLayoutWithNavigationComponent],
})
export class LibraryEntryStartCreationComponent {}
