import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateSubuserDto } from '../dtos/create-subuser.dto';
import { SubUser } from './sub-user.model';

export const SubUserActions = createActionGroup({
    source: 'Caretaker/API',
    events: {
        'Load All SubUsers': emptyProps(),
        'Load All SubUsers Success': props<{ subUsers: SubUser[] }>(),
        'Load All SubUsers Failure': props<{ error: any }>(),

        'Create SubUser': props<{ subUser: CreateSubuserDto }>(),
        'Create SubUser Success': props<{ subUser: SubUser }>(),
        'Create SubUser Failure': props<{ error: any }>(),
    },
});
