import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LibraryEntryActions } from '../+store/library-entry/library-entry.actions';

export const libraryEntryDetailResolver: ResolveFn<Observable<boolean>> = route => {
    const entryId = route.paramMap.get('id');
    const store = inject(Store);
    const actions = inject(Actions);
    const router = inject(Router);

    store.dispatch(LibraryEntryActions.loadLibraryEntryByID({ id: entryId ?? '' }));

    return actions.pipe(
        ofType(LibraryEntryActions.loadLibraryEntryByIDSuccess, LibraryEntryActions.loadLibraryEntryByIDFailure),
        first(),
        map(action => {
            if (action.type === LibraryEntryActions.loadLibraryEntryByIDSuccess.type) {
                if (action.libraryEntry.finished === false) {
                    const media = action.libraryEntry.media.find(media => media.purpose === 'audio-record');
                    router.navigate(['/lebensgeschichte', 'erinnerung-erstellen', entryId, media?.id]);
                    return true;
                } else {
                    return true;
                }
            } else {
                router.navigate(['/lebensgeschichte']);
                return false;
            }
        }),
    );
};
