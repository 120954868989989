import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    forwardRef,
    inject,
    Input,
    signal,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons';
import { faCamera, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { UserActions } from '../../../user/+store/user.actions';
import { User } from '../../../user/+store/user.model';
import { FaDuotoneIconComponent, FaIconComponent, FaLayersComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { ConfirmedClickDirective } from '../../directives/confirmed-click/confirmed-click.directive';

export enum AvatarUploadControlMode {
    create = 'create',
    update = 'update',
}

@Component({
    selector: 'app-avatar-upload-control',
    standalone: true,
    template: `
        <ng-template #cameraIcon>
            <fa-layers [fixedWidth]="true" size="2x">
                <fa-icon size="xl" [icon]="faCamera"></fa-icon>
                <fa-duotone-icon
                    [icon]="faCirclePlus"
                    [inverse]="false"
                    transform="up-10 left-11"
                    secondaryColor="#f6f6f6"
                    secondaryOpacity="1"></fa-duotone-icon>
            </fa-layers>
        </ng-template>

        <div class="aspect-square w-full">
            <label class="cursor-pointer group">
                <div
                    class="flex items-center justify-center h-full border-4 border-dotted border-[#384250] rounded-full"
                    *ngIf="!currentValue()">
                    <ng-container *ngTemplateOutlet="cameraIcon"></ng-container>
                </div>
                <div class="relative" *ngIf="currentValue()">
                    <div class="rounded-full overflow-hidden aspect-square">
                        <ng-container *ngIf="isFile(currentValue()); else existingPhoto">
                            <img
                                class="h-full w-full object-cover"
                                [src]="getBlob()"
                                alt="Ausgewähltes Profilfoto, bereit zum Speichern." />
                        </ng-container>
                        <ng-template #existingPhoto>
                            <img
                                class="h-full w-full object-cover hidden"
                                [avatarSrc]="avatarSrc()"
                                alt="Aktuelles Profilbild" />
                        </ng-template>
                        <div
                            class="bg-white/50 absolute inset-0 flex justify-center items-center flex-col invisible group-hover:visible rounded-full">
                            <ng-container *ngTemplateOutlet="cameraIcon"></ng-container>
                        </div>
                    </div>

                    <ng-container *ngIf="mode === AvatarUploadControlMode.update && avatarSrc(); else formClear">
                        <button
                            type="button"
                            (confirmedClick)="deleteAvatar()"
                            dialogType="ConfirmedDelete"
                            dialogBody="Möchtest du dein Bild wirklich löschen?">
                            <ng-container *ngTemplateOutlet="removeButtonContent"></ng-container>
                        </button>
                    </ng-container>
                    <ng-template #formClear>
                        <button type="button" (click)="clear($event)">
                            <ng-container *ngTemplateOutlet="removeButtonContent"></ng-container>
                        </button>
                    </ng-template>
                    <ng-template #removeButtonContent>
                        <div
                            tp="Bild entfernen"
                            class="bg-white absolute items-center rounded-full top-0 right-0 text-black flex justify-center w-[40px] aspect-square shadow">
                            <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
                        </div>
                    </ng-template>
                </div>
                <input type="file" class="hidden" accept="image/jpeg,image/png" (change)="onChange($event)" #input />
            </label>
        </div>
    `,
    styleUrls: ['./avatar-upload-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AvatarUploadControlComponent),
            multi: true,
        },
    ],
    imports: [
        FaLayersComponent,
        FaDuotoneIconComponent,
        FaIconComponent,
        NgIf,
        NgTemplateOutlet,
        SharedModule,
        ConfirmedClickDirective,
    ],
})
export class AvatarUploadControlComponent implements ControlValueAccessor {
    changeFn: any;
    // currentValue: File | string = '';
    faCamera = faCamera;
    faCirclePlus = faCirclePlus;
    faTimes = faTimes;
    touchFn: any;
    disabled = false;
    store = inject(Store);

    @ViewChild('input', { static: true })
    inputEl!: ElementRef<HTMLInputElement>;

    @Input()
    set user(user: User | undefined) {
        this._user.set(user ?? null);
    }

    @Input() mode: AvatarUploadControlMode = AvatarUploadControlMode.create;

    currentValue = signal<File | string>('');
    _user = signal<User | null>(null);

    avatarSrc = computed(() => {
        const v = this.currentValue();
        if (v && !this.isFile(v) && this._user()) {
            return this._user();
        }
        return null;
    });

    constructor(
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
    ) {}

    getBlob(): SafeResourceUrl | string {
        const v = this.currentValue();
        let url: string | SafeResourceUrl = '';

        if (v instanceof File) {
            url = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(v));
        }

        return url;
    }

    isFile(value: File | string) {
        return value instanceof File;
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files) {
            if (this.mode === AvatarUploadControlMode.create) {
                const file = target.files[0];
                this.changeFn(file);
                this.currentValue.set(file);
            } else {
                this.store.dispatch(UserActions.updateAvatar({ avatar: target.files[0] }));
            }
        }
        this.cdr.markForCheck();
    }

    clear(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.changeFn(null);
        this.currentValue.set('');
        this.inputEl.nativeElement.value = '';
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = true;
    }

    writeValue(url: string): void {
        this.currentValue.set(url);
    }

    deleteAvatar() {
        this.store.dispatch(UserActions.deleteAvatar());
    }

    protected readonly AvatarUploadControlMode = AvatarUploadControlMode;
}
