import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { DashboardIndexComponent } from './dashboard-index/dashboard-index.component';

export const dashboardRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: DashboardIndexComponent,
        canActivate: [AuthGuard],
    },
];
