import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from '../+store/user.model';
import { environment } from '../../../environments/environment';
import { SubUser } from '../../caretaker/+store/sub-user.model';
import { CustomFormData } from '../../shared/utility/custom-form-data.helper';
import { CreateUserDto } from '../dtos/create-user.dto';
import { UpdateUserShortProfileDto } from '../dtos/update-user-short-profile.dto';
import { UpdateUserDto } from '../dtos/update-user.dto';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    http = inject(HttpClient);

    constructor() {}

    createUser(dto: CreateUserDto) {
        const formData = new CustomFormData(dto);
        return this.http.post<SubUser>(`${environment.api}/user/profile`, formData);
    }

    loadMe() {
        return this.http.get<User>(`${environment.api}/user/me`);
    }

    updateMe(dto: UpdateUserDto) {
        return this.http.patch<User>(`${environment.api}/user/me`, dto);
    }

    updateNotes(notes: string) {
        return this.http.patch<User>(`${environment.api}/user/me/notes`, { notes });
    }

    updateAvatar(avatar: File) {
        const formData = new CustomFormData({
            avatar,
        });
        return this.http.patch<User>(`${environment.api}/user/me/avatar`, formData);
    }

    removeAvatar() {
        return this.http.delete<User>(`${environment.api}/user/me/avatar`);
    }

    updateShortProfile(dto: UpdateUserShortProfileDto) {
        return this.http.patch<User>(`${environment.api}/user/me/short-profile`, dto);
    }
}
