import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { StoryCollectionGalleryItemComponent } from '../story-collection-gallery-item/story-collection-gallery-item.component';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'app-story-collection-gallery',
    standalone: true,
    template: `
        <div class="grid grid-cols-auto-grow gap-x-5 gap-y-8 w-full" style="--c-min: 320px;">
            <ng-container *ngFor="let libraryEntry of libraryEntries">
                <app-story-collection-gallery-item [libraryEntry]="libraryEntry"></app-story-collection-gallery-item>
            </ng-container>
        </div>
    `,
    styleUrls: ['./story-collection-gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
    imports: [StoryCollectionGalleryItemComponent, NgForOf],
})
export class StoryCollectionGalleryComponent {
    @Input() libraryEntries?: LibraryEntry[];
}
