import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LegalLinksComponent } from '../../../shared/components/legal-links/legal-links.component';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-auth-wrapper',
    standalone: true,
    imports: [LegalLinksComponent, NgIf, ReactiveFormsModule, SharedModule, RouterLink],
    template: `
        <div class="flex items-center bg-beige h-full w-full font-serif">
            <div class="w-1/2 p-5 w-1/2 p-10 flex justify-end">
                <div>
                    <div class="relative overflow-hidden rounded-lg shadow-lg">
                        <img
                            class="object-cover w-full h-full border-2 border-l-4 border-b-4 border border-blue rounded-md"
                            [src]="image"
                            alt="Elderly Woman" />
                        <div class="absolute bottom-0 left-0 p-2 m-4 bg-blue rounded-xl text-center">
                            <h3 class="mb-3 text-white font-sans">
                                Wir haben alle eine individuelle Geschichte, darin sind wir uns gleich.
                            </h3>
                        </div>
                    </div>
                    <app-legal-links></app-legal-links>
                </div>
            </div>
            <div class="w-1/2 p-10 flex">
                <div class="flex flex-col">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    styleUrl: './auth-page-wrapper.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageWrapperComponent {
    image = `/assets/images/woman-${Math.floor(Math.random() * 3) + 1}.jpg`;
}
