import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LibraryEntryService } from '../../services/library-entry.service';
import { LibraryRecordingService } from '../../services/library-recording.service';
import { LibraryEntryActions } from './library-entry.actions';

@Injectable()
export class LibraryEntryEffects {
    actions$ = inject(Actions);
    libraryEntryService = inject(LibraryEntryService);
    libraryRecordingService = inject(LibraryRecordingService);
    toastService = inject(HotToastService);
    router = inject(Router);

    loadAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.loadLibraryEntries),
            exhaustMap(action =>
                this.libraryEntryService.loadAll().pipe(
                    map(libraryEntries => LibraryEntryActions.loadLibraryEntriesSuccess({ libraryEntries })),
                    catchError(error => of(LibraryEntryActions.loadLibraryEntriesFailure({ error }))),
                ),
            ),
        ),
    );

    loadById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.loadLibraryEntryByID),
            switchMap(action =>
                this.libraryEntryService.loadById(action.id).pipe(
                    map(libraryEntry => LibraryEntryActions.loadLibraryEntryByIDSuccess({ libraryEntry })),
                    catchError(error => of(LibraryEntryActions.loadLibraryEntryByIDFailure({ error }))),
                ),
            ),
        ),
    );

    deleteById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.deleteLibraryEntry),
            switchMap(action =>
                this.libraryEntryService.deleteById(action.libraryEntryId).pipe(
                    map(() => LibraryEntryActions.deleteLibraryEntrySuccess({ libraryEntryId: action.libraryEntryId })),
                    tap(() => {
                        this.toastService.success('Eintrag erfolgreich gelöscht.');
                    }),
                    catchError(error => of(LibraryEntryActions.deleteLibraryEntryFailure({ error }))),
                ),
            ),
        ),
    );

    createLibraryEntry = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.createLibraryEntry),
            switchMap(action =>
                this.libraryEntryService.createLibraryEntry(action.libraryEntry).pipe(
                    map(libraryEntry =>
                        LibraryEntryActions.createLibraryEntrySuccess({
                            libraryEntry,
                        }),
                    ),
                    catchError(error =>
                        of(
                            LibraryEntryActions.createLibraryEntryFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateLibraryEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.updateLibraryEntry),
            switchMap(action =>
                this.libraryEntryService.updateEntry(action.libraryEntry).pipe(
                    map(libraryEntry =>
                        LibraryEntryActions.updateLibraryEntrySuccess({
                            libraryEntry,
                        }),
                    ),
                    tap(() => {
                        this.toastService.success('Eintrag erfolgreich geändert.');
                    }),
                    catchError(error =>
                        of(
                            LibraryEntryActions.updateLibraryEntryFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateLibraryEntrySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LibraryEntryActions.updateLibraryEntrySuccess),
                tap(action => this.router.navigate(['/lebensgeschichte', 'erinnerung', action.libraryEntry.id])),
            ),
        { dispatch: false },
    );

    addLibraryEntryMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.addLibraryEntryMedia),
            switchMap(action =>
                this.libraryEntryService.addMediaToLibraryEntry(action.libraryEntryId, action.mediaFiles).pipe(
                    map(libraryEntry => {
                        if (!libraryEntry) throw Error('Missing Library Entry');

                        return LibraryEntryActions.addLibraryEntryMediaSuccess({
                            libraryEntry: libraryEntry,
                        });
                    }),
                    tap(() => {
                        this.toastService.success('Medien erfolgreich hinzugefügt.');
                    }),
                    catchError(error =>
                        of(
                            LibraryEntryActions.addLibraryEntryMediaFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    deleteLibraryEntryMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.deleteLibraryEntryMedia),
            switchMap(action =>
                this.libraryEntryService.deleteMediaOfLibraryEntry(action.libraryEntryId, action.mediaId).pipe(
                    tap(() => {
                        this.toastService.success('Erfolgreich gelöscht.');
                    }),
                    map(libraryEntry => LibraryEntryActions.deleteLibraryEntryMediaSuccess({ libraryEntry })),
                    catchError(error => of(LibraryEntryActions.deleteLibraryEntryMediaFailure({ error }))),
                ),
            ),
        ),
    );

    startLibraryEntryMediaRecording$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryEntryActions.startLibraryEntryMediaRecording),
            switchMap(action =>
                this.libraryRecordingService.startRecordingMediaEntry(action.libraryEntryId, action.mediaId).pipe(
                    map(res => LibraryEntryActions.startLibraryEntryMediaRecordingSuccess({ res })),
                    catchError(error => of(LibraryEntryActions.startLibraryEntryMediaRecordingFailure({ error }))),
                ),
            ),
        ),
    );
}
