import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Directive({
    selector: 'img[avatarSrc]',
})
export class AvatarSrcDirective {
    avatarSrc!: { id: string; avatar: string } | null;

    @Input('avatarSrc') set _avatarSrc(src: { id: string; avatar: string } | null) {
        this.avatarSrc = src;
        this.load();
    }

    constructor(
        private http: HttpClient,
        private elRef: ElementRef<HTMLImageElement>,
    ) {}

    load() {
        if (this.avatarSrc?.avatar) {
            this.http
                .get<{ url: string }>(`${environment.api}/tenant/avatar/${this.avatarSrc.id}`)
                .subscribe(response => {
                    this.elRef.nativeElement.src = response.url;
                    this.elRef.nativeElement.classList.remove('hidden');
                });
        }
    }
}
