import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../../user/services/user.service';
import { CaretakerRouteNames } from '../caretaker-routing';
import { CaretakerService } from '../caretaker.service';
import { SubUserActions } from './sub-user.actions';

@Injectable()
export class SubUserEffects {
    userService = inject(UserService);
    actions$ = inject(Actions);
    caretakerService = inject(CaretakerService);
    router = inject(Router);

    createSubUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubUserActions.createSubUser),
            exhaustMap(action =>
                this.userService.createUser(action.subUser).pipe(
                    map(user => SubUserActions.createSubUserSuccess({ subUser: user })),
                    catchError(error => of(SubUserActions.createSubUserFailure({ error }))),
                ),
            ),
        ),
    );

    createSubUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SubUserActions.createSubUserSuccess),
                tap(action =>
                    this.router.navigate([
                        CaretakerRouteNames.prefix,
                        CaretakerRouteNames.createSubUserSuccess_ID,
                        action.subUser.id,
                    ]),
                ),
            ),
        { dispatch: false },
    );

    loadSubUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubUserActions.loadAllSubUsers),
            exhaustMap(action =>
                this.caretakerService.loadAllSubUsers().pipe(
                    map(subUsers => SubUserActions.loadAllSubUsersSuccess({ subUsers })),
                    catchError(error => of(SubUserActions.loadAllSubUsersFailure({ error }))),
                ),
            ),
        ),
    );
}
