import { reduceToValueLabelMap } from '../../shared/utility/reduce-label-value-array.helper';

export enum genderEnum {
    man = 'm',
    woman = 'w',
    various = 'd',
    notSpecified = '',
}

export const genderData = [
    { label: 'Männlich', value: genderEnum.man },
    { label: 'Weiblich', value: genderEnum.woman },
    { label: 'Divers', value: genderEnum.various },
    { label: 'Keine Angabe', value: genderEnum.notSpecified },
];

export const genderValueLabelMap = reduceToValueLabelMap(genderData);
