import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

export type AppButtonColorVariants = 'primary' | 'white' | 'blue' | 'green' | 'red' | 'beige' | 'transparent';

@Component({
    selector: 'app-button',
    template: `
        <button
            [type]="type"
            class="btn"
            [class]="class"
            [class.w-full]="fullWidth"
            *ngIf="!link"
            [class.square]="square"
            [class.unequal-border]="unequalBorder"
            [disabled]="disabled"
            [class.whitespace-nowrap]="!wrap"
            [class.outline]="outline">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>

        <a
            class="btn"
            [class]="class"
            [class.w-full]="fullWidth"
            *ngIf="link"
            [routerLink]="link"
            [fragment]="fragment"
            [class.unequal-border]="unequalBorder"
            [class.whitespace-nowrap]="!wrap"
            [class.outline]="outline">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </a>

        <ng-template #contentTpl>
            <ng-content></ng-content>
            <fa-icon [icon]="faSpinner" animation="spin" class="ml-2" *ngIf="loading"></fa-icon>
        </ng-template>
    `,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
    @Input() color: AppButtonColorVariants = 'primary';
    @Input() disabled: boolean | null = false;
    faSpinner = faSpinner;
    @Input() fragment?: string;
    @Input() fullWidth: boolean = false;
    @Input() link?: any[];
    @Input() loading: boolean | null = false;
    @Input() outline = false;
    @Input() rounded: 'rounded-md' | 'rounded-full' = 'rounded-md';
    @Input() square = false;
    @Input() type: string = 'button';
    @Input() unequalBorder = false;
    @Input() wrap = true;

    constructor() {}

    get class() {
        return `btn-${this.color} ${this.rounded}`;
    }

    ngOnInit(): void {}
}
