import {
    animate,
    AnimationBuilder,
    AnimationMetadata,
    AnimationPlayer,
    style,
} from '@angular/animations';
import { Directive, ElementRef, HostBinding } from '@angular/core';
import { DropdownContainerDirective } from './dropdown-container.directive';

@Directive({
    selector: '[appDropdown]',
})
export class DropdownDirective {
    player?: AnimationPlayer;

    @HostBinding('style.opacity') opacity = '0';
    @HostBinding('class.hidden') hidden = true;

    constructor(
        private dropdownContainer: DropdownContainerDirective,
        private builder: AnimationBuilder,
        private el: ElementRef<any>,
    ) {}

    animate() {
        if (this.player) {
            this.player.destroy();
        }

        if (this.dropdownContainer.isOpen) {
            this.hidden = false;
        }

        const metadata = this.dropdownContainer.isOpen
            ? this.getFadeInAnimation()
            : this.getFadeOutAnimation();
        const factory = this.builder.build(metadata);
        this.player = factory.create(this.el.nativeElement);

        if (!this.dropdownContainer.isOpen) {
            this.player.onDone(() => (this.hidden = true));
        }

        this.player.play();
    }

    getFadeInAnimation(): AnimationMetadata[] {
        return [
            style({ opacity: 0, transform: 'scale(95%)' }),
            animate(
                '100ms ease-out',
                style({ opacity: 1, transform: 'scale(100%)' }),
            ),
        ];
    }

    getFadeOutAnimation(): AnimationMetadata[] {
        return [
            style({ opacity: 1, transform: 'scale(100%)' }),
            animate(
                '75ms ease-in',
                style({ opacity: 0.5, transform: 'scale(95%)' }),
            ),
        ];
    }
}
