import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { asyncScheduler, firstValueFrom, observeOn, of, switchMap, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LibraryEntryActions } from '../../../library/+store/library-entry/library-entry.actions';
import { LibraryEntryMedia } from '../../../library/+store/library-entry/library-entry.model';

export interface AudioRecordChunk {
    blob: Blob;
    groupId: string;
    last?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-voice-recorder',
    template: `
        <div class="min-h-[180px]">
            <div class="text-xs text-gray-500 row-start-2 col-span-full mb-1" *ngIf="initialized === 'record-player'">
                <span *ngIf="mode() === 'recorder'">Tippe auf die Kopfhörer um deine Aufnahme anzuhören</span>
                <span *ngIf="mode() === 'player'">Tippe auf das Mikrofon um eine Aufnahme zu machen</span>
            </div>
            <div *ngIf="initialized" class="grid grid-cols-[auto_300px_auto] grid-rows-[74px_1fr] items-center gap-x-3">
                <div class="bg-gray-100 flex shadow rounded-md col-span-2 row-start-1 col-start-1 h-full"></div>

                <ng-container *ngIf="mode() === 'player'">
                    <app-voice-recorder-player [libraryEntryId]="libraryEntryId" [mediaEntry]="mediaEntry">
                    </app-voice-recorder-player>
                </ng-container>

                <ng-container *ngIf="mode() === 'recorder'">
                    <app-voice-recorder-recorder
                        [libraryEntryId]="libraryEntryId"
                        [mediaEntry]="mediaEntry"
                        [userId]="userId">
                    </app-voice-recorder-recorder>
                </ng-container>

                <!-- mode switch overlay buttons -->
                <ng-container *ngIf="initialized === 'record-player'">
                    <div class="col-start-1 row-start-1 ml-3">
                        <app-button
                            rounded="rounded-full"
                            [color]="'blue'"
                            [square]="true"
                            (click)="modeChange('player')"
                            *ngIf="mode() === 'recorder'">
                            <fa-icon [icon]="['fas', 'headphones']" size="lg" [fixedWidth]="true"></fa-icon>
                        </app-button>
                    </div>

                    <div class="col-start-3 row-start-1">
                        <app-button
                            rounded="rounded-full"
                            [color]="'blue'"
                            [square]="true"
                            (click)="modeChange('recorder')"
                            *ngIf="mode() === 'player'">
                            <fa-icon [icon]="['fas', 'microphone']" size="2x" [fixedWidth]="true"></fa-icon>
                        </app-button>
                    </div>
                </ng-container>
            </div>
        </div>
    `,
    styleUrls: ['./voice-recorder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoiceRecorderComponent implements OnInit, OnDestroy {
    @Input() libraryEntryId!: string;
    @Input() userId?: string | undefined;
    @Input() mediaEntry!: LibraryEntryMedia;
    @Output() currentTime = new EventEmitter<number>();
    // @ViewChild(VoiceRecorderRecorderComponent, { static: false }) recorderComponent?: VoiceRecorderRecorderComponent;
    // @ViewChild(VoiceRecorderPlayerComponent, { static: false }) playerComponent?: VoiceRecorderPlayerComponent;

    store = inject(Store);
    actions = inject(Actions);

    mode = signal<'recorder' | 'player'>('player');
    initialized?: 'player' | 'record-player';

    ngOnInit() {
        if (!this.libraryEntryId || !this.mediaEntry) {
            console.log('MISSING MEDIA INPUTS');
            return;
        }

        if (this.libraryEntryId && this.mediaEntry && this.mediaEntry.purpose === 'audio-record' && this.userId) {
            this.initialized = 'record-player';
            this.mode.set('recorder');
        } else {
            this.initialized = 'player';
            this.mode.set('player');
        }
    }

    async modeChange(mode: 'player' | 'recorder', start = false) {
        try {
            await this.reloadLibraryEntry();
            this.mode.set(mode);
        } catch (e) {
            console.log(e);
        }
    }

    reloadLibraryEntry() {
        return firstValueFrom(
            of(this.libraryEntryId).pipe(
                tap(id => this.store.dispatch(LibraryEntryActions.loadLibraryEntryByID({ id }))),
                switchMap(() =>
                    this.actions.pipe(
                        ofType(
                            LibraryEntryActions.loadLibraryEntryByIDSuccess,
                            LibraryEntryActions.loadLibraryEntryByIDFailure,
                        ),
                        first(),
                        map(action => {
                            if (action.type === LibraryEntryActions.loadLibraryEntryByIDSuccess.type) {
                                return action;
                            }

                            throw new Error();
                        }),
                    ),
                ),
                observeOn(asyncScheduler),
                untilDestroyed(this),
            ),
        );
    }

    ngOnDestroy() {}
}
