import { Pipe, PipeTransform } from '@angular/core';

interface Media {
    contentType: string;
}

type MediaType = 'audio' | 'image' | 'video';

@Pipe({
    name: 'mediaType',
})
export class MediaTypePipe implements PipeTransform {
    transform<T extends Media>(value: T[], ...types: MediaType[]): T[] {
        return value.filter(media => types.some(type => media.contentType?.startsWith(type)));
    }
}
