import { ChangeDetectionStrategy, Component, computed, Input } from '@angular/core';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { RouterLink } from '@angular/router';
import { ImageComponent } from '../../../shared/components/image/image.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-story-collection-gallery-item',
    standalone: true,
    template: `
        <a [routerLink]="['/lebensgeschichte', 'erinnerung', libraryEntry.id]" class="w-full text-primary">
            <div class="p-3 bg-white rounded-xl shadow-card">
                <div class="rounded-xl overflow-hidden">
                    <app-image [src]="previewImage()" width="295" height="172"></app-image>
                </div>
            </div>
            <div class="mt-1.5 text-sm uppercase font-medium">
                Erstellt am {{ libraryEntry.createdAt | date: 'dd.MM.yyyy' }}
            </div>
            <div class="mt-2 text-sm font-bold leading-relaxed">
                {{ libraryEntry.title }}
            </div>
        </a>
    `,
    styleUrls: ['./story-collection-gallery-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, ImageComponent, DatePipe],
})
export class StoryCollectionGalleryItemComponent {
    @Input() libraryEntry!: LibraryEntry;

    // todo check better result
    previewImage = computed(() => {
        return this.libraryEntry?.media?.filter(media => media.contentType?.startsWith('image'))[0]?.location;
    });
}
