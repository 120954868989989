import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LibraryImpulseQuestion } from '../+store/library-impulse-question/library-impulse-question.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LibraryImpulseQuestionService {
    constructor(private http: HttpClient) {}

    loadAll() {
        return this.http.get<LibraryImpulseQuestion[]>(`${environment.api}/library/impulse-questions/`);
    }

    loadById(id: string) {
        return this.http.get<LibraryImpulseQuestion[]>(`${environment.api}/library/impulse-questions/${id}`);
    }

    deleteById(id: string) {
        return this.http.delete(`${environment.api}/library/impulse-question/${id}`);
    }
}
