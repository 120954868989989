import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LibraryImpulseQuestion } from './library-impulse-question.model';

export const LibraryImpulseQuestionActions = createActionGroup({
    source: 'LibraryImpulseQuestion/API',
    events: {
        'Load Library Impulse Questions': emptyProps(),
        'Load Library Impulse Questions Success': props<{ impulseQuestions: LibraryImpulseQuestion[] }>(),
        'Load Library Impulse Questions Failure': props<{ error: any }>(),

        'Load Library Impulse Question By Id': props<{ id: string }>(),
        'Load Library Impulse Question By Id Success': props<{ impulseQuestions: LibraryImpulseQuestion[] }>(),
        'Load Library Impulse Question By Id Failure': props<{ error: any }>(),

        'Clear Library Impulse Questions': emptyProps(),
    },
});
