import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompleteSignupComponent } from './pages/complete-signup/complete-signup.component';
import { EmailSentComponent } from './pages/email-sent/email-sent.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordForgetSuccessComponent } from './pages/password-forget-success/password-forget-success.component';
import { PasswordForgetComponent } from './pages/password-forget/password-forget.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { SignupComponent } from './pages/signup/signup.component';

export const authRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'signup',
        component: SignupComponent,
    },
    {
        path: 'complete-signup/:token',
        component: CompleteSignupComponent,
    },
    {
        path: 'set-password/:token',
        component: SetPasswordComponent,
    },
    {
        path: 'email-sent',
        component: EmailSentComponent,
    },
    {
        path: 'password-forget',
        component: PasswordForgetComponent,
    },
    {
        path: 'password-forget-success',
        component: PasswordForgetSuccessComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
