import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardCardComponent } from '../../dashboard/components/dashboard-card/dashboard-card.component';
import { DefaultLayoutWithNavigationComponent } from '../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../shared/shared.module';
import { ChannelFiveComponent } from './channel-five/channel-five.component';
import { ChannelFourComponent } from './channel-four/channel-four.component';
import { ChannelOneComponent } from './channel-one/channel-one.component';
import { ChannelSixComponent } from './channel-six/channel-six.component';
import { ChannelThreeComponent } from './channel-three/channel-three.component';
import { ChannelTwoComponent } from './channel-two/channel-two.component';
import { ChannelComponent } from './channel/channel.component';
import { NetworkComponent } from './network/network.component';

@NgModule({
    declarations: [
        NetworkComponent,
        ChannelOneComponent,
        ChannelTwoComponent,
        ChannelThreeComponent,
        ChannelFourComponent,
        ChannelFiveComponent,
        ChannelSixComponent,
        ChannelComponent,
    ],
    imports: [CommonModule, SharedModule, RouterModule, DashboardCardComponent, DefaultLayoutWithNavigationComponent],
    exports: [],
})
export class ComponentsModule {}
