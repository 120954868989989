import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormGroupFinderDirective } from '../../../shared/custom-inputs/directives/form-group-finder/form-group-finder.directive';
import { InputSelectMultiComponent } from '../../../shared/custom-inputs/input-select-multi/input-select-multi.component';
import { SharedModule } from '../../../shared/shared.module';
import { genderData } from '../../data/gender.data';
import { languagesData } from '../../data/languages.data';

@Component({
    selector: 'app-client-profile-info-form',
    standalone: true,
    imports: [ReactiveFormsModule, SharedModule, InputSelectMultiComponent, FormGroupFinderDirective, NgForOf],
    template: `
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full" [formGroup]="formGroup" formGroupFinder>
            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Geschlecht</label>
                <select
                    formControlName="gender"
                    class="form-control"
                    [class.text-gray-500]="formGroup.get('gender')?.value === ''">
                    <option value="" disabled selected class="text-gray-500">Geschlecht wählen</option>
                    <option *ngFor="let gender of genderData" [value]="gender.value">{{ gender.label }}</option>
                </select>
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Beziehungsstatus</label>
                <input formControlName="relationship" class="form-control" placeholder="zB. Verheiratet" type="text" />
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Familienangehörige</label>
                <input formControlName="family" class="form-control" placeholder="zB. 2 Töchter, 1 Sohn" type="text" />
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Haustiere</label>
                <input formControlName="pets" class="form-control" placeholder="zB. Hund" type="text" />
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <app-input-select-multi
                    formControlName="languages"
                    label="Sprachen"
                    placeholder="Sprachen auswählen"
                    [options]="languages">
                </app-input-select-multi>
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Glaube</label>
                <input formControlName="faith" class="form-control" placeholder="zB. Atheist" type="text" />
            </div>
        </div>
    `,
    styleUrl: './client-profile-info-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class ClientProfileInfoFormComponent {
    @Input() formGroup!: FormGroup;
    languages = languagesData;
    protected readonly genderData = genderData;
}
