export interface LibraryEntry {
    id: string;
    title: string;
    year: string;
    location: string;
    createdAt: string;
    updatedAt: string;
    media: LibraryEntryMedia[];
    impulseQuestionId: string;
    timer: string;
    // todo mark as non optional after migration
    finished?: boolean;

    // todo gibts das noch?
    bookName: string;
    text: string;
    city: string;
    day: string;
    month: string;
}

export interface LibraryEntryMedia {
    id: string;
    location: string;
    contentType: string;
    createdAt?: string;
    purpose?: LibraryEntryMediaPurpose;
    connectedRecordingId?: string;
    imageRecordingTimestamp?: number;
    lastConvertedGroup?: string;
    recordingGroups?: [];
    duration?: number;
    conversionRunning: boolean;
}

export enum LibraryEntryMediaPurpose {
    audioRecord = 'audio-record',
}
