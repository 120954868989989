import { ChangeDetectionStrategy, Component, forwardRef, inject, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { ControlsOf } from '../../types/controls-of.type';

export interface DatePickerDate {
    day: string;
    month: string;
    year: string;
}
export type DatePickerDateControls = ControlsOf<DatePickerDate>;

export function convertIsoDateToValues(isoDate: string): DatePickerDate {
    const d = new Date(isoDate);

    return {
        day: ('0' + d.getUTCDate()).slice(-2),
        month: (d.getUTCMonth() + 1).toString(),
        year: d.getFullYear().toString(),
    };
}

@UntilDestroy()
@Component({
    selector: 'app-datepicker-input',
    template: `
        <div class="grid grid-cols-3 gap-3" [formGroup]="dateForm">
            <select class="form-control" formControlName="day">
                <option value="" disabled selected hidden>Tag</option>
                <option value="01">1</option>
                <option value="02">2</option>
                <option value="03">3</option>
                <option value="04">4</option>
                <option value="05">5</option>
                <option value="06">6</option>
                <option value="07">7</option>
                <option value="08">8</option>
                <option value="09">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
            </select>
            <select class="form-control" name="month" formControlName="month">
                <option value="" disabled selected hidden>Monat</option>
                <option value="1">Januar</option>
                <option value="2">Februar</option>
                <option value="3">März</option>
                <option value="4">April</option>
                <option value="5">Mai</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Dezember</option>
            </select>
            <select class="form-control" name="year" formControlName="year">
                <option value="" disabled selected hidden>Jahr</option>
                <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </option>
            </select>
        </div>
    `,
    styleUrls: ['./datepicker-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerInputComponent),
            multi: true,
        },
    ],
})
export class DatepickerInputComponent implements ControlValueAccessor, OnInit {
    changeFn: any = () => {};
    touchFn: any;

    fb = inject(NonNullableFormBuilder);
    dateForm: FormGroup<DatePickerDateControls> = this.fb.group({
        day: [''],
        month: [''],
        year: [''],
    });

    years: number[] = [];

    ngOnInit() {
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: currentYear - 1899 }, (_, i) => 1900 + i);

        this.dateForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
            this.update(this.dateForm.getRawValue());
        });
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // throw new Error('Method not implemented.');
    }

    update(values: DatePickerDate) {
        if (values.day && values.month && values.year) {
            const date = DateTime.fromObject({
                day: +values.day,
                month: +values.month,
                year: +values.year,
            });

            if (date.isValid) {
                this.changeFn(date.toISODate());
            } else {
                this.changeFn('');
            }
        } else {
            this.changeFn('');
        }
    }

    writeValue(date: string): void {
        if (date) {
            const values = convertIsoDateToValues(date);
            this.dateForm.patchValue(values, { emitEvent: false });
        }
    }
}
