import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DialogModalWrapperComponent } from '../../modal/dialog-modal-wrapper/dialog-modal-wrapper.component';
import { NgIf } from '@angular/common';
import { DialogModalHeaderComponent } from '../../modal/dialog-modal-header/dialog-modal-header.component';

@Component({
    selector: 'app-image-modal',
    template: `
        <app-dialog-modal-wrapper [fullScreen]="true">
            <app-dialog-modal-header bgColorClass="bg-white"></app-dialog-modal-header>
            <div class="flex w-full justify-center h-full">
                <img
                    [src]="src"
                    [alt]="headline ?? 'Erinnerungsbild'"
                    *ngIf="src"
                    class="max-w-full h-full max-h-full object-contain" />
            </div>
        </app-dialog-modal-wrapper>
    `,
    styleUrls: ['./image-modal.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DialogModalWrapperComponent, NgIf, DialogModalHeaderComponent],
})
export class ImageModalComponent {
    @Input() src?: string;
    @Input() headline?: string;

    constructor() {}
}
