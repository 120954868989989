import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, signal, ViewChild } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TippyDirective } from '@ngneat/helipopper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animationFrames, switchMap, tap, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { SharedModule } from '../../shared.module';

export interface GroupCollapse {
    duration?: number;
    iconDef?: IconProp;
    headline: string;
    subline?: string;
    infoHeadline?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-group-collapsable',
    standalone: true,
    imports: [FaIconComponent, SharedModule, NgClass, NgIf, TippyDirective],
    template: `
        <div class="border border-[#EDECEC] rounded-md">
            <header
                class="p-4 flex text-primary items-center transition-all cursor-pointer"
                (click)="toggleCollapse()"
                [ngClass]="collapsed() ? 'bg-[#F6F6F6]' : 'bg-beige'">
                <div class="flex items-center">
                    <div class="">
                        <fa-icon [icon]="iconDef" size="xl" class="mr-4" *ngIf="iconDef" [fixedWidth]="true"></fa-icon>
                    </div>
                    <div>
                        <div>
                            <span class="font-bold text-xl" *ngIf="headline">{{ headline }}</span>
                            <ng-container *ngIf="infoHeadline">
                                <button
                                    class="ml-2 text-primary"
                                    type="button"
                                    [tp]="tpl"
                                    tpVariation="popper"
                                    (click)="handleInfoClick($event)">
                                    <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                                </button>
                                <ng-template class="tippy-box tippy-arrow" #tpl let-hide>
                                    <div class="text-sm" [innerHTML]="infoHeadline"></div>
                                </ng-template>
                            </ng-container>
                        </div>
                        <div
                            [class.text-gray-400]="collapsed()"
                            class="text-xs font-medium transition-all mt-1"
                            *ngIf="subline">
                            {{ subline }}
                        </div>
                    </div>
                </div>
                <button
                    class="h-full ml-auto transition-opacity"
                    type="button"
                    [ngClass]="collapsed() ? 'opacity-0' : 'opacity-100'">
                    <fa-icon [icon]="['fas', 'circle-xmark']" size="2xl"></fa-icon>
                </button>
            </header>
            <div
                class="transition-all ease-linear overflow-hidden"
                [style.max-height]="maxHeight()"
                [style.transition-duration]="duration + 'ms'">
                <div class="p-4" #contentWrapper>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    styleUrl: './group-collapsable.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupCollapsableComponent {
    @ViewChild('contentWrapper', { static: true }) contentWrapper!: ElementRef;
    @Input() duration = 200;
    @Input() headline?: string;
    @Input() subline?: string;
    @Input() iconDef?: IconProp;
    @Input() infoHeadline?: string;
    collapsed = signal(true);
    maxHeight = signal<string>('0px');
    transitioning = false;

    toggleCollapse() {
        if (this.transitioning) {
            return;
        }
        this.transitioning = true;
        this.collapsed() ? this.expand() : this.collapse();
    }

    calcMaxHeight() {
        return this.contentWrapper.nativeElement.scrollHeight;
    }

    expand() {
        this.collapsed.set(false);
        this.animate(0, this.calcMaxHeight());
    }

    collapse() {
        this.collapsed.set(true);
        this.animate(this.calcMaxHeight(), 0);
    }

    animate(from: number, to: number) {
        this.transitioning = true;
        this.maxHeight.set(from + 'px');

        animationFrames()
            .pipe(
                first(),
                tap(() => this.maxHeight.set(to + 'px')),
                switchMap(() => timer(this.duration)),
                untilDestroyed(this),
            )
            .subscribe(() => {
                if (!this.collapsed()) {
                    this.maxHeight.set('none');
                }
                this.transitioning = false;
            });
    }

    handleInfoClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
