import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UpdateUserShortProfileDto } from '../dtos/update-user-short-profile.dto';
import { UpdateUserDto } from '../dtos/update-user.dto';
import { User } from './user.model';

export const UserActions = createActionGroup({
    source: 'User/API',
    events: {
        'Load Me': emptyProps(),
        'Load Me Success': props<{ user: User }>(),
        'Load Me Failure': props<{ error: any }>(),

        'Update Me': props<{ user: UpdateUserDto }>(),
        'Update Me Success': props<{ user: User }>(),
        'Update Me Failure': props<{ error: any }>(),

        'Update Avatar': props<{ avatar: File }>(),
        'Update Avatar Success': props<{ user: User }>(),
        'Update Avatar Failure': props<{ error: any }>(),

        'Delete Avatar': emptyProps(),
        'Delete Avatar Success': props<{ user: User }>(),
        'Delete Avatar Failure': props<{ error: any }>(),

        'Update Notes': props<{ notes: string }>(),
        'Update Notes Success': props<{ user: User }>(),
        'Update Notes Failure': props<{ error: any }>(),

        'Update ShortProfile': props<{ shortProfile: UpdateUserShortProfileDto }>(),
        'Update ShortProfile Success': props<{ user: User }>(),
        'Update ShortProfile Failure': props<{ error: any }>(),
    },
});
