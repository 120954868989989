import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';
import { LibraryImpulseQuestionActions } from './library-impulse-question.actions';

import { LibraryImpulseQuestion } from './library-impulse-question.model';

export const libraryImpulseQuestionsFeatureKey = 'libraryImpulseQuestions';

export interface LibraryImpulseQuestionState extends EntityState<LibraryImpulseQuestion> {
    // additional entities state properties
    loading: boolean;
}

export const adapter: EntityAdapter<LibraryImpulseQuestion> = createEntityAdapter<LibraryImpulseQuestion>();

export const initialState: LibraryImpulseQuestionState = adapter.getInitialState({
    // additional entity state properties
    loading: false,
});

export const libraryImpulseQuestionReducer = createReducer(
    initialState,
    on(
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestions,
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionById,
        state => {
            return { ...state, loading: true };
        },
    ),

    on(
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionsSuccess,
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionByIdSuccess,
        (state, action) =>
            adapter.upsertMany(action.impulseQuestions, {
                ...state,
                loading: false,
            }),
    ),

    on(
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionsFailure,
        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionByIdFailure,
        state => ({
            ...state,
            loading: false,
        }),
    ),

    on(LibraryImpulseQuestionActions.clearLibraryImpulseQuestions, state => adapter.removeAll(state)),
);

export const selectLibraryImpulseQuestionState = createFeatureSelector<LibraryImpulseQuestionState>(
    libraryImpulseQuestionsFeatureKey,
);

export const {
    selectIds: selectImpulseQuestionIds,
    selectEntities: selectImpulseQuestionEntities,
    selectAll: selectImpulseQuestionAll,
    selectTotal: selectImpulseQuestionTotal,
} = adapter.getSelectors(selectLibraryImpulseQuestionState);

export const selectImpulseQuestionsByBookName = (props: { bookName: string }) =>
    pipe(
        select(
            createSelector(selectImpulseQuestionAll, entities =>
                entities.filter(entity =>
                    entity.bookNames.map(v => v.toLowerCase()).includes(props.bookName.toLowerCase()),
                ),
            ),
        ),
        filterNullish(),
    );

export const selectImpulseQuestionsNoNull = () =>
    pipe(select(createSelector(selectImpulseQuestionAll, entities => entities)), filterNullish());

export const selectImpulseQuestionsLoading = () =>
    select(createSelector(selectLibraryImpulseQuestionState, state => state.loading));

export const selectImpulseQuestionById = (id: string) =>
    pipe(
        select(selectImpulseQuestionEntities),
        map(entities => entities[id]),
    );
