import { Routes } from '@angular/router';
import { canDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { CaretakerCreateSubuserComponent } from './pages/caretaker-create-subuser/caretaker-create-subuser.component';
import { CaretakerOverviewComponent } from './pages/caretaker-overview/caretaker-overview.component';
import { CaretakerSubuserCreatedComponent } from './pages/caretaker-subuser-created/caretaker-subuser-created.component';

export enum CaretakerRouteNames {
    prefix = 'alltagshelfer',
    dashboard = '',
    mySpace = 'mein-bereich',
    createSubUser = 'profil-anlegen',
    createSubUserSuccess_ID = 'profil-angelegt',
    accountData = 'account',
}

export const caretakerRoutes: Routes = [
    {
        path: CaretakerRouteNames.dashboard,
        pathMatch: 'full',
        component: CaretakerOverviewComponent,
    },

    // diabled until pages are filled
    // {
    //     path: CaretakerRouteNames.mySpace,
    //     component: CaretakerMySpaceComponent,
    //     resolve: {
    //         userAction: getMeResolver,
    //     },
    //     children: [
    //         {
    //             path: '',
    //             redirectTo: CaretakerRouteNames.accountData,
    //             pathMatch: 'full',
    //         },
    //         {
    //             path: CaretakerRouteNames.accountData,
    //             component: CaretakerAccountEditComponent,
    //         },
    //     ],
    // },
    {
        path: CaretakerRouteNames.createSubUser,
        component: CaretakerCreateSubuserComponent,
        canDeactivate: [canDeactivateGuard],
    },
    {
        path: `${CaretakerRouteNames.createSubUserSuccess_ID}/:id`,
        component: CaretakerSubuserCreatedComponent,
    },
];
