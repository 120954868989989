import { InjectionToken } from '@angular/core';

export interface FormErrors {
    required: () => string;
    minlength: (a: number, b: number) => string;
    iban: () => string;
}

export const defaultErrors = {
    required: () => `Dieses Feld darf nicht leer sein.`,
    minlength: ({
        requiredLength,
        actualLength,
    }: {
        requiredLength: number;
        actualLength: number;
    }) => `Das Feld benötigt mindestens ${requiredLength} Zeichen.`,
    maxlength: ({
        requiredLength,
        actualLength,
    }: {
        requiredLength: number;
        actualLength: number;
    }) => `Das Feld darf maximal ${requiredLength} Zeichen enthalten.`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors,
});
