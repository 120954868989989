import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../app.reducer';
import { filterNullish } from '../shared/utility/filter-nullish.operator';
import { setPassword } from './+store/auth.actions';
import { Credentials } from './+store/auth.reducer';
import { selectAccessToken } from './+store/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    token!: string;

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
    ) {
        this.store
            .select(selectAccessToken)
            .pipe(filterNullish())
            .subscribe(token => (this.token = token));
    }

    login(credentials: Credentials): Observable<{ accessToken: string; refreshToken: string }> {
        return this.http.post<{ accessToken: string; refreshToken: string }>(
            `${environment.api}/auth/login`,
            credentials,
        );
    }

    signup(email: string): Observable<any> {
        return this.http.post<any>(`${environment.api}/auth/signup`, { email });
    }

    setPassword(payload: ReturnType<typeof setPassword>): Observable<any> {
        return this.http.post<any>(`${environment.api}/user/set-password`, {
            password: payload.password,
            token: payload.token,
            caretaker: payload.caretaker,
        });
    }

    getNewAccessToken(refreshTokenBody: any): Observable<{ accessToken: string; refreshToken: string }> {
        const body = {
            refreshToken: refreshTokenBody,
        };
        return this.http.post<{ accessToken: string; refreshToken: string }>(
            `${environment.api}/auth/access-token`,
            body,
        );
    }
}
