import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, inject, Input, signal, WritableSignal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TippyDirective } from '@ngneat/helipopper';
import { takeUntil } from 'rxjs';
import { ModalWysiwygEditorComponent } from '../../components/modal-wysiwyg-editor/modal-wysiwyg-editor.component';
import { DialogEvents } from '../../modal/dialog-modal-footer/dialog-modal-footer.component';
import { DialogEventResponse } from '../../modal/interfaces/is-dialog-modal.interface';
import { TypedValidationErrors } from '../types/typed-validation-errors.type';

@Component({
    selector: 'app-input-editor-modal',
    standalone: true,
    imports: [NgIf, FaIconComponent, AsyncPipe, TippyDirective, NgClass],
    template: `
        <div>
            <label class="font-bold text-primary mb-2 inline-block" *ngIf="label && label.length > 0">
                <fa-icon [icon]="labelIcon" *ngIf="labelIcon" class="mr-2"></fa-icon>
                <span>{{ label }}</span>
                <ng-container *ngIf="labelInfo">
                    <button class="p-2 text-primary" type="button" [tp]="tpl" tpVariation="popper">
                        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                    </button>
                    <ng-template class="tippy-box tippy-arrow" #tpl let-hide>
                        <div class="text-sm" [innerHTML]="labelInfo"></div>
                    </ng-template>
                </ng-container>
            </label>
            <div
                class="p-4 rounded-lg flex justify-between items-center min-h-16 relative"
                [ngClass]="getStyleClasses()">
                <div [innerHTML]="value()" *ngIf="value().length > 0" class="mr-8 wysiwyg-text text-primary"></div>

                <button
                    class="flex items-center absolute inset-0 w-full h-full"
                    (click)="openDynamicInputModal()"
                    type="button">
                    <span *ngIf="value().length === 0; else enlarge" class="inline-flex mx-auto">
                        <fa-icon
                            class="mr-2"
                            [icon]="placeholderIcon"
                            *ngIf="showPlaceHolderIcon && placeholderIcon"></fa-icon>
                        <span>{{ placeholder }}</span>
                    </span>
                    <ng-template #enlarge>
                        <span class="mr-4 mt-4 ml-auto self-start inline-flex items-center justify-center">
                            <fa-icon [icon]="editIcon"></fa-icon>
                        </span>
                    </ng-template>
                </button>
            </div>
        </div>
    `,
    styleUrl: './input-editor-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputEditorModalComponent),
            multi: true,
        },
    ],
    host: {
        class: 'contents',
    },
})
export class InputEditorModalComponent {
    @Input() label?: string;
    @Input() labelIcon?: IconProp;
    @Input() labelInfo?: string;
    @Input() modalLabel?: string;
    @Input() placeholder = 'Tippe zum Eintragen';
    @Input() editorPlaceholder?: string;
    @Input() placeholderIcon: IconProp = ['fas', 'circle-plus'];
    @Input() editIcon: IconProp = ['fas', 'arrow-up-right-and-arrow-down-left-from-center'];
    @Input() showPlaceHolderIcon: boolean = true;
    @Input() beforeValueChange?: (value: string) => Promise<boolean>;
    @Input() showSaveAndCancel?: boolean;
    @Input() style: 'gray-faded' | 'white-card' = 'gray-faded';

    isDisabled = false;
    value = signal('');
    touched = signal(false);

    errorObject: WritableSignal<TypedValidationErrors | null> = signal(null);
    dialog = inject(Dialog);

    changeFn = (p: string) => {};

    onChange(value: string) {
        this.changeFn(value);
        this.value.set(value);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    touchFn = () => {};

    onTouch() {
        this.touched.set(true);
        this.touchFn();
    }

    writeValue(value: string): void {
        this.value.set(value);
    }

    getStyleClasses() {
        switch (this.style) {
            case 'white-card':
                return ['shadow-card bg-white text-primary'];
            default:
                return ['bg-[#f6f6f6] text-[#9F9F9F] hover:text-primary'];
        }
    }

    async openDynamicInputModal() {
        const modalRef = this.dialog.open(ModalWysiwygEditorComponent, { disableClose: true });

        const instance = modalRef.componentInstance as ModalWysiwygEditorComponent;
        instance.value = this.value();
        instance.label = this.modalLabel ?? this.label;
        instance.placeholder = this.editorPlaceholder;
        instance.showSaveAndCancel = this.showSaveAndCancel;

        const updateValueAndClose = (value: string) => {
            this.onChange(value);
            modalRef.close();
        };

        instance.dialogEventResponse.pipe(takeUntil(modalRef.closed)).subscribe((res: DialogEventResponse<string>) => {
            if (res.type === DialogEvents.cancel || res.value === undefined) {
                modalRef.close();
                return;
            }

            if (!this.beforeValueChange) {
                updateValueAndClose(res.value);
            } else {
                instance.loading = true;

                this.beforeValueChange(res.value)
                    .then(success => {
                        success ? updateValueAndClose(res.value!) : (instance.loading = false);
                    })
                    .catch(() => {
                        instance.loading = false;
                    });
            }
        });
    }
}
