import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { NgIf } from '@angular/common';
import { AppButtonColorVariants } from '../../components/button/button.component';
import { ConfirmedClickDirective } from '../../directives/confirmed-click/confirmed-click.directive';

export enum DialogEvents {
    confirm = 'confirm',
    cancel = 'cancel',
    confirmDo = 'confirmAndDoSomething',
}

@Component({
    selector: 'app-dialog-modal-footer',
    standalone: true,
    imports: [NgIf, SharedModule, forwardRef(() => ConfirmedClickDirective)],
    template: `
        <div class="flex flex-col sm:flex-row items-center justify-between px-4 py-3 gap-y-4 flex-wrap bg-gray-100">
            <app-button
                *ngIf="cancelButton"
                (confirmedClick)="click(DialogEvents.cancel)"
                [showConfirm]="cancelConfirm"
                [color]="cancelButtonVariant"
                >{{ cancelButtonText }}
            </app-button>
            <app-button *ngIf="confirmButton" (click)="click(DialogEvents.confirm)" [color]="confirmButtonVariant"
                >{{ confirmButtonText }}
            </app-button>
        </div>
    `,
    styleUrl: './dialog-modal-footer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogModalFooterComponent {
    @Input() cancelButton = true;
    @Input() cancelButtonText = 'Abbrechen';
    @Input() cancelButtonVariant: AppButtonColorVariants = 'transparent';
    @Input() cancelConfirm = false;
    @Input() confirmButton = true;
    @Input() confirmButtonText = 'Bestätigen';
    @Input() confirmButtonVariant: AppButtonColorVariants = 'primary';
    @Output() dialogEvent = new EventEmitter<DialogEvents>();

    click(type: DialogEvents) {
        this.dialogEvent.emit(type);
    }

    protected readonly DialogEvents = DialogEvents;
}
