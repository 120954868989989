import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
    AvatarUploadControlComponent,
    AvatarUploadControlMode,
} from '../../../shared/components/avatar-upload-control/avatar-upload-control.component';
import { SharedModule } from '../../../shared/shared.module';
import { User } from '../../../user/+store/user.model';

@Component({
    selector: 'app-client-profile-photo-form',
    standalone: true,
    imports: [FaIconComponent, ReactiveFormsModule, SharedModule, NgIf, AvatarUploadControlComponent],
    template: `
        <div class="flex flex-col justify-center" [formGroup]="formGroup">
            <div class="bg-gray-100 shadow rounded-md p-6">
                <div class="text-center mb-6">
                    <h2 class="mb-1">
                        {{ headline }}
                        <fa-icon [icon]="['fas', 'camera']"></fa-icon>
                    </h2>
                    <p class="text-[#9F9F9F]">{{ subline }}</p>
                </div>
                <div class="flex justify-center">
                    <div class="w-[200px]">
                        <app-avatar-upload-control
                            formControlName="avatar"
                            [user]="user"
                            [mode]="avatarMode"></app-avatar-upload-control>
                    </div>
                </div>
            </div>
            <div class="flex justify-center bg-[#8BE8EE] border-1 shadow rounded-md mt-2 p-2" *ngIf="infoText">
                <fa-stack size="lg">
                    <fa-icon class="text-[#b3e2e5]" [icon]="['fas', 'circle']" stackItemSize="2x"></fa-icon>
                    <fa-icon
                        class="pr-4 text-l text-[#3d9298]"
                        [icon]="['fas', 'info-circle']"
                        stackItemSize="1x"></fa-icon>
                </fa-stack>
                <p class="text-[#3D9298] flex items-center">{{ infoText }}</p>
            </div>
        </div>
    `,
    styleUrl: './client-profile-photo-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfilePhotoFormComponent {
    @Input() formGroup!: FormGroup;
    @Input() headline = ' Profilfoto auswählen';
    @Input() subline = 'Ein Bild, das verbindet. Füge dem Profil ein Gesicht hinzu.';
    @Input() infoText?: string;
    @Input() user?: User;
    @Input() avatarMode: AvatarUploadControlMode = AvatarUploadControlMode.create;
}
