import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { LibraryEntryActions } from '../../../library/+store/library-entry/library-entry.actions';
import { UserActions } from '../../../user/+store/user.actions';

@Injectable()
export class ErrorToastyEffects {
    actions$ = inject(Actions);
    toastService = inject(HotToastService);

    showErrorToasty$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    LibraryEntryActions.createLibraryEntryFailure,
                    LibraryEntryActions.updateLibraryEntryFailure,
                    LibraryEntryActions.addLibraryEntryMediaFailure,
                    LibraryEntryActions.deleteLibraryEntryMediaFailure,

                    UserActions.loadMeFailure,
                    UserActions.updateMeFailure,
                    UserActions.deleteAvatarFailure,
                    UserActions.updateShortProfileFailure,
                ),
                tap(() => this.toastService.error('Es ist ein Fehler aufgetreten')),
            ),
        { dispatch: false },
    );
}
