import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { login } from '../../+store/auth.actions';
import { selectAuthError, selectAuthLoading } from '../../+store/auth.selectors';
import { AppState } from '../../../app.reducer';

@Component({
    selector: 'app-login',
    template: `
        <div class="flex items-center bg-beige h-full w-full font-sans">
            <div class="w-1/2 p-5 w-1/2 p-10 flex justify-end">
                <div>
                    <div class="relative overflow-hidden rounded-lg shadow-lg">
                        <img
                            class="object-cover max-h-[75vh] border-2 border-l-4 border-b-4 border border-blue rounded-md"
                            [src]="image"
                            alt="" />
                        <div class="absolute bottom-0 left-0 p-2 m-4 bg-blue rounded-xl text-center">
                            <h3 class="mb-3 text-white font-sans">
                                Wir haben alle eine individuelle Geschichte, darin sind wir uns gleich.
                            </h3>
                        </div>
                    </div>
                    <app-legal-links></app-legal-links>
                </div>
            </div>
            <div class="w-1/2 p-10 flex max-w-md">
                <div class="flex flex-col w-full">
                    <div class="mt-8 border-2 border-l-4 border-b-4 border border-blue rounded-md">
                        <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
                            <div>
                                <img class="mx-auto h-12 w-auto" src="/assets/logos/logo.png" alt="TeresaLogo" />
                            </div>
                            <h2 class="m-4 mx-8 text-2xl flex justify-center font-sans"> Jetzt anmelden </h2>

                            <div
                                class="bg-secondary mb-3 rounded p-3 text-sm border border-amber-400 font-sans"
                                *ngIf="error$ | async as error">
                                <ng-container [ngSwitch]="error.error.message">
                                    <ng-container *ngSwitchCase="'NOT_ACTIVATED'"
                                        >Ihr Account ist noch nicht aktiviert worden. Bitte kontaktieren Sie einen
                                        Administrator.
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'NO_PASSWORD_SET'"
                                        >Sie haben noch kein Passwort gesetzt. Bitte schauen Sie in Ihr E-Mail Postfach
                                        oder benutzen Sie die "Passwort vergessen?" Funktion.
                                    </ng-container>
                                    <ng-container *ngSwitchDefault
                                        >Benutzername und/oder Passwort ist falsch. Bitte versuche es erneut.
                                    </ng-container>
                                </ng-container>
                            </div>

                            <form class="" [formGroup]="loginForm" (ngSubmit)="submit()">
                                <div>
                                    <div>
                                        <input
                                            formControlName="email"
                                            type="email"
                                            autocomplete="email"
                                            required
                                            placeholder="Email-Adresse"
                                            class="form-control" />
                                    </div>
                                    <div class="mt-3 font-sans">
                                        <input
                                            formControlName="password"
                                            type="password"
                                            placeholder="Passwort"
                                            autocomplete="current-password"
                                            required
                                            class="form-control" />
                                    </div>
                                </div>
                                <div class="flex items-center justify-between mt-2 font-sans">
                                    <div class="text-sm ml-2 mb-4">
                                        <a
                                            [routerLink]="['/auth', 'password-forget']"
                                            class="font-medium text-indigo-600 hover:text-indigo-500"
                                            >Passwort vergessen?</a
                                        >
                                    </div>
                                </div>
                                <app-button type="submit" class="flex justify-center">
                                    <ng-container *ngIf="(loading$ | async) === false; else loading"
                                        >Anmelden
                                    </ng-container>
                                    <ng-template #loading>
                                        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
                                    </ng-template>
                                </app-button>
                            </form>
                        </div>
                    </div>
                    <h3 class="flex justify-center mt-4 font-sans"> Du hast noch kein Konto? </h3>
                    <app-button class="flex justify-center m-2" [routerLink]="['/auth', 'signup']" color="white">
                        Konto erstellen
                    </app-button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    faSpinner = faSpinner;
    image = `/assets/images/woman-${Math.floor(Math.random() * 3) + 1}.jpg`;

    loginForm!: UntypedFormGroup;

    error$!: Observable<any>;
    loading$!: Observable<boolean>;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.error$ = this.store.select(selectAuthError);
        this.loading$ = this.store.select(selectAuthLoading);

        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            password: new UntypedFormControl('', [Validators.required]),
        });
    }

    submit(): void {
        if (this.loginForm.valid) {
            this.store.dispatch(login({ credentials: this.loginForm.value }));
        }
    }
}
