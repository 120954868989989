import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SubUserActions } from './sub-user.actions';
import { SubUser } from './sub-user.model';

export const subUserFeatureKey = 'subusers';

export interface SubUserState extends EntityState<SubUser> {
    savingInProgress: boolean;
    loading: boolean;
}

export const adapter: EntityAdapter<SubUser> = createEntityAdapter<SubUser>();

export const initialState: SubUserState = adapter.getInitialState({
    savingInProgress: false,
    loading: false,
});

export const subUserReducer = createReducer(
    initialState,
    on(SubUserActions.loadAllSubUsers, state => ({ ...state, loading: true })),

    on(SubUserActions.loadAllSubUsersSuccess, (state, action) =>
        adapter.setAll(action.subUsers, {
            ...state,
            loading: false,
        }),
    ),

    on(SubUserActions.createSubUser, state => ({
        ...state,
        savingInProgress: true,
        loading: true,
    })),
    on(
        SubUserActions.createSubUserSuccess,
        SubUserActions.createSubUserFailure,
        SubUserActions.loadAllSubUsersFailure,
        state => ({
            ...state,
            savingInProgress: false,
            loading: false,
        }),
    ),
);

export const subUserFeature = createFeature({
    name: subUserFeatureKey,
    reducer: subUserReducer,
    extraSelectors: ({ selectSubusersState }) => ({
        ...adapter.getSelectors(selectSubusersState),
    }),
});
