import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { DiaryIndexComponent } from './diary-index/diary-index.component';

export const diaryRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: DiaryIndexComponent,
        canActivate: [AuthGuard],
    },
];
