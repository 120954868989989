import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { LibraryEntryCreateComponent } from './pages/library-entry-create/library-entry-create.component';
import { LibraryEntryDetailComponent } from './pages/library-entry-detail/library-entry-detail.component';
import { LibraryEntryStartCreationComponent } from './pages/library-entry-start-creation/library-entry-start-creation.component';
import { LibraryImpulseQuestionSelectPageComponent } from './pages/library-impulse-question-select-page/library-impulse-question-select-page.component';
import { LibraryIndexComponent } from './pages/library-index/library-index.component';
import { libraryEntryAudioRecordResolve } from './resolver/library-entry-audio-record.resolver';
import { libraryEntryDetailResolver } from './resolver/library-entry-detail.resolver';

export const libraryRoutes: Routes = [
    {
        path: '',
        component: LibraryIndexComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'anlegen',
        component: LibraryEntryStartCreationComponent,
    },
    {
        path: 'erinnerung-erstellen/:id/:recordid',
        component: LibraryEntryCreateComponent,
        resolve: {
            ready: libraryEntryAudioRecordResolve,
        },
    },
    // todo change naming
    {
        path: 'inspirationsfragen',
        component: LibraryImpulseQuestionSelectPageComponent,
        resolve: {
            // impulseQuestions: impulseQuestionResolver,
        },
    },
    // {
    //     path: 'bearbeiten/:id',
    //     component: LibraryEntryEditComponent,
    // },
    {
        path: 'erinnerung/:id',
        component: LibraryEntryDetailComponent,
        resolve: {
            finished: libraryEntryDetailResolver,
        },
    },
];
