import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import jwt from 'jwt-decode';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthState } from '../../auth/+store/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class CaretakerGuard {
    router = inject(Router);
    store = inject(Store);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.select(selectAuthState).pipe(
            map(authState => {
                if (authState.accessToken) {
                    const payload = jwt(authState.accessToken) as {
                        caretaker: boolean;
                    };
                    const isCaretaker = payload.caretaker;

                    if (!isCaretaker) {
                        return this.router.createUrlTree(['/']);
                    }
                }

                return true;
            }),
            take(1),
        );
    }
}
