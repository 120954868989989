import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import jwt from 'jwt-decode';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAuthState } from '../../auth/+store/auth.selectors';
import { CaretakerRouteNames } from '../caretaker-routing';

@Injectable({
    providedIn: 'root',
})
export class AntiCaretakerGuard {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select(selectAuthState).pipe(
            map(authState => {
                if (authState.accessToken) {
                    const payload = jwt(authState.accessToken) as {
                        caretaker: boolean;
                    };
                    const isCaretaker = payload.caretaker;

                    if (isCaretaker) {
                        return this.router.createUrlTree([CaretakerRouteNames.prefix]);
                    }
                }

                return true;
            }),
            take(1),
        );
    }
}
