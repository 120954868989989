import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { SharedModule } from '../../shared.module';
import { DialogEventResponse, IsDialogModal } from '../../modal/interfaces/is-dialog-modal.interface';
import { DialogModalWrapperComponent } from '../../modal/dialog-modal-wrapper/dialog-modal-wrapper.component';
import { DialogEvents } from '../../modal/dialog-modal-footer/dialog-modal-footer.component';
import { ConfirmedClickDirective } from '../../directives/confirmed-click/confirmed-click.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { TinyCustom } from '../../tiny-custom/tiny-custom.component';

@UntilDestroy()
@Component({
    selector: 'app-modal-dynamic-input',
    standalone: true,
    imports: [
        EditorComponent,
        ReactiveFormsModule,
        SharedModule,
        NgIf,
        NgForOf,
        DialogModalWrapperComponent,
        ConfirmedClickDirective,
        JsonPipe,
        TinyCustom,
    ],
    template: `
        <app-dialog-modal-wrapper maxWidth="1000px">
            <form (ngSubmit)="submit()" [formGroup]="form" class="p-4">
                <div
                    class="flex flex-col gap-y-4 md:flex-row items-center justify-between mb-4 text-[#384250] font-bold">
                    <div *ngIf="label" class="text-xl">{{ label }}</div>
                    <div class="flex gap-2" [class.ml-auto]="!label">
                        <ng-container *ngIf="showSaveAndCancel; else simpleClose">
                            <app-button
                                type="button"
                                color="transparent"
                                (confirmedClick)="close()"
                                dialogBody="Möchtest du wirklich deine Änderungen verwerfen?"
                                [showConfirm]="form.dirty">
                                Abbrechen
                            </app-button>
                            <app-button type="submit" color="primary"> Speichern</app-button>
                        </ng-container>

                        <ng-template #simpleClose>
                            <button type="submit" class="ml-auto">
                                <fa-icon size="2x" [icon]="['fas', 'circle-xmark']"></fa-icon>
                            </button>
                        </ng-template>
                    </div>
                </div>
                <div class="text-6xl max-w-full h-full relative">
                    <app-tiny-custom
                        formControlName="editor"
                        [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 500,
                            placeholder: this.placeholder,
                            menubar: false,
                            plugins: ['fullscreen', 'lists'],
                            toolbar: 'bold italic | bullist numlist | fullscreen'
                        }">
                    </app-tiny-custom>
                    <div
                        *ngIf="loading"
                        class="bg-white/70 flex absolute inset-0 w-full h-full items-center justify-center z-50">
                        <span class="animate-spin"> <fa-icon [icon]="['fas', 'spinner']" size="sm"></fa-icon></span>
                    </div>
                </div>
            </form>
        </app-dialog-modal-wrapper>
    `,
    styleUrl: './modal-wysiwyg-editor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class ModalWysiwygEditorComponent implements IsDialogModal {
    @Input() value!: string | null;
    @Input() placeholder?: string;
    @Input() label?: string;
    @Input() loading?: boolean;
    @Input() showSaveAndCancel: boolean | undefined = false;

    dialogEventResponse = new EventEmitter<DialogEventResponse<string>>();

    form = new FormGroup({
        editor: new FormControl('', { nonNullable: true }),
    });

    formGroupDirty = toSignal(this.form.valueChanges.pipe(map(() => this.form.dirty)), { initialValue: false });

    ngOnInit(): void {
        if (this.value) {
            this.form.patchValue({ editor: this.value });
        }
    }

    close() {
        this.dialogEventResponse.emit({ type: DialogEvents.cancel });
    }

    submit() {
        if (this.form.valid) {
            this.dialogEventResponse.emit({ type: DialogEvents.confirm, value: this.form.get('editor')?.value });
        }
    }
}
