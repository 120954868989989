import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { faBooks } from '@fortawesome/pro-regular-svg-icons';
import {
    faHeartbeat,
    faList,
    faPhone,
    faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { InfoService } from '../../../shared/info.service';

@Component({
    selector: 'app-channel',
    template: `
        <app-default-layout-with-navigation>
            <div class="my-auto">
                <h1 class="text-center mb-[75px] font-serif">Sprachkanäle</h1>
                <div class="flex flex-col justify-center items-center">
                    <nav class="flex flex-row flex-wrap max-w-[810px]">
                        <app-dashboard-card
                            title="Raum 1"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '1']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>

                        <app-dashboard-card
                            title="Raum 2"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '2']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>

                        <app-dashboard-card
                            title="Raum 3"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '3']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>

                        <div class="w-full mt-16"></div>

                        <app-dashboard-card
                            title="Raum 4"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '4']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>

                        <app-dashboard-card
                            title="Raum 5"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '5']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>

                        <app-dashboard-card
                            title="Raum 6"
                            description=""
                            [icon]="faPhone"
                            [link]="['/channel', '6']"
                            cardClass="bg-[#a9a39a]"
                            circleClass="text-[#a9a39a]"></app-dashboard-card>
                    </nav>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./channel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelComponent implements OnInit {
    faBooks = faBooks;
    faUsers = faUsers;
    faHeartBeat = faHeartbeat;
    faList = faList;
    faPhone = faPhone;

    constructor(private infoService: InfoService) {}

    ngOnInit(): void {
        this.infoService.setCurrentPage('channel');
    }
}
