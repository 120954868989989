import { Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { AsyncPipe, JsonPipe, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    inject,
    Input,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    animationFrames,
    BehaviorSubject,
    combineLatestWith,
    debounceTime,
    firstValueFrom,
    Observable,
    shareReplay,
    Subject,
    take,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedModule } from '../../shared.module';
import { isMaxCountInvalid, isMinCountInvalid } from '../../utility/input.validations';
import { InputErrorMessagesComponent } from '../input-error-messages/input-error-messages.component';
import { TypedValidationErrors } from '../types/typed-validation-errors.type';

export interface InputMultiSelectOption {
    label: string;
    value: string;
}

export interface InputMultiSelectedOption extends InputMultiSelectOption {
    selected?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-input-select-multi',
    standalone: true,
    imports: [
        InputErrorMessagesComponent,
        NgIf,
        NgClass,
        NgForOf,
        AsyncPipe,
        JsonPipe,
        SharedModule,
        OverlayModule,
        PortalModule,
        NgStyle,
    ],
    template: `
        <label class="form-label font-bold" *ngIf="label && label.length > 0">
            {{ label }}<span *ngIf="required">*</span>
        </label>
        <div class="bg-slate-100 p-2 rounded relative w-full">
            <div
                #select
                class="form-input flex rounded-md border-2 border-black"
                [tabIndex]="this.isDisabled ? -1 : 0"
                (click)="showDropDown()"
                (blur)="onTouch()"
                role="listbox">
                <div class="flex flex-wrap gap-2 min-h-[40px]">
                    <ng-container *ngIf="$selectedOptions | async; let options">
                        <ng-container *ngIf="options.length > 0; else nothingSelected">
                            <div
                                *ngFor="let selectedOption of options"
                                class="py-1 px-3 font-medium bg-beige text-primary rounded-sm inline-flex items-center gap-2 cursor-pointer text-md"
                                (click)="onChange(selectedOption); $event.stopPropagation()">
                                <span>{{ selectedOption.label }}</span>
                                <fa-icon [icon]="['fas', 'xmark']" size="lg"></fa-icon>
                            </div>
                        </ng-container>

                        <ng-template #nothingSelected>
                            <div class="w-full h-full inline-flex items-center text-gray-400">{{ placeholder }}</div>
                        </ng-template>
                    </ng-container>
                </div>

                <div
                    class="w-10 h-full ml-auto flex items-center justify-end cursor-pointer"
                    [ngClass]="dropDownOpen() ? 'text-primary' : 'text-gray-400 hover:text-primary'">
                    <fa-icon
                        [icon]="['fas', 'chevron-down']"
                        class="transition-transform"
                        [class.rotate-180]="dropDownOpen()"></fa-icon>
                </div>
            </div>

            <ng-template cdkPortal class="dropdown">
                <div class="rounded-md bg-white py-4 shadow-md w-full max-h-svh overflow-auto">
                    <div
                        *ngFor="let option of $fullOptions | async"
                        (click)="onChange(option)"
                        class="flex items-center font-bold gap-2 cursor-pointer px-6 py-2 group/option"
                        [ngClass]="option.selected ? 'text-blue' : 'text-gray-400 hover:text-blue hover:bg-slate-100'">
                        <span
                            class="inline-flex w-6 h-6 rounded-full items-center justify-center flex-shrink-0"
                            [ngClass]="option.selected ? 'bg-gray-300' : 'bg-gray-200'">
                            <fa-icon
                                [icon]="['fas', 'check']"
                                [ngClass]="
                                    option.selected ? 'text-blue' : 'text-transparent group-hover/option:text-blue'
                                "></fa-icon>
                        </span>
                        <span>{{ option.label }}</span>
                    </div>
                </div>
            </ng-template>
        </div>

        <app-input-error-messages
            [errorObject]="errorObject()"
            [touched]="touched()"
            *ngIf="errorObject() && touched()"></app-input-error-messages>
    `,
    styleUrl: './input-select-multi.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSelectMultiComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputSelectMultiComponent),
            multi: true,
        },
    ],
    host: {
        class: 'contents',
    },
})
export class InputSelectMultiComponent implements OnInit, OnDestroy {
    private overlayRef!: OverlayRef;
    @ViewChild(CdkPortal, { static: true }) public contentTemplate!: CdkPortal;
    @ViewChild('select', { static: true }) public select!: ElementRef;

    @Input()
    label?: string;
    @Input() placeholder: string = 'Nichts ausgewählt';
    @Input() required?: boolean;
    @Input() maxSelect?: number;

    @Input()
    set options(options: string[] | InputMultiSelectOption[]) {
        const mapped = options.map(option => {
            if (typeof option !== 'string') {
                return option;
            } else {
                return {
                    label: option,
                    value: option,
                };
            }
        });

        this.$options.next(mapped);
    }

    overlay = inject(Overlay);
    cdr = inject(ChangeDetectorRef);
    resizeObserver!: ResizeObserver;

    isDisabled = false;
    touched = signal(false);

    errorObject: WritableSignal<TypedValidationErrors | null> = signal(null);

    dropDownOpen = signal(false);

    $options = new BehaviorSubject<InputMultiSelectOption[]>([]);
    $selected = new BehaviorSubject<string[]>([]);
    $fullOptions: Observable<InputMultiSelectedOption[]> = this.$selected.pipe(
        combineLatestWith(this.$options),
        map(([selection, options]) => {
            return options.map(option => {
                return {
                    ...option,
                    selected: selection.includes(option.value),
                };
            });
        }),
    );
    $selectedOptions = this.$fullOptions.pipe(
        map(options => options.filter(o => o.selected)),
        shareReplay(),
    );
    $widthChange = new Subject<ResizeObserverEntry[]>();

    ngOnInit() {
        this.$selected.pipe(untilDestroyed(this)).subscribe(() => this.updateDropDownWidthAndPosition());
        this.resizeObserver = new ResizeObserver(entries => this.$widthChange.next(entries));
        this.$widthChange
            .pipe(debounceTime(100), untilDestroyed(this))
            .subscribe(() => this.updateDropDownWidthAndPosition());
        this.resizeObserver.observe(this.select.nativeElement);
    }

    ngOnDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.select.nativeElement);
        }
    }

    changeFn = (p: string[]) => {};

    async onChange(option: InputMultiSelectOption) {
        const selection = [...(await firstValueFrom(this.$selected))];
        const i = selection.indexOf(option.value);
        if (i === -1) {
            selection.push(option.value);
        } else {
            selection.splice(i, 1);
        }
        this.$selected.next(selection);
        this.changeFn(selection);
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    touchFn = () => {};

    onTouch() {
        this.touched.set(true);
        this.touchFn();
    }

    writeValue(value: string[]): void {
        this.$selected.next(value);
    }

    // error validation
    setErrorAndReturn(errors: TypedValidationErrors | null) {
        this.errorObject.set(errors);
        return errors;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Validate required
        if (this.required && isMinCountInvalid(control.value, 1)) {
            return this.setErrorAndReturn({ required: true });
        }

        if (!!this.maxSelect && isMaxCountInvalid(control.value, this.maxSelect)) {
            return this.setErrorAndReturn({ maxSelect: this.maxSelect });
        }

        // No errors found
        return this.setErrorAndReturn(null);
    }

    get overlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.select.nativeElement)
            .withPush(true)
            .withPositions([
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                    offsetY: 4,
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                    offsetY: -4,
                },
            ]);

        const scrollStrategy = this.overlay.scrollStrategies.reposition();

        return new OverlayConfig({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
        });
    }

    updateDropDownWidthAndPosition() {
        if (!this.overlayRef) {
            return;
        }

        animationFrames()
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => {
                const refRectWidth = this.select.nativeElement.getBoundingClientRect().width;
                this.overlayRef.updateSize({ width: refRectWidth });
                this.overlayRef.updatePosition();
            });
    }

    hideDropDown() {
        this.overlayRef?.detach();
        this.dropDownOpen.set(false);
    }

    showDropDown() {
        this.overlayRef = this.overlay.create(this.overlayConfig);
        this.overlayRef.attach(this.contentTemplate);
        this.updateDropDownWidthAndPosition();
        this.overlayRef
            .backdropClick()
            .pipe(take(1), untilDestroyed(this))
            .subscribe(() => this.hideDropDown());
        this.dropDownOpen.set(true);
    }
}
