import { HttpClient, HttpEventType } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { DBSchema } from 'idb';
import { last, Observable, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AddLibraryMediaEntryDto, LibraryEntryActions } from '../+store/library-entry/library-entry.actions';
import { LibraryEntry, LibraryEntryMediaPurpose } from '../+store/library-entry/library-entry.model';
import { environment } from '../../../environments/environment';
import { AudioRecordBlob } from './library-recording.service';

export interface AudioRecordDB extends DBSchema {
    [audioRecordStoreName]: {
        key: string;
        value: AudioRecordBlob;
    };
}

export const audioRecordStoreName = 'audioRecords';

@Injectable({
    providedIn: 'root',
})
export class LibraryEntryService {
    constructor(
        private http: HttpClient,
        private store: Store,
        private actions: Actions,
    ) {}

    router = inject(Router);

    loadAll() {
        return this.http.get<LibraryEntry[]>(`${environment.api}/library`);
    }

    loadById(id: string) {
        return this.http.get<LibraryEntry>(`${environment.api}/library/entry/${id}`);
    }

    updateEntry(libraryEntry: Update<LibraryEntry>) {
        return this.http.patch<LibraryEntry>(
            `${environment.api}/library/entry/${libraryEntry.id}`,
            libraryEntry.changes,
        );
    }

    createLibraryEntry(entry: Partial<LibraryEntry>) {
        return this.http.post<LibraryEntry>(`${environment.api}/library/entry`, entry);
    }

    deleteById(id: string) {
        return this.http.delete(`${environment.api}/library/entry/${id}`);
    }

    addMediaToLibraryEntry(libraryEntryId: string, mediaFiles: AddLibraryMediaEntryDto) {
        const formData = new FormData();

        // todo maybe in future more generic name like media?
        mediaFiles.files.forEach(mediaFile => {
            formData.append(`images`, mediaFile);
        });

        if (mediaFiles.connectedRecordingId) {
            formData.append('connectedRecordingId', mediaFiles.connectedRecordingId);
        }
        if (mediaFiles.imageRecordingTimestamp !== undefined) {
            formData.append('imageRecordingTimestamp', mediaFiles.imageRecordingTimestamp.toString());
        }

        console.log(formData);

        return this.http
            .post<LibraryEntry>(`${environment.api}/library/entry/${libraryEntryId}/media`, formData, {
                headers: {},
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                // todo add Upload Progress in UI
                tap(event => {
                    console.log('Event:');
                    console.log(event);
                    console.log(event.type);
                }),
                last(),
                map(httpResponse => {
                    if (httpResponse.type !== HttpEventType.Response) throw new Error('No correct HttpResponse');

                    return httpResponse.body;
                }),
            );
    }

    deleteMediaOfLibraryEntry(libraryEntryId: string, mediaId: string) {
        return this.http.delete<LibraryEntry>(`${environment.api}/library/entry/${libraryEntryId}/media/${mediaId}`);
    }

    createLibraryEntryForRecord(entryInfo: {
        impulseQuestionId?: string;
    }): Observable<{ libraryEntryId: string; audioRecordId: string }> {
        this.store.dispatch(LibraryEntryActions.createLibraryEntry({ libraryEntry: { ...entryInfo } }));

        return this.actions.pipe(
            ofType(LibraryEntryActions.createLibraryEntrySuccess, LibraryEntryActions.createLibraryEntryFailure),
            first(),
            map(action => {
                if (action.type === LibraryEntryActions.createLibraryEntryFailure.type) {
                    throw new Error('Missing Media oder Audio Record Entry');
                } else {
                    const entry = action.libraryEntry;
                    const media = entry.media.find(media => media.purpose === LibraryEntryMediaPurpose.audioRecord);

                    if (!entry || !media) throw new Error('Missing Media oder Audio Record Entry');

                    return { libraryEntryId: entry.id, audioRecordId: media.id };
                }
            }),
        );
    }
}
