import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-auth-box',
    standalone: true,
    imports: [SharedModule],
    template: `
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md border-2 border-l-4 border-b-4 border border-blue rounded-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                    <img class="mx-auto h-12 w-auto" src="/assets/logos/logo.png" alt="TeresaLogo" />
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrl: './auth-box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthBoxComponent {}
