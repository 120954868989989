import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DiaryEntry } from './+store/diary.reducer';

@Injectable({
    providedIn: 'root',
})
export class DiaryService {
    constructor(private http: HttpClient) {}

    getAllEntries() {
        return this.http.get(`${environment.api}/diary`);
    }

    createEntry(entry: DiaryEntry): Observable<DiaryEntry> {
        return this.http.post<DiaryEntry>(
            `${environment.api}/diary/create`,
            entry,
        );
    }
}
