import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducer';

@Component({
    selector: 'app-email-send',
    template: `
        <div class="flex items-center bg-beige h-full w-full font-sans">
            <div class="w-1/2 p-5 w-1/2 p-10 flex justify-end">
                <div>
                    <div class="relative overflow-hidden rounded-lg shadow-lg">
                        <img
                            class="object-cover max-h-[75vh] border-2 border-l-4 border-b-4 border border-blue rounded-md"
                            [src]="image"
                            alt="" />
                        <div class="absolute bottom-0 left-0 p-2 m-4 bg-blue rounded-xl text-center">
                            <h3 class="mb-3 text-white font-sans">
                                Wir haben alle eine individuelle Geschichte, darin sind wir uns gleich.
                            </h3>
                        </div>
                    </div>
                    <app-legal-links></app-legal-links>
                </div>
            </div>
            <div class="w-1/2 p-10 flex max-w-md">
                <div class="flex flex-col w-full">
                    <div class="mt-8 border-2 border-l-4 border-b-4 border border-blue rounded-md">
                        <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
                            <div>
                                <img class="mx-auto h-12 w-auto" src="/assets/logos/logo.png" alt="TeresaLogo" />
                            </div>
                            <h2 class="m-4 mx-8 text-2xl flex justify-center text-center font-sans">
                                Registrierung erfolgreich!
                            </h2>
                            <p class="text-center font-sans">
                                Wir haben dir eine E-Mail geschickt. Um die Registrierung abzuschließen, klicke bitte
                                auf den Link in der E-Mail.
                            </p>
                        </div>
                    </div>
                    <app-button
                        class="flex justify-center m-2 font-sans"
                        [routerLink]="['/auth', 'login']"
                        color="white">
                        Zum Login
                    </app-button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./email-sent.component.scss'],
})
export class EmailSentComponent implements OnInit {
    emailSendForm!: UntypedFormGroup;
    image = `/assets/images/woman-${Math.floor(Math.random() * 3) + 1}.jpg`;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {}
}
