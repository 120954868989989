import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, Subscription, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryEntryActions } from '../../../../library/+store/library-entry/library-entry.actions';
import { LibraryEntryMedia } from '../../../../library/+store/library-entry/library-entry.model';
import { AudioRecordBlob, LibraryRecordingService } from '../../../../library/services/library-recording.service';
import { VoiceRecorderComponent } from '../voice-recorder.component';

@UntilDestroy()
@Component({
    selector: 'app-voice-recorder-recorder',
    template: `
        <div class="row-start-1 col-start-3">
            <app-button
                rounded="rounded-full"
                [color]="recording() ? 'red' : 'green'"
                [square]="true"
                (click)="toggleRecord()"
                [disabled]="cantClick()">
                <fa-icon [icon]="['fas', 'microphone']" size="2x" [fixedWidth]="true" *ngIf="!recording()"></fa-icon>

                <fa-icon [icon]="['fas', 'pause']" size="2x" [fixedWidth]="true" *ngIf="recording()"></fa-icon>
            </app-button>
        </div>

        <div class="col-start-2 row-start-1 py-3 pr-3 flex justify-center">
            <svg #visualizer class="w-[300px] h-[50px]" viewBox="0 0 300 50" preserveAspectRatio="none"></svg>
        </div>

        <div class="flex flex-col items-center justify-center row-start-2 col-span-3 mt-3">
            <div
                class="px-4 py-1 bg-blue-200 text-blue-700 font-semibold rounded-full"
                *ngIf="!recording() && readyToRecord()">
                Bereit zur Aufnahme
            </div>
            <div class="px-4 py-1 bg-green-200 text-green-700 font-semibold rounded-full" *ngIf="recording()">
                Aufnahme läuft
            </div>
            <div class="px-4 py-1 bg-red-200 text-red-700 font-semibold rounded-full" *ngIf="errorMsg()">
                {{ errorMsg() }}
            </div>
            <div class="mt-2">{{ recordTime() | date: 'mm:ss' }} Minuten</div>
        </div>
    `,
    styleUrl: './voice-recorder-recorder.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class VoiceRecorderRecorderComponent implements OnInit, OnDestroy {
    @ViewChild('visualizer', { static: false })
    visualizerRef!: ElementRef<SVGElement>;

    @Input() libraryEntryId!: string;
    @Input() userId!: string | undefined;
    @Input()
    set mediaEntry(entry: LibraryEntryMedia) {
        // only set once to prevent overriding with temp values
        if (this._mediaEntry()) {
            return;
        }
        this._mediaEntry.set(entry);
        this.recordTime.set(entry.duration ? entry.duration : 0);
        this.mediaId = entry.id;
    }

    wrapperComponent = inject(VoiceRecorderComponent);
    store = inject(Store);
    actions = inject(Actions);
    zone = inject(NgZone);
    cdr = inject(ChangeDetectorRef);
    recordingService = inject(LibraryRecordingService);

    recorder?: MediaRecorder | null;
    stream?: MediaStream | null;
    timer?: Subscription;
    mediaId!: string;

    _mediaEntry = signal<LibraryEntryMedia | null>(null);
    readyToRecord = signal(false);
    recordTime = signal(0);
    errorMsg = signal<string | null>(null);

    recording = signal<boolean>(false);
    waitingForRecording = signal<boolean>(false);

    cantClick = computed(() => {
        return this.waitingForRecording() || !this.readyToRecord();
    });

    ngOnInit() {
        if (this._mediaEntry() && this.userId && this.libraryEntryId) {
            this.readyToRecord.set(true);
            this.wrapperComponent?.currentTime.emit(this.recordTime());
        }
    }

    ngOnDestroy() {
        this.stopRecord();
    }

    startTimer() {
        this.timer = interval(1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.recordTime.update(time => time + 1000);
                this.wrapperComponent?.currentTime.emit(this.recordTime());
            });
    }

    stopTimer() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    }

    async initializeRecorder() {
        const mimeTypes = ['audio/webm', 'audio/mp4'];

        let mimeType;

        for (const type of mimeTypes) {
            if (MediaRecorder.isTypeSupported(type)) {
                mimeType = type;
                break;
            }
        }

        if (!mimeType) {
            console.log('mime type not supported');
            throw Error(`No supported mimetype found ${mimeType}`);
        } else {
            console.log(`use mime type: ${mimeType}`);
        }

        const constraints = {
            audio: {
                echoCancellation: true,
                sampleRate: 48000, // 48kHz
                channelCount: 1, // mono
            },
        };

        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.recorder = new MediaRecorder(this.stream, { mimeType });
    }

    async toggleRecord() {
        const state = this.recorder?.state;

        if (state === 'recording') {
            await this.stopRecord();
        } else {
            await this.startRecord();
        }
    }

    async record(groupId: string) {
        try {
            // each time data is ready we save it in the browser db with metadata for the user and media entries
            this.recorder!.ondataavailable = e => this.saveData(e, groupId);

            // this slice time sets upload emit/upload interval
            this.recorder!.start(5000);
            this.startTimer();
            this.recording.set(true);
            this.startVisualizing();
        } catch (e) {
            console.log(e);
        }
    }

    saveData(e: BlobEvent, groupId: string) {
        this.zone.run(() => {
            const audioBlob: AudioRecordBlob = {
                mediaId: this.mediaId,
                libraryEntryId: this.libraryEntryId,
                timestamp: Date.now().toString(),
                data: e.data,
                userId: this.userId!,
                groupId,
                last: this.recorder?.state !== 'recording',
            };

            this.recordingService.addToDB(audioBlob).catch(() => {
                this.errorMsg.set(
                    'Beim speichern der Aufnahme ist ein Problem aufgetreten. Die Aufnahme wurde gestoppt',
                );
                console.error('something went wrong in saving your record');
                this.stopRecord();
            });
        });
    }

    async startRecord() {
        if (this.recorder || this.stream || !this.readyToRecord()) {
            return;
        }

        try {
            await this.initializeRecorder();
        } catch (e) {
            this.errorMsg.set('Audioaufnahme auf diesem Gerät wird nicht unterstützt.');
            return;
        }

        this.waitingForRecording.set(true);

        // we get a new recording group id for metadata and save it on the server - then we start with recording
        this.store.dispatch(
            LibraryEntryActions.startLibraryEntryMediaRecording({
                libraryEntryId: this.libraryEntryId,
                mediaId: this.mediaId,
            }),
        );
        this.actions
            .pipe(
                ofType(
                    LibraryEntryActions.startLibraryEntryMediaRecordingSuccess,
                    LibraryEntryActions.startLibraryEntryMediaRecordingFailure,
                ),
                take(1),
                map(action => {
                    if (action.type === LibraryEntryActions.startLibraryEntryMediaRecordingFailure.type) {
                        throw new Error('Error in getting Record Group');
                    }
                    return action.res.recordingGroupId;
                }),
            )
            .subscribe({
                next: groupId => {
                    this.waitingForRecording.set(false);
                    this.record(groupId);
                },
                error: err => {
                    console.log(err);
                    this.errorMsg.set('Leider gibt es ein Problem beim erstellen der Aufnahme');
                    this.stopRecord();
                    this.waitingForRecording.set(false);
                },
            });
    }

    async stopRecord() {
        this.recorder?.stop();
        this.stopTimer();
        this.recording.set(false);
        this.stream?.getTracks().forEach(track => track.stop());
        this.recorder = undefined;
        this.stream = undefined;
    }

    startVisualizing() {
        // audio visualization
        const width = 300;
        const height = 50;
        const audioContext = new AudioContext();
        const audioStream = audioContext.createMediaStreamSource(this.stream!);
        const analyzer = audioContext.createAnalyser();
        audioStream.connect(analyzer);
        analyzer.fftSize = 64;
        const frequencyArray = new Uint8Array(analyzer.frequencyBinCount);
        this.visualizerRef.nativeElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
        const paths = this.visualizerRef.nativeElement.getElementsByTagName('path');

        const barWidth = width / (analyzer.frequencyBinCount / 2) / 1.5;
        const barSpacing = barWidth * 0.5;

        const draw = () => {
            analyzer.getByteFrequencyData(frequencyArray);

            for (let i = 0; i < analyzer.frequencyBinCount / 2; i++) {
                const frequency = Math.floor((frequencyArray[i] / 255) * height);
                if (!paths[i]) {
                    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path.setAttribute('stroke-width', barWidth.toString());
                    path.setAttribute('stroke', 'black');
                    path.setAttribute('stroke-linecap', 'round');
                    this.visualizerRef.nativeElement.appendChild(path);
                }
                const adjustedLength = Math.floor(frequency * 0.6);
                paths[i].setAttribute(
                    'd',
                    `M${i * barWidth + i * barSpacing + barWidth / 2}, ${
                        height / 2 - adjustedLength / 2
                    } v ${adjustedLength}`,
                );
            }

            if (this.recorder && this.recording()) {
                requestAnimationFrame(draw);
            }
        };

        requestAnimationFrame(draw);
        this.cdr.markForCheck();
    }
}
