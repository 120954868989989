import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    Signal,
    signal,
    ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, switchMap, take, takeUntil } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LibraryEntryActions } from '../../+store/library-entry/library-entry.actions';
import { LibraryEntry, LibraryEntryMedia } from '../../+store/library-entry/library-entry.model';
import { selectLibraryEntryByIdNoNull } from '../../+store/library-entry/library-entry.selectors';
import { LibraryImpulseQuestionActions } from '../../+store/library-impulse-question/library-impulse-question.actions';

import { LibraryImpulseQuestion } from '../../+store/library-impulse-question/library-impulse-question.model';
import { selectImpulseQuestionById } from '../../+store/library-impulse-question/library-impulse-question.reducer';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';
import { selectUserId } from '../../../user/+store/user.selectors';
import { LibraryEntryFinishCreationModalComponent } from '../../components/library-entry-finish-creation-modal/library-entry-finish-creation-modal.component';
import { LibraryRecordingService } from '../../services/library-recording.service';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ConfirmedClickDirective } from '../../../shared/directives/confirmed-click/confirmed-click.directive';
import { Dialog } from '@angular/cdk/dialog';
import { DialogEvents } from '../../../shared/modal/dialog-modal-footer/dialog-modal-footer.component';

@UntilDestroy()
@Component({
    selector: 'app-library-entry-create',
    standalone: true,
    template: `
        <app-default-layout-with-navigation>
            <div class="grow flex flex-col items-center">
                <app-page-title class="mt-5" [backButtonLink]="['../']">
                    <p *ngIf="!impulseQuestion()">Freie Erzählung</p>
                    <p *ngIf="impulseQuestion()">Inspirationsfrage</p>
                    <div right-slot>
                        <fa-icon
                            [icon]="['fas', 'cloud-arrow-up']"
                            size="2x"
                            *ngIf="uploading$ | async"
                            class="text-slate-500 animate-pulse"></fa-icon>
                    </div>
                </app-page-title>

                <ng-container *ngIf="libraryEntry(); let libEntry">
                    <div
                        [ngClass]="{ 'bg-[#FACEC3]': impulseQuestion() }"
                        class="mx-3 flex flex-col justify-center py-6 px-8 rounded-xl">
                        <div class="text-center" *ngIf="impulseQuestion() as question">
                            <h3 class="text-primary">{{ question.title }}</h3>
                        </div>
                        <div class="mt-12 mx-auto">
                            <ng-container *ngIf="mediaEntry(); let media">
                                <app-voice-recorder
                                    #voiceRecorder
                                    [libraryEntryId]="libEntry.id"
                                    [mediaEntry]="media"
                                    [userId]="userId"
                                    *ngIf="userId$ | async; let userId"
                                    (currentTime)="currentTime.set($event)">
                                </app-voice-recorder>
                            </ng-container>
                        </div>
                    </div>

                    <div class="mt-12 mb-6">
                        <app-button type="button" (click)="openFinishCreationModal()">
                            Erinnerung abschließen
                        </app-button>
                    </div>

                    <section
                        class="mt-auto w-full py-6 bg-neutral-100 flex justify-center items-center space-x-6 overflow-hidden px-4"
                        *ngIf="libraryEntry() as libraryEntry">
                        <div class="flex overflow-auto space-x-6">
                            <ng-container *ngIf="(libraryEntry.media | mediaType: 'image').length > 0">
                                <div class="shrink-0" *ngFor="let media of libraryEntry.media | mediaType: 'image'">
                                    <div class="relative">
                                        <img
                                            height="170"
                                            width="170"
                                            class="object-cover h-[170px] w-[170px] rounded-md"
                                            [src]="media.location"
                                            alt="Erinnerungsbild" />
                                        <div class="absolute bottom-4 right-4">
                                            <app-button
                                                color="red"
                                                [square]="true"
                                                rounded="rounded-full"
                                                class="shadow"
                                                (confirmedClick)="deleteMediaEntry(media)"
                                                dialogType="ConfirmedDelete"
                                                dialogBody="Möchtest du das Bild wirklich wieder löschen?">
                                                <fa-icon
                                                    class="text-white"
                                                    size="lg"
                                                    [icon]="['fas', 'trash-xmark']"
                                                    [fixedWidth]="true"></fa-icon>
                                            </app-button>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        {{ media.imageRecordingTimestamp | date: 'mm:ss' }}
                                    </div>
                                </div>
                            </ng-container>
                            <div
                                *ngIf="uploadingImages()"
                                class="w-[170px] h-[170px] rounded-md bg-white flex justify-center items-center">
                                <div class="text-primary animate-spin">
                                    <fa-icon size="2xl" [icon]="['fas', 'loader']" [fixedWidth]="true"></fa-icon>
                                </div>
                            </div>
                        </div>

                        <div class="h-[170px] flex items-center shrink-0 px-12 space-x-3">
                            <app-button [square]="true" rounded="rounded-full" (click)="fileInput.click()">
                                <fa-icon size="2x" [icon]="['fas', 'image']" [fixedWidth]="true"></fa-icon>
                            </app-button>

                            <app-button [square]="true" rounded="rounded-full" (click)="fileInputEnvironment.click()">
                                <fa-icon size="2x" [icon]="['fas', 'camera']" [fixedWidth]="true"></fa-icon>
                            </app-button>
                        </div>
                        <input
                            type="file"
                            class="hidden"
                            accept="image/*"
                            #fileInput
                            (input)="addImage($event)"
                            multiple />
                        <input
                            type="file"
                            class="hidden"
                            accept="image/*"
                            capture="environment"
                            #fileInputEnvironment
                            (input)="addImage($event)"
                            multiple />
                    </section>
                </ng-container>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./library-entry-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DefaultLayoutWithNavigationComponent,
        SharedModule,
        NgIf,
        AsyncPipe,
        NgClass,
        ConfirmedClickDirective,
        DatePipe,
        NgForOf,
    ],
})
export class LibraryEntryCreateComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: false })
    fileInput!: ElementRef<HTMLInputElement>;

    store = inject(Store);
    actions = inject(Actions);
    router = inject(Router);
    activeRoute = inject(ActivatedRoute);
    libraryRecordService = inject(LibraryRecordingService);
    dialog = inject(Dialog);

    uploadingImages = signal<boolean>(false);
    currentTime = signal(0);

    impulseQuestion!: Signal<LibraryImpulseQuestion | undefined>;
    libraryEntry!: Signal<LibraryEntry | undefined>;
    mediaEntry!: Signal<LibraryEntryMedia | undefined>;

    userId$!: Observable<string | undefined>;
    uploading$!: Observable<Boolean>;

    constructor() {
        const entry$ = this.store.pipe(selectLibraryEntryByIdNoNull(this.activeRoute.snapshot.paramMap.get('id')!));

        const media$ = entry$.pipe(
            map(entry => entry.media.find(media => media.id === this.activeRoute.snapshot.paramMap.get('recordid'))),
            filterNullish(),
        );

        this.uploading$ = entry$.pipe(switchMap(entry => this.libraryRecordService.entryIsLoading(entry.id)));
        this.userId$ = this.store.pipe(select(selectUserId), filterNullish());

        const question$ = entry$.pipe(
            switchMap(entry => this.store.pipe(selectImpulseQuestionById(entry.impulseQuestionId))),
        );

        this.libraryEntry = toSignal(entry$);
        this.impulseQuestion = toSignal(question$);
        this.mediaEntry = toSignal(media$);

        combineLatest([question$, entry$])
            .pipe(take(1))
            .subscribe(([question, entry]) => {
                if (!question && entry.impulseQuestionId) {
                    this.store.dispatch(
                        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionById({ id: entry.impulseQuestionId }),
                    );
                }
            });
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        // this.modalService.closeCurrentModal();
    }

    addImage(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files!;

        if (files.length === 0) {
            return;
        }

        this.uploadingImages.set(true);

        const media = this.mediaEntry() as LibraryEntryMedia;
        const libEntry = this.libraryEntry() as LibraryEntry;

        this.store.dispatch(
            LibraryEntryActions.addLibraryEntryMedia({
                libraryEntryId: libEntry.id,
                mediaFiles: {
                    connectedRecordingId: media.id,
                    imageRecordingTimestamp: this.currentTime(),
                    files: Array.from(files),
                },
            }),
        );

        this.actions
            .pipe(
                ofType(
                    LibraryEntryActions.addLibraryEntryMediaSuccess,
                    LibraryEntryActions.addLibraryEntryMediaFailure,
                ),
                first(),
            )
            .subscribe(() => this.uploadingImages.set(false));

        this.fileInput.nativeElement.value = '';
    }

    deleteMediaEntry(mediaEntry: LibraryEntryMedia) {
        const entry = this.libraryEntry() as LibraryEntry;
        this.store.dispatch(
            LibraryEntryActions.deleteLibraryEntryMedia({ libraryEntryId: entry.id, mediaId: mediaEntry.id }),
        );
    }

    // opens modal for changing text details of library-entry
    async openFinishCreationModal() {
        const entry = this.libraryEntry() as LibraryEntry;
        const dialogRef = this.dialog.open(LibraryEntryFinishCreationModalComponent);
        const instance = dialogRef.componentInstance as LibraryEntryFinishCreationModalComponent;

        instance.libraryEntry = entry;

        instance.dialogEventResponse.pipe(takeUntil(dialogRef.closed)).subscribe(res => {
            if (res.type === DialogEvents.confirm && res.value) {
                this.store.dispatch(
                    LibraryEntryActions.updateLibraryEntry({ libraryEntry: { id: entry.id, changes: res.value } }),
                );
                this.actions
                    .pipe(
                        ofType(
                            LibraryEntryActions.updateLibraryEntrySuccess,
                            LibraryEntryActions.updateLibraryEntryFailure,
                        ),
                        first(),
                        untilDestroyed(this),
                    )
                    .subscribe(action => {
                        if (action.type === LibraryEntryActions.updateLibraryEntrySuccess.type) {
                            dialogRef.close();
                            this.router.navigate(['/', 'lebensgeschichte', 'erinnerung', entry.id]);
                        }
                    });
            } else {
                dialogRef.close();
            }
        });
    }
}
