import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InfoService {
    private infoVisible: boolean | undefined;
    private $currentPage = new ReplaySubject<string>(1);
    public infoText$ = this.$currentPage.pipe(
        map(page => this.getInfoText(page)),
    );

    constructor(private http: HttpClient) {}

    setCurrentPage(page: string) {
        this.$currentPage.next(page);
    }

    getInfoText(page: string): string {
        switch (page.toLowerCase()) {
            case 'dashboard':
                return 'Hier siehst du das Hauptmenü mit allen Funktionen von Teresa. Über die runde Schaltfläche mit dem Häuschen – unten in der Mitte – kannst du jederzeit auf diese Seite zurück kommen. Über den Pfeil der nach rechts zeigt, kommst du zu weiteren Funktionen.';
            case 'library-index':
                return (
                    'Die Funktion ‚Lebensgeschichte‘ ist ein Streifzug durch die eigene Vergangenheit, auf den man, wenn man möchte, auch die Kinder, Enkel und Freunde mitnehmen kann. Seine Lebenserinnerungen festzuhalten, unterstützt nämlich nicht nur dabei sich selbst an wichtige Ereignisse zu erinnern, sondern erleichtert auch das Teilen mit Familie und Freunden.</br>' +
                    '</br>' +
                    'Die Lebenserinnerungen werden in Büchern aufgezeichnet, die auf einem virtuellen Regal stehen. Die Bücher sind nach Jahrzehnten beschriftet und sortiert. Jedes Buch beinhaltet die Erinnerungen des betreffenden Jahrzehnts.</br>' +
                    '</br>' +
                    'Dann gibt es noch spezielle Bücher wie:</br>' +
                    'Meine Persönlichkeit</br>' +
                    'Meine Rezepte</br>' +
                    'Meine Hobbies</br>' +
                    '</br>' +
                    'Du kannst auch selbst ein Buch anlegen. Tippe auf das leere Buch. Füge ein Bild und den Titel hinzu.'
                );
            case 'library-book-detail':
                return (
                    'Auf der linken Seite siehst du das Inhaltsverzeichnis deines Buches mit allen Einträgen die du machst. Auf der rechten Seite findest du zwei Möglichkeiten Erinnerungseinträge zu machen.</br>' +
                    'Du kannst selbst eine Erinnerung aufschreiben, die dir gerade einfällt oder du lässt dich durch Fragen bzw. Fotos inspirieren'
                );
            case 'detail':
                return 'Hier kannst du alle Informationen zu deiner Erinnerung eingeben, damit Teresa sie in der richtigen Reihenfolge im Buch sortiert und man weiß, um was es darin geht.';
            case 'audio':
                return 'Tippe einfach auf das blaue Mikrofon und beginne zu sprechen. Die Schaltfläche wird dann rot. Wenn du die Aufzeichnung beenden willst drücke erneut darauf. Gib teresa etwas Zeit um die Daten zu sichern. Erst wenn du unten eine graue Aufzeichnung siehst, ist die Aufnahme komplett gespeichert. Los gehts!';
            case 'text':
                return 'Hier kannst du über deine Tastatur deine Erinnerung eintippen oder mit dem Apple Stift handschriftlich schreiben.';
            case 'picture':
                return 'Um Fotos hinzuzufügen drücke auf das „+“. Dann öffnet sich automatisch die Kamera mit der du Fotos in einem Album abfotografieren kannst oder Aufnahmen von anderen Dingen machen kannst. Du kannst die Fotos jederzeit löschen und neue hinzufügen. Du bekommst auch die Auswahl Fotos z. B. aus deiner Mediendatenbank einzufügen.';
            case 'entry-detail':
                return 'Du kannst alle Eintragungen zu deiner Erinnerung jederzeit bearbeiten. Klicke dazu entweder auf die blauen Schaltflächen oder wähle unter „Bearbeiten“ was du tun möchtest.';
            case 'channel':
                return 'Die sechs Sprachkanäle sind wie Räume, in denen du dich mit einem anderen Teresa Nutzter für ein Video Telefonat verabreden kannst.';
            case 'channel-voice':
                return (
                    'KAMERA: Ist das Kamera Symbol durchgestrichen, bist du nicht zu sehen. Klicke es an um wieder sichtbar zu sein.</br>' +
                    '</br>' +
                    'TON: Hier kannst du den Ton an und abschalten und die Lautstärke regulieren. CHAT: Du kannst während des Anrufes auch mit deinem Gegenüber schreiben.</br>' +
                    '</br>' +
                    'LEUTE: ????</br>' +
                    '</br>' +
                    'ENDE: Um den Anruf zu beenden klicke auf diese Schaltfläche</br>' +
                    '</br>' +
                    'Unter dem Zahnrad und den drei Punkten oben rechts, kannst du weitere Einstellungen vornehmen.'
                );
            default:
                return 'Keine Informationen verfügbar.';
        }
    }

    toggleInfo() {
        this.infoVisible = !this.infoVisible;
    }

    isInfoVisible() {
        return this.infoVisible;
    }
}
