import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faBooks, faCalendar, faChartNetwork, faHeart, faUserFriends } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-main-module-selection',
    template: `
        <div class="grid grid-cols-3 gap-x-4">
            <a
                [routerLink]="['/lebensgeschichte']"
                class="bg-white rounded-lg px-5 py-4 w-full justify-center flex shadow">
                <fa-icon [icon]="faBooks" class="text-lg pr-3 text-blue"></fa-icon>
                <button class="text-lg font-bold">Biografie</button>
            </a>
            <a [routerLink]="['/channel']" class="bg-white rounded-lg px-5 py-4 w-full justify-center flex shadow">
                <fa-icon [icon]="faChartNetwork" class="text-lg pr-3 text-violet-700"></fa-icon>
                <button class="text-lg font-bold">Netzwerk</button>
            </a>
            <!--            <div class="bg-white rounded-lg px-5 py-4 w-full justify-center flex shadow opacity-50">-->
            <!--                <fa-icon [icon]="faHeart" class="text-lg pr-3 text-red-700"></fa-icon>-->
            <!--                <button class="text-lg font-bold cursor-not-allowed">Tagebuch</button>-->
            <!--            </div>-->
            <div class="bg-white rounded-lg px-5 py-4 w-full justify-center flex shadow opacity-50">
                <fa-icon [icon]="faCalendar" class="text-lg pr-3 text-blue"></fa-icon>
                <button class="text-lg font-bold cursor-not-allowed">Kalender</button>
            </div>
            <!--            <div class="bg-white rounded-lg px-5 py-4 w-full justify-center flex shadow opacity-50">-->
            <!--                <fa-icon [icon]="faUserFriends" class="text-lg pr-3 text-green-700"></fa-icon>-->
            <!--                <button class="text-lg font-bold cursor-not-allowed">Kontakte</button>-->
            <!--            </div>-->
            <!--            <div class="col-span-3 col-start-3 text-center text-sm text-gray-500 mt-2">-->
            <!--                Diese Funktionen werden zukünftig verfügbar sein-->
            <!--            </div>-->
        </div>
    `,
    styleUrls: ['./main-module-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainModuleSelectionComponent {
    protected readonly faCalendar = faCalendar;
    protected readonly faBooks = faBooks;
    protected readonly faUserFriends = faUserFriends;
    protected readonly faHeart = faHeart;
    protected readonly faChartNetwork = faChartNetwork;
}
