import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LibraryImpulseQuestionService } from '../../services/library-impulse-question.service';
import { LibraryImpulseQuestionActions } from './library-impulse-question.actions';

@Injectable()
export class LibraryImpulseQuestionEffects {
    actions$ = inject(Actions);
    libraryImpulseQuestionService = inject(LibraryImpulseQuestionService);

    loadImpulseQuestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryImpulseQuestionActions.loadLibraryImpulseQuestions),
            exhaustMap(action =>
                this.libraryImpulseQuestionService.loadAll().pipe(
                    map(impulseQuestions =>
                        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionsSuccess({
                            impulseQuestions,
                        }),
                    ),
                    catchError(error => {
                        return of(LibraryImpulseQuestionActions.loadLibraryImpulseQuestionsFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    loadImpulseQuestionById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LibraryImpulseQuestionActions.loadLibraryImpulseQuestionById),
            switchMap(action =>
                this.libraryImpulseQuestionService.loadById(action.id).pipe(
                    map(impulseQuestions =>
                        LibraryImpulseQuestionActions.loadLibraryImpulseQuestionByIdSuccess({
                            impulseQuestions,
                        }),
                    ),
                    catchError(error => {
                        return of(LibraryImpulseQuestionActions.loadLibraryImpulseQuestionByIdFailure({ error }));
                    }),
                ),
            ),
        ),
    );
}
