import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SubUserActions } from '../../+store/sub-user.actions';
import { SubUser } from '../../+store/sub-user.model';
import { selectSubUserLoading, selectSubUsersAll } from '../../+store/sub-user.selectors';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { SubuserTileComponent } from '../../components/subuser-tile/subuser-tile.component';

@Component({
    selector: 'app-caretaker-overview',
    standalone: true,
    template: `
        <app-default-layout-with-navigation [loading]="loading()">
            <div class="flex justify-center items-center flex-col bg-white pt-8 overflow-auto">
                <div class="flex justify-center m-4">
                    <h1>Meine Klient*innen</h1>
                </div>
                <div class="w-full overflow-auto">
                    <div class="max-w-[1000px] mx-auto flex flex-col items-center pb-8">
                        <ng-container *ngIf="!(subUsers$ | async)?.length">
                            <div>
                                <img ngSrc="assets/images/avatar-placeholder-2.png" width="150" height="150" alt="" />
                            </div>
                            <div class="bg-gray-100 shadow rounded-md p-6 flex flex-col items-center mt-6">
                                <div class="font-semibold"> Lege dein erstes Klient*innen Profil an</div>
                                <app-button class="mt-3" [link]="['profil-anlegen']" fragment="stammdaten"
                                    >Profil anlegen +
                                </app-button>
                            </div>
                        </ng-container>

                        <div class="flex flex-wrap justify-center w-full" *ngIf="(subUsers$ | async)?.length">
                            <div class="w-1/4 aspect-square p-4 flex">
                                <div class="w-full h-full rounded-lg bg-[#D2E0FE] items-center flex justify-center">
                                    <a
                                        type="button"
                                        [routerLink]="['profil-anlegen']"
                                        fragment="stammdaten"
                                        class="rounded-lg text-white bg-[#384250] py-2.5 px-5 my-2"
                                        >Profil anlegen +</a
                                    >
                                </div>
                            </div>
                            <ng-container *ngFor="let subUser of subUsers$ | async">
                                <div class="w-1/4 aspect-square p-4 flex">
                                    <app-subuser-tile [profile]="subUser"></app-subuser-tile>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./caretaker-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SharedModule,
        AsyncPipe,
        RouterLink,
        NgIf,
        NgForOf,
        DefaultLayoutWithNavigationComponent,
        NgOptimizedImage,
        SubuserTileComponent,
    ],
})
export class CaretakerOverviewComponent implements OnInit {
    subUsers$!: Observable<SubUser[]>;

    store = inject(Store);
    loading = toSignal(this.store.pipe(selectSubUserLoading()));

    ngOnInit() {
        this.subUsers$ = this.store.select(selectSubUsersAll);
        this.store.dispatch(SubUserActions.loadAllSubUsers());
    }
}
