import { HttpClient, HttpEventType } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IDBPDatabase, openDB } from 'idb';
import { BehaviorSubject, concatMap, firstValueFrom, last, retry, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryEntry } from '../+store/library-entry/library-entry.model';
import { environment } from '../../../environments/environment';
import { selectUser } from '../../user/+store/user.selectors';
import { AudioRecordDB, audioRecordStoreName } from './library-entry.service';

export interface AudioRecordBlob {
    mediaId: string;
    libraryEntryId: string;
    timestamp: string;
    groupId: string;
    userId: string;
    data: Blob;
    last?: boolean;
}

export interface StartLibraryEntryMediaRecordingResponse {
    libraryEntry: LibraryEntry;
    recordingGroupId: string;
}

@Injectable({
    providedIn: 'root',
})
export class LibraryRecordingService {
    http = inject(HttpClient);

    $uploadQueue?: Subject<AudioRecordBlob>;
    // this is for tracking loading state of specific entry
    $uploadingEntries = new BehaviorSubject<{ entryId: string; groupId: string }[]>([]);

    indexDB?: IDBPDatabase<AudioRecordDB>;
    readyToUploadAudio = false;

    store = inject(Store);
    actions = inject(Actions);
    user$ = this.store.pipe(select(selectUser));
    stopQueue$ = new Subject<boolean>();

    constructor() {
        // we initialize the local db and then listen for the user.
        // if user is valid and no caretaker we check for open uploads in local db and add them to the queue
        // then we are ready for new recordings
        // if user is caretaker or undefined/null we stop the queue with subject
        this.initIndexDB().then(() => {
            this.user$.subscribe(async user => {
                if (!user || user.caretaker || !user.id) {
                    this.readyToUploadAudio = false;
                    this.stopQueue$.next(true);
                    return;
                }

                const openUploads = await this.checkForOpenUploads(user.id);
                openUploads.forEach(openUpload => this.addToUploadQueue(openUpload));
                this.readyToUploadAudio = true;
            });
        });
    }

    async initIndexDB() {
        if (!('indexedDB' in window)) {
            // Can't use IndexedDB
            console.log("This browser doesn't support IndexedDB");
            return;
        }

        this.indexDB = await openDB('teresaDB', 1, {
            upgrade(db) {
                console.log('Creating a new object store...');

                // Checks if the object store exists:
                if (!db.objectStoreNames.contains(audioRecordStoreName)) {
                    // If the object store does not exist, create it:
                    db.createObjectStore(audioRecordStoreName, { keyPath: 'timestamp' });
                }
            },
        });
    }

    async checkForOpenUploads(userId: string) {
        if (!this.indexDB) {
            throw new Error('No index db');
        }

        const openUploads = await this.indexDB.getAll(audioRecordStoreName);

        if (openUploads.length === 0) {
            return [];
        }

        return openUploads.filter(fileToUpload => fileToUpload.userId === userId);
    }

    // presave in browser index db
    async addToDB(audioBlob: AudioRecordBlob) {
        if (!this.indexDB) {
            return;
        }

        return this.indexDB.add('audioRecords', audioBlob).then(() => this.addToUploadQueue(audioBlob));
    }

    // remove from browser index db - used after complete upload
    removeFromDB(audioBlob: AudioRecordBlob) {
        if (!this.indexDB) {
            return;
        }

        try {
            this.indexDB.delete('audioRecords', audioBlob.timestamp);
        } catch (e) {
            console.log(e);
        }
    }

    // add blob to upload queue and to loading
    addToUploadQueue(audioRecordBlob: AudioRecordBlob) {
        if (!this.$uploadQueue) {
            this.startUploadQueue();
        }

        this.addEntryAsLoading(audioRecordBlob);
        this.$uploadQueue!.next(audioRecordBlob);
    }

    startUploadQueue() {
        console.log('start new Uploadqueue');

        this.$uploadQueue = new Subject<AudioRecordBlob>();
        this.$uploadQueue
            .pipe(
                concatMap(audioRecordBlob => this.uploadAudioRecordBlob(audioRecordBlob).pipe(retry({ delay: 5000 }))),
                takeUntil(this.stopQueue$),
            )
            .subscribe({
                next: audioRecordBlob => {
                    console.log(audioRecordBlob.timestamp, audioRecordBlob.groupId);
                    if (audioRecordBlob.last) {
                        console.log('is last of group', audioRecordBlob.last);
                        this.removeEntryAsLoading(audioRecordBlob.groupId);
                    }
                    this.removeFromDB(audioRecordBlob);
                },
                complete: () => (this.$uploadQueue = undefined),
            });
    }

    // this will add loading object with entry and groupId as identifier
    addEntryAsLoading(audioRecordBlob: AudioRecordBlob) {
        firstValueFrom(this.$uploadingEntries).then(loadingEntries => {
            const isAlreadyLoading = loadingEntries.some(
                entry => entry.groupId === audioRecordBlob.groupId && entry.entryId === audioRecordBlob.libraryEntryId,
            );

            if (isAlreadyLoading) {
                this.$uploadingEntries.next(loadingEntries);
            } else {
                this.$uploadingEntries.next([
                    ...loadingEntries,
                    { groupId: audioRecordBlob.groupId, entryId: audioRecordBlob.libraryEntryId },
                ]);
            }
        });
    }

    // this will remove the loading of groupid
    removeEntryAsLoading(groupId: string) {
        firstValueFrom(this.$uploadingEntries).then(loadingEntries => {
            this.$uploadingEntries.next([...loadingEntries.filter(entry => entry.groupId !== groupId)]);
        });
    }

    // get boolean value based of if some media of libraryEntryId is currently in upload queue
    entryIsLoading(libraryEntryId: string | undefined) {
        return this.$uploadingEntries.asObservable().pipe(
            map(entries => {
                return entries.some(entry => entry.entryId === libraryEntryId);
            }),
        );
    }

    startRecordingMediaEntry(libraryEntryId: string, mediaId?: string) {
        return this.http.get<StartLibraryEntryMediaRecordingResponse>(
            `${environment.api}/library/entry/${libraryEntryId}/media/${mediaId}/recording-start`,
        );
    }

    uploadAudioRecordBlob(audioRecordBlob: AudioRecordBlob) {
        const formData = new FormData();

        // todo maybe in future more generic name like media?
        formData.append(`recording`, audioRecordBlob.data);
        formData.append('timestamp', audioRecordBlob.timestamp);
        formData.append('groupId', audioRecordBlob.groupId);
        formData.append('last', JSON.stringify(audioRecordBlob.last));

        return this.http
            .post<LibraryEntry>(
                `${environment.api}/library/entry/${audioRecordBlob.libraryEntryId}/media/${audioRecordBlob.mediaId}/recording-upload`,
                formData,
                {
                    headers: {},
                    reportProgress: true,
                    observe: 'events',
                },
            )
            .pipe(
                last(),
                map(httpResponse => {
                    if (httpResponse.type !== HttpEventType.Response) throw new Error('No correct HttpResponse');

                    return audioRecordBlob;
                }),
            );
    }
}
