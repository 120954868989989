import { Directive, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs';

import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ModalConfirmComponent } from '../../modal/modal-confirm/modal-confirm.component';
import { ModalConfirmDeleteComponent } from '../../modal/modal-confirm-delete/modal-confirm-delete.component';
import { DialogEvents } from '../../modal/dialog-modal-footer/dialog-modal-footer.component';

@Directive({
    selector: '[confirmedClick]',
    standalone: true,
})
export class ConfirmedClickDirective {
    @Output() confirmedClick = new EventEmitter<any>();

    @Input() dialogType: 'ConfirmedDelete' | 'ConfirmedCancel' = 'ConfirmedCancel';
    @Input() dialogTitle?: string;
    @Input() dialogBody?: string;
    @Input() dialogCancelBtn?: string;
    @Input() dialogConfirmBtn?: string;
    @Input() showConfirm = true;
    @Input() confirmCancel = false;

    @Input() backdrop = true;

    dialog = inject(Dialog);

    @HostListener('click')
    onClick() {
        if (!this.showConfirm) {
            this.confirmedClick.emit();
            return;
        }

        let dialogRef: DialogRef<unknown, ModalConfirmComponent | ModalConfirmDeleteComponent>;

        switch (this.dialogType) {
            case 'ConfirmedDelete':
                dialogRef = this.openConfirmDeleteDialog();
                break;
            case 'ConfirmedCancel':
                dialogRef = this.openConfirmDialog();
                break;
            default:
                dialogRef = this.openConfirmDialog();
                break;
        }
        const instance = dialogRef.componentInstance!;

        if (this.dialogTitle) {
            instance.dialogTitle = this.dialogTitle;
        }

        if (this.dialogBody) {
            instance.dialogBody = this.dialogBody;
        }

        if (this.dialogCancelBtn) {
            instance.dialogCancelBtn = this.dialogCancelBtn;
        }

        if (this.dialogConfirmBtn) {
            instance.dialogCancelBtn = this.dialogConfirmBtn;
        }

        if (instance instanceof ModalConfirmComponent && this.confirmCancel) {
            instance.confirmCancel = this.confirmCancel;
        }

        instance.dialogEventResponse.pipe(takeUntil(dialogRef.closed)).subscribe(res => {
            if (res.type === DialogEvents.confirm) {
                this.confirmedClick.emit(res);
            }
            dialogRef.close();
        });
    }

    openConfirmDialog() {
        return this.dialog.open(ModalConfirmComponent, { hasBackdrop: this.backdrop, disableClose: true });
    }

    openConfirmDeleteDialog() {
        return this.dialog.open(ModalConfirmDeleteComponent, {
            hasBackdrop: this.backdrop,
            disableClose: true,
            backdropClass: 'bg-white',
        });
    }
}
