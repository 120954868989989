import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InfoService } from '../../../shared/info.service';

@Component({
    selector: 'app-channel-five',
    template: `
        <app-default-layout-with-navigation>
            <div class="p-4 grow flex flex-col overflow-hidden">
                <div class="bg-white grow rounded-lg shadow-lg flex relative overflow-hidden">
                    <iframe
                        class="flex w-full"
                        src="https://teresa.whereby.com/teresa-five69f42e87-c72f-4137-aad6-bcce4f562d77"
                        allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"></iframe>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./channel-five.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelFiveComponent implements OnInit {
    constructor(private infoService: InfoService) {}

    ngOnInit(): void {
        this.infoService.setCurrentPage('channel-voice');
    }
}
