import { createAction, props } from '@ngrx/store';
import { DiaryEntry } from './diary.reducer';

export const createDiaryEntry = createAction(
    '[diary] create diary entry',
    props<{ data: any }>(),
);
export const createDiaryEntrySuccess = createAction(
    '[diary] create diary entry success',
    props<{ entry: DiaryEntry }>(),
);
export const createDiaryEntryFailure = createAction(
    '[diary] create diary entry failure',
    props<{ error: any }>(),
);
