import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { ClientProfileCoreControls } from '../../interfaces/client-profile-core.interface';

@Component({
    selector: 'app-client-profile-core-form',
    standalone: true,
    imports: [ReactiveFormsModule, SharedModule],
    template: `
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full" [formGroup]="formGroup">
            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Name</label>
                <div class="grid grid-cols-2 gap-3 w-full">
                    <input
                        formControlName="firstName"
                        class="form-control"
                        placeholder="Vorname (Pflichtfeld)"
                        type="text" />
                    <input formControlName="lastName" class="form-control" placeholder="Nachname" type="text" />
                </div>
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Geburtstag</label>
                <app-datepicker-input formControlName="birthDate"></app-datepicker-input>
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3">Geburtsort</label>
                <input formControlName="birthPlace" class="form-control" placeholder="Geburtsort" type="text" />
            </div>

            <div class="flex flex-col items-center bg-gray-100 border-1 shadow rounded-md border-[#edecec] p-6">
                <label class="font-bold mb-3 ">Wohnort</label>
                <input formControlName="city" class="form-control" placeholder="Wohnort" type="text" />
            </div>
        </div>
    `,
    styleUrl: './client-profile-core-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class ClientProfileCoreFormComponent {
    @Input() formGroup!: FormGroup;
}
