import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { signup } from '../../+store/auth.actions';
import { selectAuthState } from '../../+store/auth.selectors';
import { AppState } from '../../../app.reducer';

@Component({
    selector: 'app-signup',
    template: `
        <div class="flex items-center bg-beige h-full w-full font-serif">
            <div class="w-1/2 p-5 w-1/2 p-10 flex justify-end">
                <div>
                    <div class="relative overflow-hidden rounded-lg shadow-lg">
                        <img
                            class="object-cover w-full h-full border-2 border-l-4 border-b-4 border border-blue rounded-md"
                            [src]="image"
                            alt="Elderly Woman" />
                        <div class="absolute bottom-0 left-0 p-2 m-4 bg-blue rounded-xl text-center">
                            <h3 class="mb-3 text-white font-sans">
                                Wir haben alle eine individuelle Geschichte, darin sind wir uns gleich.
                            </h3>
                        </div>
                    </div>
                    <app-legal-links></app-legal-links>
                </div>
            </div>
            <div class="w-1/2 p-10 flex">
                <div class="flex flex-col">
                    <div
                        class="mt-8 sm:mx-auto sm:w-full sm:max-w-md border-2 border-l-4 border-b-4 border border-blue rounded-md">
                        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div>
                                <img class="mx-auto h-12 w-auto" src="/assets/logos/logo.png" alt="TeresaLogo" />
                            </div>
                            <h2 class="m-4 text-xl flex justify-center font-sans">
                                Wie lautet deine E-Mail Adresse?
                            </h2>
                            <form [formGroup]="signupForm" (ngSubmit)="submit()" class="space-y-6">
                                <div class="m-2">
                                    <input
                                        type="email"
                                        formControlName="email"
                                        required
                                        placeholder="E-Mail Adresse"
                                        class="form-control" />
                                </div>
                                <p class="mt-4 text-red-600" *ngIf="errorMsg">
                                    <ng-container *ngIf="errorMsg.status === 409; else errorFallback">
                                        Diese E-Mail Adresse wird schon verwendet.
                                    </ng-container>
                                    <ng-template #errorFallback>
                                        Ups, hier ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
                                    </ng-template>
                                </p>
                                <app-button type="submit" class="flex justify-center font-sans">
                                    Registrieren
                                </app-button>
                            </form>
                        </div>
                    </div>
                    <h3 class="flex justify-center mt-3 font-sans"> Du hast schon ein Konto? </h3>
                    <app-button
                        class="flex justify-center mt-1 font-sans"
                        [routerLink]="['/auth', 'login']"
                        color="white">
                        Hier geht es zur Anmeldung
                    </app-button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupComponent implements OnInit {
    signupForm!: UntypedFormGroup;
    image = `/assets/images/woman-${Math.floor(Math.random() * 3) + 1}.jpg`;
    errorMsg?: { status: number };

    constructor(
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef,
    ) {
        this.store.pipe(select(selectAuthState)).subscribe(state => {
            this.errorMsg = state.error;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.signupForm = new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
        });
    }

    submit() {
        if (this.signupForm.valid) {
            this.store.dispatch(signup({ email: this.signupForm.value.email }));
        }
    }
}
