import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AuthActions } from '../../../auth/+store/auth.actions';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { selectCurrentRoute } from '../../../shared/utility/router.selectors';
import { selectUserLoading } from '../../../user/+store/user.selectors';
export interface MySpaceNavLink {
    text: string;
    route: string | string[];
    title: string;
    fragment?: string;
}

@Component({
    selector: 'app-client-my-space',
    standalone: true,
    imports: [SharedModule, RouterOutlet, RouterLink, RouterLinkActive, NgForOf, DefaultLayoutWithNavigationComponent],
    template: `
        <app-default-layout-with-navigation [loading]="loading()">
            <div class="flex w-full gap-x-4 max-w-[1000px] mx-auto mt-4 pb-4 px-4 flex-grow max-h-full items-start">
                <nav class="w-[150px] pr-4 flex flex-col mt-20">
                    <ul class="text-sm flex flex-col nav-list mb-4 flex-grow">
                        <li *ngFor="let link of navLinks">
                            <a
                                [routerLink]="link.route"
                                [fragment]="link.fragment"
                                routerLinkActive="!opacity-100 font-bold"
                                class="text-primary opacity-50"
                                >{{ link.text }}</a
                            >
                        </li>
                    </ul>
                    <div class="border-t pt-4 mt-auto">
                        <button (click)="logout()" class="text-red font-bold flex items-center gap-2"
                            >Ausloggen<fa-icon [icon]="['fas', 'arrow-right-from-bracket']"></fa-icon
                        ></button>
                    </div>
                </nav>
                <section class="w-full">
                    <div class="flex justify-center items-center font-serif text-lg text-primary h-20 font-bold">
                        {{ childPageTitel() }}
                    </div>
                    <div class="overflow-y-auto overflow-x-hidden">
                        <router-outlet></router-outlet>
                    </div>
                </section>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrl: './client-my-space.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientMySpaceComponent implements OnInit {
    store = inject(Store);
    route = inject(ActivatedRoute);
    navLinks: MySpaceNavLink[] = [
        {
            text: 'Übersicht',
            route: '/dashboard',
            title: 'Profil bearbeiten',
        },
        {
            text: 'Profil',
            route: 'profil',
            title: 'Profil bearbeiten',
        },
        // {
        //     text: 'Alltagshelfer',
        //     route: 'alltagshelfer',
        //     title: 'Alltagshelfer verwalten',
        // },
        // {
        //     text: 'Account',
        //     route: 'account',
        //     title: 'Account bearbeiten',
        // },
    ];
    childPageTitel = computed(() => {
        return this.navLinks.find(link => link.route === this.childPath())?.title;
    });
    childPath = toSignal(
        this.store.pipe(
            select(selectCurrentRoute),
            map(route => route.routeConfig.path),
        ),
    );

    loading = toSignal(this.store.pipe(selectUserLoading()));

    ngOnInit() {}

    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
