import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryEntryMedia } from '../../+store/library-entry/library-entry.model';
import { ConfirmedClickDirective } from '../../../shared/directives/confirmed-click/confirmed-click.directive';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-library-entry-highlight',
    template: `
        <div
            (click)="!deleteMode ? selected.emit() : null"
            class="inline-flex flex-col relative z-0"
            [class.cursor-pointer]="!deleteMode">
            <img
                [src]="media.location"
                alt="Media Image"
                class="w-[200px] aspect-square rounded-xl object-cover"
                [class.grayscale]="deleteMode" />
            <div class="mx-auto -mt-8 z-10">
                <span
                    class="w-12 aspect-square rounded-full bg-white inline-flex items-center justify-center shadow-xl">
                    <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
                </span>
            </div>

            <button
                *ngIf="deleteMode"
                class="absolute top-4 right-4 p-2 bg-red text-white rounded w-10 aspect-square shadow-lg"
                (confirmedClick)="delete.emit(media)"
                dialogType="ConfirmedDelete"
                dialogBody="Möchtest du dieses Bild wirklich löschen?">
                <fa-icon [icon]="['fas', 'trash-xmark']"></fa-icon>
            </button>
        </div>
    `,
    styleUrls: ['./library-entry-highlight.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ConfirmedClickDirective, NgIf, FaIconComponent, SharedModule],
})
export class LibraryEntryHighlightComponent implements OnInit {
    @Input() media!: LibraryEntryMedia;
    @Input() deleteMode = false;
    @Output() selected = new EventEmitter<boolean>();
    @Output() delete = new EventEmitter<LibraryEntryMedia>();

    ngOnInit() {}
}
