import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SubUserActions } from '../../+store/sub-user.actions';
import { SubUser } from '../../+store/sub-user.model';
import { selectSubUserById } from '../../+store/sub-user.selectors';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';
import { CaretakerRouteNames } from '../../caretaker-routing';
import { CaretakerService } from '../../caretaker.service';
import { SubuserTileComponent } from '../../components/subuser-tile/subuser-tile.component';

@Component({
    selector: 'app-caretaker-profile-created',
    template: `
        <app-default-layout-with-navigation [showNavigation]="false">
            <div class="flex flex-col items-center mt-12">
                <ng-container *ngIf="subUser$ | async as subUser">
                    <div class="w-[250px] aspect-square max-w-full">
                        <app-subuser-tile [profile]="subUser"></app-subuser-tile>
                    </div>

                    <div class="mt-10">
                        <div class="alert alert-success">
                            Das Profil von {{ subUser.firstName }} wurde erfolgreich angelegt.
                        </div>

                        <div class="bg-gray-100 py-5 px-10 rounded-md mt-6 shadow w-full">
                            <h3 class="font-bold text-center"> Was willst du als Nächstes tun? </h3>

                            <div class="mt-6 text-center">
                                <app-button (click)="impersonate(subUser.id)"
                                    >Zum Bereich von {{ subUser.firstName }}
                                </app-button>
                            </div>
                        </div>
                    </div>

                    <app-button color="white" class="mt-10" [link]="['/', careTakerRoutes.prefix]"
                        >Zur Klient*innen Übersicht</app-button
                    >
                </ng-container>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./caretaker-subuser-created.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule, DefaultLayoutWithNavigationComponent, AsyncPipe, SubuserTileComponent, NgIf],
})
export class CaretakerSubuserCreatedComponent implements OnInit {
    subUser$!: Observable<SubUser>;
    careTakerRoutes = CaretakerRouteNames;

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private caretakerService: CaretakerService,
    ) {}

    ngOnInit() {
        this.store.dispatch(SubUserActions.loadAllSubUsers());

        this.subUser$ = this.route.paramMap.pipe(
            map(params => params.get('id')),
            filterNullish(),
            switchMap(id => this.store.select(selectSubUserById(id))),
            filterNullish(),
        );
    }

    impersonate(id: string) {
        this.caretakerService.impersonate(id);
    }
}
