import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent, FaStackComponent, FaStackItemSizeDirective } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-dashboard-card',
    standalone: true,
    template: `
        <div class="flex drop-shadow-xl">
            <a class="card text-white" [routerLink]="link" [ngClass]="cardClass">
                <fa-stack size="2x" class="icon">
                    <fa-icon [icon]="faCircle" stackItemSize="2x" [ngClass]="circleClass"></fa-icon>
                    <fa-icon [icon]="icon" stackItemSize="1x" transform="shrink-4"></fa-icon>
                </fa-stack>

                <div class="z-1 relative text-primary">
                    <h2 class="text-center font-sans">{{ title }}</h2>
                    <h3 class="text-center text-sm mt-2 font-sans">
                        {{ description }}
                    </h3>
                </div>
            </a>
        </div>
    `,
    styleUrls: ['./dashboard-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, FaStackComponent, NgClass, FaStackItemSizeDirective, FaIconComponent],
})
export class DashboardCardComponent implements OnInit {
    faCircle = faCircle;
    @Input() icon!: IconProp;
    @Input() title!: string;
    @Input() description?: string;
    @Input() cardClass: string = '';
    @Input() circleClass: string = 'text-white';
    @Input() link?: any[] | string | null | undefined;

    constructor() {}

    ngOnInit(): void {}
}
