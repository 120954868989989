import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as LibraryActions from './library-entry.actions';
import { LibraryEntryActions } from './library-entry.actions';
import { LibraryEntry } from './library-entry.model';

export const librariesFeatureKey = 'libraryEntries';

export interface LibraryEntryState extends EntityState<LibraryEntry> {
    loading: boolean;
}

export const adapter: EntityAdapter<LibraryEntry> = createEntityAdapter<LibraryEntry>();

export const initialState: LibraryEntryState = adapter.getInitialState({
    loading: false,
});

export const libraryEntryReducer = createReducer(
    initialState,
    on(
        // library entry
        LibraryEntryActions.createLibraryEntry,
        LibraryEntryActions.loadLibraryEntries,
        LibraryEntryActions.updateLibraryEntry,
        LibraryEntryActions.deleteLibraryEntry,
        LibraryEntryActions.loadLibraryEntryByID,
        // media entry
        LibraryEntryActions.addLibraryEntryMedia,
        LibraryEntryActions.deleteLibraryEntryMedia,
        state => ({
            ...state,
            loading: true,
        }),
    ),

    on(
        // library entry
        LibraryEntryActions.createLibraryEntrySuccess,
        LibraryEntryActions.updateLibraryEntrySuccess,
        LibraryEntryActions.loadLibraryEntryByIDSuccess,
        // media entry
        LibraryEntryActions.addLibraryEntryMediaSuccess,
        LibraryEntryActions.deleteLibraryEntryMediaSuccess,

        (state, action) =>
            adapter.upsertOne(action.libraryEntry, {
                ...state,
                loading: false,
            }),
    ),

    on(
        LibraryEntryActions.startLibraryEntryMediaRecordingSuccess,

        (state, action) =>
            adapter.upsertOne(action.res.libraryEntry, {
                ...state,
                loading: false,
            }),
    ),

    on(
        // library entry
        LibraryEntryActions.loadLibraryEntriesSuccess,
        (state, action) =>
            adapter.upsertMany(action.libraryEntries, {
                ...state,
                loading: false,
            }),
    ),

    on(
        // library entry
        LibraryEntryActions.deleteLibraryEntrySuccess,
        (state, action) =>
            adapter.removeOne(action.libraryEntryId, {
                ...state,
                loading: false,
            }),
    ),

    on(
        // library entry
        LibraryEntryActions.createLibraryEntryFailure,
        LibraryEntryActions.loadLibraryEntriesFailure,
        LibraryEntryActions.updateLibraryEntryFailure,
        LibraryEntryActions.deleteLibraryEntryFailure,
        LibraryEntryActions.loadLibraryEntryByIDFailure,
        // media entry
        LibraryEntryActions.addLibraryEntryMediaFailure,
        LibraryEntryActions.addLibraryEntryMediaFailure,
        state => ({
            ...state,
            loading: false,
        }),
    ),

    on(LibraryActions.addLibraryEntry, (state, action) => adapter.addOne(action.library, state)),
    on(LibraryActions.addLibraryEntries, (state, action) => adapter.addMany(action.libraryEntries, state)),
    on(LibraryActions.upsertLibraryEntries, (state, action) => adapter.upsertMany(action.libraryEntries, state)),
    on(LibraryActions.updateLibraryEntries, (state, action) => adapter.updateMany(action.LibraryEntries, state)),
    on(LibraryActions.deleteLibraryEntries, (state, action) => adapter.removeMany(action.ids, state)),
    on(LibraryActions.clearLibraryEntries, state => adapter.removeAll(state)),
);
