import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    HostBinding,
    Input,
    signal,
    ViewChild,
} from '@angular/core';
import { BackgroundGradientComponent } from '../background-gradient/background-gradient.component';

export enum AppImageAlignment {
    cover = 'object-cover',
    contain = 'object-contain',
}

@Component({
    selector: 'app-image',
    standalone: true,
    imports: [NgStyle, BackgroundGradientComponent, NgIf, NgClass],
    template: `
        <div
            class="relative z-0 w-full bg-primary"
            [ngStyle]="{ 'aspect-ratio': aspectRatio(), 'max-width': maxWidthValue() }">
            <app-background-gradient [classes]="'absolute inset-0 z-0'"></app-background-gradient>
            <img
                *ngIf="src"
                [src]="src"
                [alt]="alt"
                #imgEl
                class="absolute inset-0 w-full h-full opacity-0"
                (load)="loaded.set(true)"
                [class.opacity-100]="loaded()"
                [ngClass]="imageAlignment()" />
        </div>
    `,
    styleUrl: './image.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class ImageComponent {
    @ViewChild('imgEl') imgEl = ElementRef;

    @Input() src?: string;
    @Input() width?: string;
    @Input() height?: string;
    @Input() alt?: string;
    @Input() alignment?: AppImageAlignment;
    @Input() maxWidth?: string;
    @Input() maxWidthOriginal: 'true' | 'false' = 'false';

    loaded = signal(false);

    aspectRatio = computed(() => {
        if (this.width && this.height) {
            return `${this.width}/${this.height}`;
        }

        return null;
    });

    imageAlignment = computed(() => {
        return this.alignment ?? 'object-cover';
    });

    maxWidthValue = computed(() => {
        if (this.maxWidth) {
            return this.maxWidth + 'px';
        } else if (this.maxWidthOriginal === 'true') {
            return this.width + 'px';
        }
        return null;
    });
}
