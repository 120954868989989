import { createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';
import { User } from './user.model';

export const userFeatureKey = 'user';

export interface UserState {
    user?: User;
    savingInProgress: boolean;
    loading: boolean;
}

export const initialState: UserState = {
    loading: false,
    savingInProgress: false,
};

export const userReducer = createReducer(
    initialState,
    on(
        UserActions.loadMe,
        UserActions.updateMe,
        UserActions.updateAvatar,
        UserActions.deleteAvatar,
        UserActions.updateNotes,
        UserActions.updateShortProfile,
        state => ({
            ...state,
            loading: true,
            savingInProgress: true,
        }),
    ),

    on(
        UserActions.loadMeSuccess,
        UserActions.updateMeSuccess,
        UserActions.updateAvatarSuccess,
        UserActions.deleteAvatarSuccess,
        UserActions.updateNotesSuccess,
        UserActions.updateShortProfileSuccess,

        (state, action) => ({
            ...state,
            loading: false,
            savingInProgress: false,
            user: action.user,
        }),
    ),

    on(
        UserActions.loadMeFailure,
        UserActions.updateMeFailure,
        UserActions.updateAvatarFailure,
        UserActions.deleteAvatarFailure,
        UserActions.updateNotesFailure,
        UserActions.updateShortProfileFailure,
        state => ({
            ...state,
            savingInProgress: false,
            loading: false,
        }),
    ),
);
