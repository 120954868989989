import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { subUserFeatureKey, subUserFeature, SubUserState } from './sub-user.reducer';

export const selectSubUserState = createFeatureSelector<SubUserState>(subUserFeatureKey);

export const {
    selectIds: selectSubUserIds,
    selectEntities: selectSubUserEntities,
    selectAll: selectSubUsersAll,
    selectSavingInProgress: selectSubUserSavingInProgress,
} = subUserFeature;

export const selectSubUserById = (id: string) => createSelector(selectSubUserEntities, entities => entities[id]);

export const selectSubUserLoading = () => select(createSelector(selectSubUserState, state => state.loading));
