import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BriefLayoutWithNavigation } from './components/brief-layout-with-navigation/brief-layout-with-navigation';
import { BriefNavComponent } from './components/brief-nav/brief-nav.component';
import { BriefProfileDetailNotes } from './components/brief-profile-detail.components/brief-profile-detail-notes/brief-profile-detail-notes';
import { HandoverComponent } from './components/handover/handover.component';
import { BirthPlaceComponent } from './components/input-fields/birth-place/birth-place.component';
import { BirthdayComponent } from './components/input-fields/birthday/birthday.component';
import { NameComponent } from './components/input-fields/name/name.component';
import { ResidenceComponent } from './components/input-fields/residence/residence.component';

@NgModule({
    declarations: [
        HandoverComponent,
        NameComponent,
        BirthdayComponent,
        BirthPlaceComponent,
        ResidenceComponent,
        BriefLayoutWithNavigation,
        BriefNavComponent,
        BriefProfileDetailNotes,
    ],
    imports: [CommonModule, RouterLink, FormsModule, RouterOutlet, RouterLinkActive, SharedModule],
})
export class BriefProfileModule {}
