import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAccessToken = createSelector(selectAuthState, (state: AuthState) => state.accessToken);

export const selectAuthError = createSelector(selectAuthState, state => state.error);

export const selectAuthLoading = createSelector(selectAuthState, state => state.isLoading);
