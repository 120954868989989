import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { UserActions } from '../../../user/+store/user.actions';
import { selectUserNotes } from '../../../user/+store/user.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';

import { map } from 'rxjs/operators';

import { NgForOf } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { InputEditorModalComponent } from '../../../shared/custom-inputs/input-editor-modal/input-editor-modal.component';
import { ControlsOf } from '../../../shared/types/controls-of.type';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';

@UntilDestroy()
@Component({
    selector: 'app-client-notes',
    standalone: true,
    imports: [SharedModule, InputEditorModalComponent, ReactiveFormsModule, NgForOf],
    template: `
        <form [formGroup]="form">
            <app-input-editor-modal
                modalLabel="Notizen"
                formControlName="notes"
                [beforeValueChange]="inputChange()"
                [showSaveAndCancel]="true"
                placeholder="Tippe zum Eintragen von Notizen">
            </app-input-editor-modal>
        </form>
    `,
    styleUrl: './client-notes.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientNotesComponent implements OnInit {
    store = inject(Store<AppState>);
    actions = inject(Actions);
    form!: FormGroup<ControlsOf<{ notes: string }>>;
    fb = inject(NonNullableFormBuilder);

    ngOnInit() {
        this.form = this.fb.group({
            notes: [''],
        });

        this.store
            .pipe(select(selectUserNotes), filterNullish(), untilDestroyed(this))
            .subscribe(notes => this.form.patchValue({ notes }, { emitEvent: false }));
    }

    inputChange() {
        return (notes: string) => {
            this.store.dispatch(UserActions.updateNotes({ notes }));

            return firstValueFrom(
                this.actions.pipe(
                    ofType(UserActions.updateNotesSuccess, UserActions.updateNotesFailure),
                    map(action => action.type === UserActions.updateNotesSuccess.type),
                    untilDestroyed(this),
                ),
            );
        };
    }
}
