import { Update } from '@ngrx/entity';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { StartLibraryEntryMediaRecordingResponse } from '../../services/library-recording.service';

import { LibraryEntry } from './library-entry.model';

export type AddLibraryMediaEntryDto = {
    imageRecordingTimestamp?: number;
    connectedRecordingId?: string;
    files: File[];
};

export const LibraryEntryActions = createActionGroup({
    source: 'LibraryEntry/API',
    events: {
        'Load Library Entries': emptyProps(),
        'Load Library Entries Success': props<{ libraryEntries: LibraryEntry[] }>(),
        'Load Library Entries Failure': props<{ error: any }>(),

        'Load Library Entry by ID': props<{ id: string }>(),
        'Load Library Entry by ID Success': props<{ libraryEntry: LibraryEntry }>(),
        'Load Library Entry by ID Failure': props<{ error: any }>(),

        'Create Library Entry': props<{ libraryEntry: Partial<LibraryEntry> }>(),
        'Create Library Entry Success': props<{ libraryEntry: LibraryEntry }>(),
        'Create Library Entry Failure': props<{ error: any }>(),

        'Update Library Entry': props<{ libraryEntry: Update<LibraryEntry> }>(),
        'Update Library Entry Success': props<{ libraryEntry: LibraryEntry }>(),
        'Update Library Entry Failure': props<{ error: any }>(),

        'Delete Library Entry': props<{ libraryEntryId: string }>(),
        'Delete Library Entry Success': props<{ libraryEntryId: string }>(),
        'Delete Library Entry Failure': props<{ error: any }>(),

        'Add Library Entry Media': props<{ libraryEntryId: string; mediaFiles: AddLibraryMediaEntryDto }>(),
        'Add Library Entry Media Success': props<{ libraryEntry: LibraryEntry }>(),
        'Add Library Entry Media Failure': props<{ error: any }>(),

        'Delete Library Entry Media': props<{ libraryEntryId: string; mediaId: string }>(),
        'Delete Library Entry Media Success': props<{ libraryEntry: LibraryEntry }>(),
        'Delete Library Entry Media Failure': props<{ error: any }>(),

        'Start Library Entry Media Recording': props<{ libraryEntryId: string; mediaId: string }>(),
        'Start Library Entry Media Recording Success': props<{ res: StartLibraryEntryMediaRecordingResponse }>(),
        'Start Library Entry Media Recording Failure': props<{ error: any }>(),
    },
});

// export const loadLibraryEntryById = createAction('[LibraryEntry/API] Load LibraryEntry By Id', props<{ id: string }>());

export const addLibraryEntry = createAction('[LibraryEntry/API] Add Library', props<{ library: LibraryEntry }>());

export const upsertLibraryEntry = createAction(
    '[LibraryEntry/API] Upsert Library',
    props<{ libraryEntry: LibraryEntry }>(),
);

export const addLibraryEntries = createAction(
    '[LibraryEntry/API] Add LibraryEntries',
    props<{ libraryEntries: LibraryEntry[] }>(),
);

export const upsertLibraryEntries = createAction(
    '[LibraryEntry/API] Upsert LibraryEntries',
    props<{ libraryEntries: LibraryEntry[] }>(),
);

export const updateLibraryEntry = createAction(
    '[LibraryEntry/API] Update Library',
    props<{ libraryEntry: Update<LibraryEntry> }>(),
);

export const updateLibraryEntries = createAction(
    '[LibraryEntry/API] Update LibraryEntries',
    props<{ LibraryEntries: Update<LibraryEntry>[] }>(),
);

export const deleteLibraryEntries = createAction(
    '[LibraryEntry/API] Delete LibraryEntries',
    props<{ ids: string[] }>(),
);

export const clearLibraryEntries = createAction('[LibraryEntry/API] Clear LibraryEntries');
