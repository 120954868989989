<div class="min-h-full flex flex-col">
    <app-header></app-header>

    <main class="-mt-24 pb-8">
        <div class="bg-white mx-6 rounded-lg overflow-auto">
            <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Datum
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Region
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Intensität
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Anmerkungen
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                    <tr *ngIf="(diaryEntries$ | async)?.length === 0">
                        <td class="text-center py-5" colspan="4">
                            Noch keine Einträge vorhanden.
                        </td>
                    </tr>
                    <tr *ngFor="let diaryEntry of diaryEntries$ | async">
                        <td
                            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {{ diaryEntry.createdAt | date: 'dd.MM.yy HH:mm' }}
                            Uhr
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ diaryEntry.region }}
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ diaryEntry.intensity }}
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ diaryEntry.notes }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>

    <!--    <div class="bg-white overflow-hidden shadow rounded-lg">-->
    <!--        <div class="px-4 py-5 sm:p-6">-->
    <!--<h1 *ngFor="let diaryEntry of diaryEntries$ | async">{{ diaryEntry.brief-profile-detail-notes }}</h1>-->
    <!--        </div>-->
    <!--    </div>-->

    <footer class="mt-auto">
        <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div
                class="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
                <span class="block sm:inline"
                    >&copy; 2022 Teresa.AI UG (haftungsbeschränkt)</span
                >
            </div>
        </div>
    </footer>
</div>
