import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Host, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-background-gradient',
    template: `
        <svg class="w-full background-gradient h-full" [ngClass]="classes">
            <defs>
                <filter
                    id="nnnoise-filter"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    color-interpolation-filters="linearRGB">
                    <feTurbulence
                        type="fractalNoise"
                        baseFrequency="0.6, 0.6"
                        numOctaves="1"
                        seed="15"
                        stitchTiles="stitch"
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        result="turbulence"></feTurbulence>
                    <feColorMatrix
                        type="saturate"
                        values="0"
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        in="specularLighting"
                        result="colormatrix"></feColorMatrix>
                </filter>
            </defs>
            <rect width="100%" height="100%" fill="white" filter="url(#nnnoise-filter)" opacity="0.4"></rect>
        </svg>
    `,

    styleUrls: ['./background-gradient.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
    host: {
        class: 'contents',
    },
})
export class BackgroundGradientComponent {
    @Input() classes?: string;
}
