import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-filter-button',
    standalone: true,
    imports: [NgIf, NgClass],
    template: `
        <button
            type="button"
            class="flex items-center whitespace-nowrap text-sm text-primary transition-opacity font-bold tabular-nums"
            [ngClass]="{ 'opacity-50': !active }">
            <span
                *ngIf="count !== null && count !== undefined"
                class="border-black border bg-gray-200 h-6 mr-2 px-1.5 rounded-md"
                >{{ count }}</span
            >
            <span><ng-content></ng-content></span>
        </button>
    `,
    styleUrl: './filter-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonComponent {
    @Input() count?: number;
    @Input() active = true;
}
