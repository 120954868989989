import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnInit,
    Optional,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { FORM_ERRORS, FormErrors } from './form-errors.token';

@UntilDestroy()
@Component({
    selector: 'app-validation-error',
    template: `
        <!--    <div class="text-success" *ngIf="ctrl.dirty && ctrl.valid && !ctrl.pending">-->
        <!--      <fa-icon [icon]="faCheckCircle"></fa-icon>-->
        <!--    </div>-->

        <ng-container *ngIf="displayErrorText">
            <div class="flex text-red-700 font-bold mt-2">
                <fa-icon [icon]="faTimesCircle"></fa-icon>
                <span class="text-sm ml-2">{{ displayErrorText }}</span>
            </div>

            <!--      <div *ngIf="ctrl.pending">-->
            <!--        <app-loading-spinner size="1x"></app-loading-spinner>-->
            <!--      </div>-->
        </ng-container>

        <!--    invalid: {{ ctrl.invalid }}-->
        <!--    dirty: {{ ctrl.dirty }}-->
        <!--    pristine: {{ ctrl.pristine }}-->
        <!--    touched: {{ ctrl.touched }}-->
        <!--    valid: {{ ctrl.valid }}-->
        <!--        errors: {{ ctrl.errors | json }}-->
        <!--    status: {{ ctrl.status }}-->
    `,
    styleUrls: ['./validation-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorComponent implements OnInit {
    faTimesCircle = faTimesCircle;

    @Input() controlName!: string;
    @Input() errorName?: string;
    @Input() errorText?: string;

    displayErrorText?: string;
    formGroup!: FormGroup;

    constructor(
        @Optional() public formGroupDirective: FormGroupDirective,
        private cdr: ChangeDetectorRef,
        @Inject(FORM_ERRORS) private errors: FormErrors,
    ) {}

    ngOnInit(): void {
        if (!this.formGroupDirective) {
            console.error(
                'No FormGroup found! Do you use the InputValidationComponent correctly?',
            );
        }

        this.formGroup = this.formGroupDirective.form;

        merge(
            this.formGroupDirective.statusChanges!,
            this.formGroupDirective.valueChanges!,
            this.formGroupDirective.ngSubmit,
        )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setErrorText();
            });
    }

    setErrorText(): void {
        const formControl = this.formGroup.get(this.controlName);
        if (
            formControl &&
            formControl.errors &&
            (formControl.dirty || this.formGroupDirective.submitted)
        ) {
            const controlErrors = formControl.errors;

            if (controlErrors) {
                this.displayErrors(controlErrors);
            } else {
                this.displayErrorText = undefined;
            }
        }

        if (this.errorName && this.formGroupDirective.submitted) {
            const formErrors = this.getFormError();

            if (formErrors) {
                this.displayErrors(formErrors);
            } else {
                this.displayErrorText = undefined;
            }
        }

        this.cdr.markForCheck();
    }

    displayErrors(errors: { [key: string]: any }) {
        const firstKey = Object.keys(errors)[0] as keyof FormErrors;
        const getError: (...a: any) => string = this.errors[firstKey];

        if (this.errorText) {
            this.displayErrorText = this.errorText;
        } else if (getError) {
            this.displayErrorText = getError(errors[firstKey]);
        }
    }

    getFormError(): any {
        if (this.formGroup.errors && this.errorName) {
            return this.formGroup.errors;
        }
        return null;
    }
}
