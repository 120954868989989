import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { DialogEventResponse } from '../interfaces/is-dialog-modal.interface';
import { DialogModalWrapperComponent } from '../dialog-modal-wrapper/dialog-modal-wrapper.component';
import { DialogEvents, DialogModalFooterComponent } from '../dialog-modal-footer/dialog-modal-footer.component';

@Component({
    selector: 'app-modal-confirm-delete',
    standalone: true,
    imports: [DialogModalWrapperComponent, DialogModalFooterComponent],
    template: `
        <app-dialog-modal-wrapper maxWidth="400px">
            <div class="text-primary text-2xl px-4 py-6 text-center font-bold">
                {{ dialogBody }}
            </div>
            <app-dialog-modal-footer
                [confirmButtonVariant]="'red'"
                (dialogEvent)="onDialogEvent($event)"
                confirmButtonText="Löschen"></app-dialog-modal-footer>
        </app-dialog-modal-wrapper>
    `,
    styleUrl: './modal-confirm-delete.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmDeleteComponent {
    @Input() dialogTitle?: string;
    @Input() dialogBody: string = 'Möchtest du wirklich löschen?';
    @Input() dialogCancelBtn: string = 'Abbrechen';
    @Input() dialogConfirmBtn: string = 'Löschen';
    dialogEventResponse = new EventEmitter<DialogEventResponse<undefined>>();

    onDialogEvent(type: DialogEvents) {
        this.dialogEventResponse.emit({ type });
    }
}
