import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as DiaryActions from './diary.actions';

export interface DiaryEntry {
    id: string;
    region: string;
    notes: string;
    intensity: string;
    createdAt: string;
}

export const diaryFeatureKey = 'diary';

export const adapter: EntityAdapter<DiaryEntry> =
    createEntityAdapter<DiaryEntry>({
        selectId: (diaryEntry: DiaryEntry) => diaryEntry.id,
        sortComparer: (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    });

export interface DiaryState extends EntityState<DiaryEntry> {}

export const initialState: DiaryState = adapter.getInitialState({});

export const diaryReducer = createReducer(
    initialState,
    on(DiaryActions.createDiaryEntrySuccess, (state, { entry }) =>
        adapter.addOne(entry, state),
    ),
);

export const selectDiaryState =
    createFeatureSelector<DiaryState>(diaryFeatureKey);
const { selectAll } = adapter.getSelectors(selectDiaryState);

export const selectAllDiaryEntries = selectAll;
