import { ChangeDetectionStrategy, Component, inject, OnInit, signal, TrackByFunction } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, pluck, shareReplay, take, takeUntil } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { LibraryEntryActions } from '../../+store/library-entry/library-entry.actions';
import { LibraryEntry, LibraryEntryMedia } from '../../+store/library-entry/library-entry.model';
import { selectLibraryEntryByIdNoNull } from '../../+store/library-entry/library-entry.selectors';
import { LibraryImpulseQuestion } from '../../+store/library-impulse-question/library-impulse-question.model';
import { selectImpulseQuestionById } from '../../+store/library-impulse-question/library-impulse-question.reducer';
import { AppState } from '../../../app.reducer';
import { AudioPlayerCoreComponent } from '../../../shared/components/audio-player-core/audio-player-core.component';
import { ImageModalComponent } from '../../../shared/components/image-modal/image-modal.component';
import { InfoService } from '../../../shared/info.service';
import { User } from '../../../user/+store/user.model';
import { selectNonNullUser } from '../../../user/+store/user.selectors';
import { LibraryInfoEditModal } from '../../components/library-info-edit-modal/library-info-edit-modal.component';
import { Dialog } from '@angular/cdk/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { BackgroundGradientComponent } from '../../../shared/components/background-gradient/background-gradient.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../shared/shared.module';
import { ConfirmedClickDirective } from '../../../shared/directives/confirmed-click/confirmed-click.directive';
import { InputEditorModalComponent } from '../../../shared/custom-inputs/input-editor-modal/input-editor-modal.component';
import { LibraryEntryHighlightComponent } from '../../components/library-entry-highlight/library-entry-highlight.component';
import { LibraryEntryAudioTileComponent } from '../../components/library-entry-audio-tile/library-entry-audio-tile.component';
import { DialogEvents } from '../../../shared/modal/dialog-modal-footer/dialog-modal-footer.component';

@UntilDestroy()
@Component({
    selector: 'app-library-entry-detail',
    template: `
        <app-default-layout-with-navigation>
            <ng-container *ngIf="user$ | async; let user">
                <div class="relative grid grid-cols-1">
                    <div
                        class="w-full h-[392px] col-span-full row-start-1 relative -z-10"
                        [class.grayscale]="deleteMode()">
                        <div class="inset-0 absolute z-0">
                            <app-background-gradient></app-background-gradient>
                        </div>

                        <!-- header image -->
                        <ng-container *ngIf="headerImage$ | async; let media">
                            <div class="inset-0 bg-black opacity-30 z-10 absolute"></div>

                            <img
                                alt="Hero Bild"
                                class="w-auto h-full max-w-full max-h-full mx-auto z-20 relative object-contain"
                                [src]="media.location" />
                        </ng-container>
                    </div>

                    <div class="row-start-1 col-span-full px-4 text-primary pt-72">
                        <div class="rounded-2xl shadow-lg max-w-4xl mx-auto overflow-hidden">
                            <ng-container *ngIf="!deleteMode(); else deleteInfo">
                                <ng-container *ngIf="libraryEntry$ | async; let entry">
                                    <div class="bg-white py-4 px-5 min-h-[175px]">
                                        <div class="flex items-center">
                                            <ng-container *ngIf="entry.year">
                                                <span class="font-bold text-2xl">{{ entry.year }}</span>
                                                <span
                                                    class="inline-block bg-primary rounded-full mx-2.5 w-2.5 h-2.5 flex-shrink-0"></span>
                                            </ng-container>

                                            <ng-container *ngIf="entry.location">
                                                <span class="font-bold text-2xl">{{ entry.location }}</span>
                                                <span
                                                    class="inline-block bg-primary rounded-full mx-2.5 w-2.5 h-2.5 flex-shrink-0"></span>
                                            </ng-container>

                                            <h3 class="font-bold text-2xl">{{ entry.title }}</h3>

                                            <button
                                                (click)="openEditLibraryInfoModal()"
                                                type="button"
                                                class="ml-1 w-8 aspect-square">
                                                <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
                                            </button>
                                        </div>

                                        <div class="mt-4"
                                            >Hier können Sie Ihre Erinnerungen zu
                                            <span class="font-bold">{{ entry.title }}</span> ansehen. Um sich Aufnahmen
                                            anzuhören, klicken Sie auf die gewünschte Aufnahme. Um sich Bilder
                                            anzusehen, klicken Sie auf das gewünschte Bild.
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-template #deleteInfo>
                                <div
                                    class="bg-red-400 py-4 px-5 min-h-[175px] text-white flex flex-col justify-center items-center text-center">
                                    <div class="font-bold text-xl max-w-lg"
                                        >Hier kannst du einzelne Fotos löschen. Verlasse den Modus indem du "Beenden"
                                        antippst.
                                    </div>
                                    <div class="max-w-lg text-sm mt-4"
                                        >Deine Daten sind wichtig – stelle sicher, dass du wirklich löschen möchtest,
                                        bevor du fortfährst.
                                    </div>

                                    <app-button color="white" class="mt-4" (click)="switchToDeleteMode(false)"
                                        >Beenden
                                    </app-button>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <div
                        class="bg-gradient-to-b from-primary to-transparent h-[100px] col-span-full row-start-1 pt-5 pb-6">
                        <app-page-title
                            colorClasses="text-white"
                            [backButtonLink]="['/lebensgeschichte']"
                            buttonText="Lebensgeschichte"
                            buttonColor="white">
                            <app-action-menu right-slot>
                                <div class="flex flex-col items-stretch">
                                    <button
                                        class="text-red flex justify-between p-4 whitespace-nowrap font-bold"
                                        (click)="switchToDeleteMode(true)">
                                        <span>Einzelne Einträge löschen</span>
                                        <fa-icon [icon]="['fas', 'trash-xmark']" class="ml-5"></fa-icon>
                                    </button>
                                    <div class="w-full h-px bg-primary/30"></div>
                                    <button
                                        class="text-red flex justify-between p-4 whitespace-nowrap font-bold"
                                        (confirmedClick)="delete()"
                                        dialogType="ConfirmedDelete"
                                        dialogBody="Möchtest du wirklich die gesamte Erinnerung löschen?">
                                        <span>Gesamte Erinnerung löschen</span>
                                        <fa-icon [icon]="['fas', 'trash-xmark']" class="ml-5"></fa-icon>
                                    </button>
                                </div>
                            </app-action-menu>
                        </app-page-title>
                    </div>
                </div>

                <form [formGroup]="form" *ngIf="form" class="w-full px-4 mt-8">
                    <div class="max-w-4xl mx-auto w-full">
                        <app-input-editor-modal
                            placeholder="Schreibe hier deine Erzählung nieder"
                            [placeholderIcon]="['fas', 'pencil']"
                            [editIcon]="['fas', 'pencil']"
                            formControlName="text"
                            modalLabel="Erzählung"
                            [style]="'white-card'"
                            [showSaveAndCancel]="true"></app-input-editor-modal>
                    </div>
                </form>

                <!-- audio entries-->
                <ng-container *ngIf="libraryEntry$ | async as libraryEntry">
                    <ng-container *ngIf="(libraryEntry.media | mediaType: 'audio').length > 0">
                        <section class="px-4 flex flex-col mt-11 mb-14 flex-grow">
                            <ng-container *ngIf="oneHasLocation(libraryEntry.media)">
                                <div class="max-w-4xl mx-auto flex flex-col w-full text-primary">
                                    <h3 class="font-bold mb-4">Aufnahme von {{ user.firstName }}</h3>

                                    <div class="flex gap-6">
                                        <div class="flex-grow flex flex-wrap gap-6">
                                            <!-- entry-->
                                            <ng-container
                                                *ngFor="let audioMedia of libraryEntry.media | mediaType: 'audio'">
                                                <app-library-entry-audio-tile
                                                    [media]="audioMedia"
                                                    (selected)="playAudioEntry(audioMedia)"
                                                    *ngIf="audioMedia.location"></app-library-entry-audio-tile>
                                            </ng-container>
                                        </div>

                                        <!-- add audio entry -->
                                        <!--                            <button type="button" class="w-28 h-28 bg-[#EDECEC] rounded-xl block flex-shrink-0">-->
                                        <!--                                <span-->
                                        <!--                                    class="bg-[#dad1c5] rounded-full text-4xl w-14 h-14 inline-flex items-center justify-center">-->
                                        <!--                                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>-->
                                        <!--                                </span>-->
                                        <!--                            </button>-->
                                    </div>
                                </div>
                            </ng-container>
                        </section>
                    </ng-container>
                </ng-container>

                <!-- highlights-->
                <section class="py-6 px-4 bg-[#dad1c5] mt-11" *ngIf="libraryEntry$ | async as libraryEntry">
                    <div class="max-w-4xl mx-auto flex flex-col w-full text-primary">
                        <h3 class="font-bold mb-4">Fotos / Highlights von {{ user.firstName }}</h3>

                        <ng-container *ngIf="(libraryEntry.media | mediaType: 'image').length > 0; else noMedia">
                            <div class="flex flex-wrap gap-x-8 gap-y-6 mb-10">
                                <ng-container
                                    *ngFor="
                                        let media of libraryEntry.media | mediaType: 'image';
                                        trackBy: trackByMediaId
                                    ">
                                    <div class="relative">
                                        <app-library-entry-highlight
                                            [media]="media"
                                            [deleteMode]="deleteMode()"
                                            (selected)="openHighlightImage(media)"
                                            (delete)="deleteMediaEntry($event)">
                                        </app-library-entry-highlight>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-template #noMedia>
                            <div class="py-6 px-4 text-center"
                                >Du hast noch keine Bilder für diese Erinnerung hochgeladen
                            </div>
                        </ng-template>

                        <div class="flex w-full justify-center" *ngIf="!deleteMode()">
                            <app-button (click)="fileInput.click()">Fotos hinzufügen</app-button>
                            <input
                                type="file"
                                class="hidden"
                                accept="image/*"
                                #fileInput
                                (input)="addMediaToLibraryEntry($event)"
                                multiple />
                        </div>
                    </div>
                </section>
            </ng-container>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./library-entry-detail.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DefaultLayoutWithNavigationComponent,
        NgIf,
        BackgroundGradientComponent,
        AsyncPipe,
        FaIconComponent,
        SharedModule,
        ConfirmedClickDirective,
        ReactiveFormsModule,
        InputEditorModalComponent,
        NgForOf,
        LibraryEntryHighlightComponent,
        LibraryEntryAudioTileComponent,
    ],
})
export class LibraryEntryDetailComponent implements OnInit {
    libraryEntry$!: Observable<LibraryEntry>;
    libraryEntry!: LibraryEntry;
    user$!: Observable<User>;

    deleteMode = signal(false);

    // todo why?
    impulseQuestion$!: Observable<LibraryImpulseQuestion | undefined>;
    headerImage$?: Observable<LibraryEntryMedia | null>;

    // track function for media section rendering
    trackByMediaId: TrackByFunction<LibraryEntryMedia> = (index, media) => media.id;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private router: Router,
        private actions: Actions,
        private infoService: InfoService,
    ) {}

    dialog = inject(Dialog);
    form!: FormGroup;

    ngOnInit(): void {
        // this form is for the text input
        this.form = new FormGroup({
            text: new FormControl(''),
        });

        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
            this.store.dispatch(
                LibraryEntryActions.updateLibraryEntry({
                    libraryEntry: {
                        id: this.libraryEntry.id,
                        changes: value,
                    },
                }),
            );
        });

        this.libraryEntry$ = this.route.params.pipe(
            pluck('id'),
            switchMap(id => this.store.pipe(selectLibraryEntryByIdNoNull(id))),
            shareReplay(1),
        );

        this.libraryEntry$.pipe(untilDestroyed(this)).subscribe(entry => {
            this.libraryEntry = entry;
            this.form.patchValue(entry, { emitEvent: false });
        });

        this.user$ = this.store.pipe(selectNonNullUser());

        this.impulseQuestion$ = this.libraryEntry$.pipe(
            switchMap(libraryEntry => this.store.pipe(selectImpulseQuestionById(libraryEntry.impulseQuestionId))),
            shareReplay(1),
        );

        this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
            this.store.dispatch(LibraryEntryActions.loadLibraryEntries());
        });

        this.infoService.setCurrentPage('entry-detail');

        // todo updated nicht wenn bilder hinzugefügt oder gelöscht werden
        this.headerImage$ = this.libraryEntry$.pipe(
            first(),
            map(entry => this.getRandomImageEntry(entry)),
        );
    }

    // temp until we can add more records
    oneHasLocation(mediaEntries: LibraryEntryMedia[]) {
        return mediaEntries.some(entry => entry.location);
    }

    // opens modal for changing info details of library-entry
    async openEditLibraryInfoModal() {
        const entry = await firstValueFrom(this.libraryEntry$);
        const ref = this.dialog.open(LibraryInfoEditModal);
        const instance = ref.componentInstance as LibraryInfoEditModal;

        instance.libraryEntry = entry;
        instance.dialogEventResponse.pipe(takeUntil(ref.closed)).subscribe(res => {
            if (res.value && res.type === DialogEvents.confirm) {
                this.store.dispatch(
                    LibraryEntryActions.updateLibraryEntry({
                        libraryEntry: {
                            id: entry.id,
                            changes: res.value,
                        },
                    }),
                );
            }

            ref.close();
        });
    }

    getRandomImageEntry(entry: LibraryEntry) {
        if (!entry || !Array.isArray(entry.media)) {
            return null;
        }

        const imageArray = entry.media.filter(entry => entry.contentType?.startsWith('image'));

        if (imageArray.length === 0) {
            return null;
        }

        const randomIndex = Math.floor(Math.random() * imageArray.length);
        return imageArray[randomIndex];
    }

    playAudioEntry(libraryAudioEntry: LibraryEntryMedia) {
        const ref = this.dialog.open(AudioPlayerCoreComponent);
        const instance = ref.componentInstance as AudioPlayerCoreComponent;

        instance.src = libraryAudioEntry.location;
        instance.playAfterInit = true;
    }

    openHighlightImage(libraryImageMedia: LibraryEntryMedia) {
        const ref = this.dialog.open(ImageModalComponent);
        const instance = ref.componentInstance as ImageModalComponent;

        instance.src = libraryImageMedia.location;
    }

    get entryId(): string {
        return this.route.snapshot.paramMap.get('id')!;
    }

    delete() {
        this.store.dispatch(LibraryEntryActions.deleteLibraryEntry({ libraryEntryId: this.entryId }));

        this.actions
            .pipe(ofType(LibraryEntryActions.deleteLibraryEntrySuccess), take(1), untilDestroyed(this))
            .subscribe(() => this.router.navigate(['/lebensgeschichte']));
    }

    addMediaToLibraryEntry(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files!;

        this.store.dispatch(
            LibraryEntryActions.addLibraryEntryMedia({
                libraryEntryId: this.entryId,
                mediaFiles: { files: Array.from(files) },
            }),
        );

        target.value = '';
    }

    switchToDeleteMode(on: boolean) {
        this.deleteMode.set(on);
    }

    deleteMediaEntry(mediaEntry: LibraryEntryMedia) {
        this.store.dispatch(
            LibraryEntryActions.deleteLibraryEntryMedia({ libraryEntryId: this.entryId, mediaId: mediaEntry.id }),
        );
    }
}
