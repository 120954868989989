import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { DiaryEffects } from './+store/diary.effects';
import { diaryFeatureKey, diaryReducer } from './+store/diary.reducer';
import { DiaryEntryFormComponent } from './components/diary-entry-form/diary-entry-form.component';
import { DiaryIndexComponent } from './diary-index/diary-index.component';

@NgModule({
    declarations: [DiaryIndexComponent, DiaryEntryFormComponent],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        StoreModule.forFeature(diaryFeatureKey, diaryReducer),
        EffectsModule.forFeature([DiaryEffects]),
    ],
    exports: [DiaryEntryFormComponent],
})
export class DiaryModule {}
