import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LibraryEntryService } from '../../services/library-entry.service';

@UntilDestroy()
@Component({
    selector: 'app-conversation-selection',
    template: `
        <div class="bg-gray-100 rounded-lg py-7 px-16 px-12 max-w-[465px] border">
            <div class="text-center mb-6">
                <h3 class="text-primary font-semibold"> Worauf möchtest du deinen Fokus im Gespräch legen? </h3>
            </div>
            <div class="grid grid-cols-2 min-h-[140px] gap-x-4">
                <a
                    [routerLink]="['/lebensgeschichte', 'inspirationsfragen']"
                    class="bg-white rounded-lg shadow p-4 flex flex-col items-center">
                    <img src="assets/artwork/bulb.svg" alt="Glühbirne" />
                    <div class="mt-auto">Inspirationsfragen</div>
                </a>
                <button
                    (click)="create()"
                    class="bg-white rounded-lg shadow p-4 flex flex-col items-center"
                    type="button">
                    <img src="assets/artwork/chat-bubbles.svg" alt="Chat" />
                    <div class="mt-auto">Freie Erzählung</div>
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./conversation-selection.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink],
})
export class ConversationSelectionComponent {
    libraryEntryService = inject(LibraryEntryService);
    router = inject(Router);

    create() {
        this.libraryEntryService
            .createLibraryEntryForRecord({})
            .pipe(untilDestroyed(this))
            .subscribe({
                next: idsToRoute => {
                    this.router.navigate([
                        '/',
                        'lebensgeschichte',
                        'erinnerung-erstellen',
                        idsToRoute.libraryEntryId,
                        idsToRoute.audioRecordId,
                    ]);
                },
                error: error => {
                    console.log(error);
                },
            });
    }
}
