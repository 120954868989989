import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LegalLinksComponent } from '../shared/components/legal-links/legal-links.component';
import { SharedModule } from '../shared/shared.module';
import { AuthEffects } from './+store/auth.effects';
import * as fromAuth from './+store/auth.reducer';
import { AuthRoutingModule } from './auth-routing.module';
import { EmailSentComponent } from './pages/email-sent/email-sent.component';
import { LoginComponent } from './pages/login/login.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { SignupComponent } from './pages/signup/signup.component';

@NgModule({
    declarations: [LoginComponent, SignupComponent, SetPasswordComponent, EmailSentComponent],
    imports: [
        SharedModule,
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        StoreModule.forFeature('auth', fromAuth.reducer),
        EffectsModule.forFeature([AuthEffects]),
        FormsModule,
        LegalLinksComponent,
    ],
    exports: [LoginComponent],
})
export class AuthModule {}
