import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroupFinderDirective } from '../directives/form-group-finder/form-group-finder.directive';
import { TypedValidationErrors } from '../types/typed-validation-errors.type';

@Component({
    selector: 'app-input-error-messages',
    template: `
        <div class="text-sm text-red-600 px-2 py-1 min-h-[28px]" *ngIf="formGroupFinder?.formGroup">
            <ng-container *ngIf="errorObject && (formGroupFinder?.formGroup?.submitted || touched)">
                <div *ngIf="errorObject?.['required']">Dieses Feld wird benötigt.</div>
                <div *ngIf="errorObject?.['maxLength']; let maxLength"> Maximal {{ maxLength }} Zeichen erlaubt. </div>
                <div *ngIf="errorObject?.['minValue']; let minValue"> Muss mindestens {{ minValue }} sein.</div>
                <div *ngIf="errorObject?.['maxValue']; let maxValue"> Muss kleiner sein als {{ maxValue }}.</div>
                <div *ngIf="errorObject?.['maxSelect']; let maxSelect">Maximale Anzahl {{ maxSelect }}.</div>
            </ng-container>
        </div>
    `,
    styleUrls: ['./input-error-messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ReactiveFormsModule],
})
export class InputErrorMessagesComponent implements OnInit {
    formGroupFinder = inject(FormGroupFinderDirective, { optional: true });

    @Input() errorObject: TypedValidationErrors | null = null;
    @Input() touched?: boolean = false;

    ngOnInit() {
        if (!this.formGroupFinder) {
            console.warn(
                'No FormGroupFinder directive Found. To enable validation errors add "formGroupFinder" directive to form',
            );
        }
    }
}
