import { Location } from '@angular/common';
import { Component, computed, Input, OnInit, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { faCircle, faHome, faQuestion, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from '../../../auth/+store/auth.actions';
import { selectUser } from '../../../user/+store/user.selectors';
import { InfoService } from '../../info.service';

@Component({
    selector: 'app-navigation',
    template: `
        <nav class="bg-white h-[75px] px-12">
            <div class="flex items-center h-full">
                <div class="mr-auto items-center flex">
                    <a [routerLink]="['/']" class="flex flex-col text-gray-500 items-center px-10">
                        <fa-icon [icon]="faHome"></fa-icon>
                        <div class="mt-1 text-sm">Start</div>
                    </a>

                    <!--                    <button class="px-10" (click)="toggleInfo()" [tp]="tpl" tpVariation="popper" tpMaxWidth="auto">-->
                    <!--                        <div class="flex flex-col text-gray-500">-->
                    <!--                            <fa-icon [icon]="faQuestion"></fa-icon>-->
                    <!--                            <div class="mt-1 text-sm">Hilfe</div>-->
                    <!--                        </div>-->
                    <!--                    </button>-->

                    <!--                    <ng-template class="tippy-box tippy-arrow" #tpl let-hide>-->
                    <!--                        <div class="text-sm" [innerHTML]="infoText$ | async"></div>-->
                    <!--                    </ng-template>-->
                </div>

                <div class="ml-auto flex items-center w-[200px] shrink justify-end">
                    <a
                        class="flex flex-col text-gray-500 items-center px-10"
                        [routerLink]="['/', 'mein-bereich']"
                        *ngIf="user()">
                        <fa-icon [icon]="['fas', 'circle-user']"></fa-icon>
                        <div class="mt-1 text-sm">Mein Bereich</div>
                    </a>

                    <!--<a class="text-right ml-4" (click)="location.back()" *ngIf="showBackButton">
                        <fa-stack size="lg">
                            <fa-icon [icon]="faCircleLight" stackItemSize="2x"></fa-icon>
                            <fa-icon [icon]="faArrowLeft" stackItemSize="1x"></fa-icon>
                        </fa-stack>
                    </a>-->
                </div>
            </div>
        </nav>
    `,
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    faHome = faHome;
    faCircle = faCircle;
    faUserCircle = faUserCircle;
    faQuestion = faQuestion;

    infoText$!: Observable<string>;

    @Input() showBackButton = true;
    @Input() showLogoutButton = false;
    @ViewChild(TippyDirective, { static: true }) tippy!: TippyDirective;

    constructor(
        public infoService: InfoService,
        public location: Location,
        private router: Router,
        private store: Store,
    ) {}

    user = toSignal(this.store.select(selectUser));

    toggleInfo() {
        this.infoService.toggleInfo();
    }

    ngOnInit(): void {
        this.updateInfoText();
    }

    updateInfoText() {
        this.infoText$ = this.infoService.infoText$;
    }

    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
