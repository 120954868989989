import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedModule } from '../../../shared/shared.module';
import { AuthBoxComponent } from '../../components/auth-box/auth-box.component';
import { AuthPageWrapperComponent } from '../../components/auth-page-wrapper/auth-page-wrapper.component';

@Component({
    selector: 'app-password-forget',
    standalone: true,
    imports: [AuthPageWrapperComponent, NgIf, ReactiveFormsModule, SharedModule, AuthBoxComponent, RouterLink],
    template: `
        <app-auth-wrapper>
            <app-auth-box>
                <h2 class="m-4 mx-8 text-2xl flex justify-center font-sans">Passwort vergessen</h2>
                <form [formGroup]="formGroup" (ngSubmit)="submit()" class="space-y-6">
                    <div class="m-2">
                        <input
                            type="email"
                            formControlName="email"
                            required
                            placeholder="E-Mail Adresse"
                            class="form-control" />
                    </div>
                    <!--                <p class="mt-4 text-red-600" *ngIf="errorMsg">-->
                    <!--                    <ng-container *ngIf="errorMsg.status === 409; else errorFallback">-->
                    <!--                        Diese E-Mail Adresse wird schon verwendet.-->
                    <!--                    </ng-container>-->
                    <!--                    <ng-template #errorFallback>-->
                    <!--                        Ups, hier ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.-->
                    <!--                    </ng-template>-->
                    <!--                </p>-->
                    <app-button type="submit" class="flex justify-center font-sans">Absenden</app-button>
                </form>
            </app-auth-box>
            <h3 class="flex justify-center mt-3 font-sans">Du kennst dein Passwort doch?</h3>
            <app-button class="flex justify-center mt-1 font-sans" [routerLink]="['/auth', 'login']" color="white">
                Zurück zum Login
            </app-button>
        </app-auth-wrapper>
    `,
    styleUrl: './password-forget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordForgetComponent {
    fb = inject(FormBuilder);
    router = inject(Router);
    http = inject(HttpClient);

    formGroup = this.fb.group({
        email: ['', Validators.required],
    });

    submit() {
        this.http
            .post(`${environment.api}/auth/password-forget`, { email: this.formGroup.getRawValue().email })
            .subscribe(() => {
                this.router.navigate(['/auth/password-forget-success']);
            });
    }
}
