import { routerReducer, RouterState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../environments/environment';
import { clearAll } from './auth/+store/auth.actions';
import * as fromAuth from './auth/+store/auth.reducer';
import { authFeatureKey } from './auth/+store/auth.reducer';
import { subUserFeatureKey, subUserReducer, SubUserState } from './caretaker/+store/sub-user.reducer';
import * as fromDiary from './diary/+store/diary.reducer';
import { diaryFeatureKey } from './diary/+store/diary.reducer';
import * as fromLibraryEntry from './library/+store/library-entry/library-entry.reducer';
import { librariesFeatureKey } from './library/+store/library-entry/library-entry.reducer';
import * as fromLibraryImpulseQuestion from './library/+store/library-impulse-question/library-impulse-question.reducer';
import { libraryImpulseQuestionsFeatureKey } from './library/+store/library-impulse-question/library-impulse-question.reducer';
import { userFeatureKey, userReducer, UserState } from './user/+store/user.reducer';

export interface AppState {
    router: RouterState;
    [authFeatureKey]: fromAuth.AuthState;
    [diaryFeatureKey]: fromDiary.DiaryState;
    [librariesFeatureKey]: fromLibraryEntry.LibraryEntryState;
    [libraryImpulseQuestionsFeatureKey]: fromLibraryImpulseQuestion.LibraryImpulseQuestionState;
    [subUserFeatureKey]: SubUserState;
    [userFeatureKey]: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
    [authFeatureKey]: fromAuth.reducer,
    [diaryFeatureKey]: fromDiary.diaryReducer,
    [librariesFeatureKey]: fromLibraryEntry.libraryEntryReducer,
    [libraryImpulseQuestionsFeatureKey]: fromLibraryImpulseQuestion.libraryImpulseQuestionReducer,
    [subUserFeatureKey]: subUserReducer,
    [userFeatureKey]: userReducer,
};

export function clearReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === clearAll.type) {
            return reducer(undefined, action);
        } else {
            return reducer(state, action);
        }
    };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                [authFeatureKey]: ['accessToken', 'refreshToken', 'redirectUrl', 'exp'],
            },
            {
                [userFeatureKey]: ['user'],
            },
        ],
        rehydrate: true,
        restoreDates: false,
    })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [localStorageSyncReducer, clearReducer]
    : [localStorageSyncReducer, clearReducer];
