import { inject, Injectable } from '@angular/core';
import { ModalConfirmComponent } from '../modal/modal-confirm/modal-confirm.component';
import { first, map } from 'rxjs/operators';
import { DialogEvents } from '../modal/dialog-modal-footer/dialog-modal-footer.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateService {
    private dialog = inject(Dialog);
    private canDeactivateRef?: DialogRef<unknown, ModalConfirmComponent>;

    canDeactivate(canDeactivate: boolean) {
        if (!canDeactivate) {
            return true;
        }

        if (this.canDeactivateRef) {
            this.canDeactivateRef.close();
        }

        this.canDeactivateRef = this.dialog.open(ModalConfirmComponent, {
            disableClose: true,
            closeOnNavigation: false,
        });
        const instance = this.canDeactivateRef.componentInstance as ModalConfirmComponent;
        return instance.dialogEventResponse.pipe(
            first(),
            map(res => {
                this.canDeactivateRef!.close();
                return res.type === DialogEvents.confirm;
            }),
        );
    }
}
