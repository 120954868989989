import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectImpulseQuestionsLoading } from '../../+store/library-impulse-question/library-impulse-question.reducer';
import { AppState } from '../../../app.reducer';
import { DefaultLayoutWithNavigationComponent } from '../../../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../../../shared/shared.module';
import { ImpulseQuestionCarouselComponent } from '../../components/impulse-question-carousel/impulse-question-carousel.component';

@UntilDestroy()
@Component({
    selector: 'app-library-create-impulse-question',
    template: `
        <app-default-layout-with-navigation [loading]="loading() ?? false">
            <div class="grow flex flex-col items-center py-5">
                <app-page-title [backButtonLink]="['/lebensgeschichte/anlegen']">Inspirationsfragen</app-page-title>
                <div class="grow flex items-center max-w-full px-4">
                    <app-impulse-question-carousel></app-impulse-question-carousel>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./library-impulse-question-select-page.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DefaultLayoutWithNavigationComponent, SharedModule, ImpulseQuestionCarouselComponent],
})
export class LibraryImpulseQuestionSelectPageComponent {
    store = inject<Store<AppState>>(Store);

    loading = toSignal(this.store.pipe(selectImpulseQuestionsLoading()));
}
