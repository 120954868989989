import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../app.reducer';
import { setRedirectUrl } from './+store/auth.actions';
import { selectAccessToken } from './+store/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private toast: HotToastService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.pipe(
            select(selectAccessToken),
            map(accessToken => {
                if (!accessToken) {
                    // this.toast.info('Bitte Einloggen');
                    this.store.dispatch(setRedirectUrl({ url: state.url }));
                    return this.router.createUrlTree(['/auth/login']);
                }
                return true;
            }),
        );
    }
}
