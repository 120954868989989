import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../shared/shared.module';

export enum PageLayout {
    grid,
    list,
}

@Component({
    selector: 'app-layout-switch',
    template: `
        <div class="flex">
            <div class="bg-neutral-300 p-2 flex rounded-md shadow">
                <button
                    (click)="switch.emit(pageLayout.grid)"
                    class="w-10 h-10"
                    [class.active]="currentLayout === pageLayout.grid">
                    <fa-icon [icon]="['fas', 'grid-2']"></fa-icon>
                </button>
                <button
                    (click)="switch.emit(pageLayout.list)"
                    class="w-10 h-10"
                    [class.active]="currentLayout === pageLayout.list">
                    <fa-icon [icon]="['fas', 'list-ul']"></fa-icon>
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./layout-switch.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FaIconComponent, SharedModule],
})
export class LayoutSwitchComponent {
    @Output() switch = new EventEmitter<PageLayout>();
    @Input() currentLayout?: PageLayout;

    pageLayout = PageLayout;
}
