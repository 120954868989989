import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthBoxComponent } from '../../components/auth-box/auth-box.component';
import { AuthPageWrapperComponent } from '../../components/auth-page-wrapper/auth-page-wrapper.component';

@Component({
    selector: 'app-password-forget-success',
    standalone: true,
    imports: [AuthPageWrapperComponent, AuthBoxComponent],
    template: ` <app-auth-wrapper>
        <app-auth-box>
            <h2 class="m-4 mx-8 text-2xl flex justify-center text-center font-sans"> E-Mail gesendet </h2>
            <p class="text-center font-sans">
                Wir haben dir eine E-Mail geschickt. Bitte schaue in dein E-Mail Postfach und klicke auf den Link in der
                E-Mail.
            </p>
        </app-auth-box>
    </app-auth-wrapper>`,
    styleUrl: './password-forget-success.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordForgetSuccessComponent {}
