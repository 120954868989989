import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogModalWrapperComponent } from '../../modal/dialog-modal-wrapper/dialog-modal-wrapper.component';
import { DialogModalHeaderComponent } from '../../modal/dialog-modal-header/dialog-modal-header.component';
import { DialogModalFooterComponent } from '../../modal/dialog-modal-footer/dialog-modal-footer.component';

@UntilDestroy()
@Component({
    selector: 'app-audio-player-core',
    template: `
        <app-dialog-modal-wrapper>
            <app-dialog-modal-header>Sprachaufnahme abspielen</app-dialog-modal-header>
            <div class="max-w-full p-5">
                <audio [src]="src" #audio controls style="width: 100%" controlsList="nodownload"></audio>
            </div>
        </app-dialog-modal-wrapper>
    `,
    styleUrls: ['./audio-player-core.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DialogModalWrapperComponent, DialogModalHeaderComponent, DialogModalFooterComponent],
})
export class AudioPlayerCoreComponent implements OnInit {
    @Input() src!: string;
    @Input() playAfterInit: boolean = false;
    // public src = 'assets/Free_Test_Data_2MB_OGG.ogg';

    @ViewChild('audio', { static: true }) audioRef!: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        // fromEvent(this.player, 'ended')
        //     .pipe(untilDestroyed(this))
        //     .subscribe(() => this.onEnd());
        //
        // fromEvent(this.player, 'timeupdate')
        //     .pipe(untilDestroyed(this))
        //     .subscribe(e => this.onTimeUpdate(e));
        if (this.playAfterInit) {
            fromEvent(this.player, 'canplay')
                .pipe(first())
                .subscribe(() => this.togglePlayer());
        }
    }

    get paused() {
        return this.audioRef.nativeElement.paused;
    }

    get player() {
        return this.audioRef.nativeElement;
    }

    togglePlayer() {
        if (this.paused) {
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    //
    // onEnd() {
    //     console.log('onEnd');
    //     this.cdr.markForCheck();
    // }
    //
    // onTimeUpdate(time: any) {
    //     console.log('onTimeUpdate', time);
    //     this.cdr.markForCheck();
    // }
}
