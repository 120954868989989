import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LibraryEntryActions } from '../+store/library-entry/library-entry.actions';
import { Actions, ofType } from '@ngrx/effects';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const libraryEntryAudioRecordResolve: ResolveFn<Observable<boolean>> = (route, state) => {
    const entryId = route.paramMap.get('id');
    const recordId = route.paramMap.get('recordid');
    const store = inject(Store);
    const actions = inject(Actions);
    const router = inject(Router);

    const redirect = () => router.navigate(['/lebensgeschichte']);

    store.dispatch(LibraryEntryActions.loadLibraryEntryByID({ id: entryId ?? '' }));

    return actions.pipe(
        ofType(LibraryEntryActions.loadLibraryEntryByIDSuccess, LibraryEntryActions.loadLibraryEntryByIDFailure),
        first(),
        map(action => {
            if (action.type === LibraryEntryActions.loadLibraryEntryByIDSuccess.type) {
                const record = action.libraryEntry.media.find(media => media.id === recordId);
                if (record && typeof recordId === 'string') {
                    return true;
                } else {
                    console.error('Missing Record with this ID');
                    redirect();
                    return false;
                }
            } else {
                redirect();
                return false;
            }
        }),
    );
};
