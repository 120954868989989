import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InfoService } from '../../../shared/info.service';

@Component({
    selector: 'app-channel-six',
    template: `
        <app-default-layout-with-navigation>
            <div class="p-4 grow flex flex-col overflow-hidden">
                <div class="bg-white grow rounded-lg shadow-lg flex relative overflow-hidden">
                    <iframe
                        class="flex w-full"
                        src="https://teresa.whereby.com/teresa-six95c9dbfa-aee6-46a1-a275-66cef5e2d425"
                        allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"></iframe>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./channel-six.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelSixComponent implements OnInit {
    constructor(private infoService: InfoService) {}

    ngOnInit(): void {
        this.infoService.setCurrentPage('channel-voice');
    }
}
