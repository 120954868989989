import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
    actions$ = inject(Actions);
    userService = inject(UserService);
    router = inject(Router);
    toastService = inject(HotToastService);

    loadMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadMe),
            switchMap(action =>
                this.userService.loadMe().pipe(
                    map(user => UserActions.loadMeSuccess({ user })),
                    catchError(error => of(UserActions.loadMeFailure({ error }))),
                ),
            ),
        ),
    );

    updateMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateMe),
            switchMap(action =>
                this.userService.updateMe(action.user).pipe(
                    map(user => UserActions.updateMeSuccess({ user })),
                    tap(() => {
                        this.toastService.success('Nutzerdaten erfolgreich aktualisiert.');
                    }),
                    catchError(error => of(UserActions.updateMeFailure({ error }))),
                ),
            ),
        ),
    );

    updateUserShortProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateShortProfile),
            switchMap(action =>
                this.userService.updateShortProfile(action.shortProfile).pipe(
                    map(user => UserActions.updateShortProfileSuccess({ user })),
                    tap(() => {
                        this.toastService.success('Nutzerdaten erfolgreich aktualisiert.');
                    }),
                    catchError(error => of(UserActions.updateShortProfileFailure({ error }))),
                ),
            ),
        ),
    );

    updateNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateNotes),
            switchMap(action =>
                this.userService.updateNotes(action.notes).pipe(
                    map(user => UserActions.updateNotesSuccess({ user })),
                    tap(() => {
                        this.toastService.success('Notiz erfolgreich aktualisiert.');
                    }),
                    catchError(error => of(UserActions.updateNotesFailure({ error }))),
                ),
            ),
        ),
    );

    updateAvatar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateAvatar),
            switchMap(action =>
                this.userService.updateAvatar(action.avatar).pipe(
                    map(user => UserActions.updateAvatarSuccess({ user })),
                    tap(() => {
                        this.toastService.success('Avatar erfolgreich hinzugefügt.');
                    }),
                    catchError(error => of(UserActions.updateAvatarFailure({ error }))),
                ),
            ),
        ),
    );

    removeAvatar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.deleteAvatar),
            switchMap(action =>
                this.userService.removeAvatar().pipe(
                    map(user => UserActions.deleteAvatarSuccess({ user })),
                    tap(() => {
                        this.toastService.success('Avatar erfolgreich entfernt.');
                    }),
                    catchError(error => of(UserActions.deleteAvatarFailure({ error }))),
                ),
            ),
        ),
    );
}
