export function isRequiredInvalid(value: string, required: boolean | undefined) {
    return required && (!value || value === '');
}

export function isMaxLengthInvalid(length: number, maxLength: number | undefined) {
    return typeof maxLength === 'number' && length > maxLength;
}

export function isMinValueInvalid(value: string, minValue: number | undefined) {
    return typeof minValue === 'number' && +value < minValue;
}

export function isMaxValueInvalid(value: string, maxValue: number | undefined) {
    return typeof maxValue === 'number' && +value > maxValue;
}

export function isMinCountInvalid(value: string[], count: number) {
    return value.length < count;
}

export function isMaxCountInvalid(value: string[], count: number) {
    return value.length > count;
}
