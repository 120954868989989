import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LibraryEntryMedia } from '../../+store/library-entry/library-entry.model';
import { DatePipe, NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-library-entry-audio-tile',
    template: `
        <div class="flex gap-x-4">
            <button
                class="w-28 h-28 bg-lightBeige rounded-xl flex-shrink-0 inline-flex flex-col items-center justify-center"
                (click)="selected.emit(media)">
                <span class="w-12 aspect-square rounded-full bg-white inline-flex items-center justify-center">
                    <fa-icon [icon]="['fas', 'play']"></fa-icon>
                </span>
                <div class="text-sm mt-2 font-medium" *ngIf="media.duration"> {{ formatTime(media.duration) }}</div>
            </button>

            <div *ngIf="media.createdAt" class="font-medium text-sm leading-normal">
                <div>Aufnahme vom:</div>
                <div>{{ media.createdAt | date: 'longDate' }}</div>
            </div>
        </div>
    `,
    styleUrls: ['./library-entry-audio-tile.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DatePipe, FaIconComponent, SharedModule, NgIf],
})
export class LibraryEntryAudioTileComponent {
    @Input() media!: LibraryEntryMedia;
    @Output() selected = new EventEmitter<LibraryEntryMedia>();

    formatTime(timeInMs: number): string {
        const timeInSeconds = timeInMs / 1000;
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
}
