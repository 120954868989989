import { ChangeDetectionStrategy, Component, computed, Input } from '@angular/core';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { RouterLink } from '@angular/router';
import { ImageComponent } from '../../../shared/components/image/image.component';
import { DatePipe, NgIf } from '@angular/common';

@Component({
    selector: 'app-story-collection-list-item',
    standalone: true,
    template: `
        <a [routerLink]="['/lebensgeschichte', 'erinnerung', libraryEntry.id]" class="flex w-full text-primary gap-x-5">
            <div class="rounded-xl overflow-hidden max-w-[234px] w-1/3 min-w-[150px] h-fit">
                <app-image [src]="previewImage()" width="234" height="234"></app-image>
            </div>

            <div>
                <div class="text-sm font-medium">
                    <span *ngIf="libraryEntry.location; let loc" class="inline-block mb-2">{{ loc }}</span
                    ><span class="first:hidden last:hidden">, </span>
                    <span *ngIf="libraryEntry.year; let year" class="inline-block mb-2">{{ year }}</span>
                </div>
                <div class="font-bold text-l">
                    {{ libraryEntry.title }}
                </div>
                <div class="mt-2 text-sm font-medium text-[#9F9F9F]">
                    Erstellt am {{ libraryEntry.createdAt | date: 'dd.MM.yyyy' }}
                </div>
            </div>
        </a>
    `,
    styleUrls: ['./story-collection-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, ImageComponent, DatePipe, NgIf],
})
export class StoryCollectionListItemComponent {
    @Input() libraryEntry!: LibraryEntry;

    // todo check better result
    previewImage = computed(() => {
        return this.libraryEntry?.media?.filter(media => media.contentType?.startsWith('image'))[0]?.location;
    });
}
