import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { ChannelFiveComponent } from './channel-five/channel-five.component';
import { ChannelFourComponent } from './channel-four/channel-four.component';
import { ChannelOneComponent } from './channel-one/channel-one.component';
import { ChannelSixComponent } from './channel-six/channel-six.component';
import { ChannelThreeComponent } from './channel-three/channel-three.component';
import { ChannelTwoComponent } from './channel-two/channel-two.component';
import { ChannelComponent } from './channel/channel.component';

export const componentRouts: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ChannelComponent,
        canActivate: [AuthGuard],
    },
    {
        path: '1',
        canActivate: [AuthGuard],
        component: ChannelOneComponent,
    },
    {
        path: '2',
        canActivate: [AuthGuard],
        component: ChannelTwoComponent,
    },
    {
        path: '3',
        canActivate: [AuthGuard],
        component: ChannelThreeComponent,
    },
    {
        path: '4',
        canActivate: [AuthGuard],
        component: ChannelFourComponent,
    },
    {
        path: '5',
        canActivate: [AuthGuard],
        component: ChannelFiveComponent,
    },
    {
        path: '6',
        canActivate: [AuthGuard],
        component: ChannelSixComponent,
    },
];
@NgModule({
    exports: [RouterModule],
})
export class ComponentsRoutingModule {}
