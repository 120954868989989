import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { DialogEventResponse, IsDialogModal } from '../../../shared/modal/interfaces/is-dialog-modal.interface';
import { SharedModule } from '../../../shared/shared.module';
import { DialogModalWrapperComponent } from '../../../shared/modal/dialog-modal-wrapper/dialog-modal-wrapper.component';
import { NgClass, NgIf } from '@angular/common';
import {
    DialogEvents,
    DialogModalFooterComponent,
} from '../../../shared/modal/dialog-modal-footer/dialog-modal-footer.component';
import { DialogModalHeaderComponent } from '../../../shared/modal/dialog-modal-header/dialog-modal-header.component';

@UntilDestroy()
@Component({
    selector: 'app-library-info-edit-modal',
    template: `
        <app-dialog-modal-wrapper>
            <app-dialog-modal-header>
                <span class="text-primary font-bold">Jahr, Ort und Titel bearbeiten</span>
            </app-dialog-modal-header>
            <form [formGroup]="form" class="flex flex-col gap-y-3 p-4" [class.ng-submitted]="submitted">
                <div>
                    <input class="form-control" type="text" name="year" formControlName="year" placeholder="Jahr" />
                </div>

                <div>
                    <input
                        class="form-control"
                        type="text"
                        name="location"
                        formControlName="location"
                        placeholder="Ort" />
                </div>

                <input
                    formControlName="title"
                    class="form-control"
                    placeholder="Vergib einen Titel für deine Erinnerung (Pflichtfeld)"
                    type="text" />
            </form>
            <app-dialog-modal-footer
                confirmButtonText="Speichern"
                [cancelConfirm]="form.dirty"
                (dialogEvent)="onDialogEvent($event)"></app-dialog-modal-footer>
        </app-dialog-modal-wrapper>
    `,
    styleUrls: ['./library-info-edit-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    host: {
        class: 'contents',
    },
    imports: [
        ReactiveFormsModule,
        SharedModule,
        DialogModalWrapperComponent,
        NgIf,
        DialogModalFooterComponent,
        DialogModalHeaderComponent,
        NgClass,
    ],
})
export class LibraryInfoEditModal implements OnInit, IsDialogModal {
    public errors = false;
    public submitted = false;
    dialogEventResponse = new EventEmitter<DialogEventResponse<LibraryEntry>>();

    @Input()
    set libraryEntry(value: LibraryEntry) {
        this._libraryEntry = value;

        if (this.form) {
            this.form.patchValue(value);
        }
    }

    private cdr = inject(ChangeDetectorRef);
    private _libraryEntry?: LibraryEntry;
    public form!: FormGroup;

    onDialogEvent(type: DialogEvents) {
        if (type === DialogEvents.confirm) {
            this.submitted = true;

            if (!this.form.valid) {
                return;
            }
            this.dialogEventResponse.emit({ type, value: this.form.value });
        } else {
            this.dialogEventResponse.emit({ type });
        }
    }

    ngOnInit() {
        this.form = new FormGroup({
            year: new FormControl(''),
            title: new FormControl('', Validators.required),
            location: new FormControl(''),
        });

        if (this._libraryEntry) {
            this.form.patchValue(this._libraryEntry);
        }

        this.form
            .get('title')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe(v => {
                if ((v.length === 0) !== this.errors) {
                    this.errors = !this.errors;
                    this.cdr.markForCheck();
                }
            });
    }
}
