import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, computed, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { CaretakerLayoutNavigationComponent } from '../../../caretaker/components/caretaker-layout-navigation/caretaker-layout-navigation.component';
import { selectUser } from '../../../user/+store/user.selectors';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-default-layout-with-navigation',
    standalone: true,
    template: `
        <div class="min-h-full flex flex-col max-h-svh overflow-hidden">
            <div
                class="flex py-5 justify-center border-b-2 border-gray relative"
                [class.bg-beige]="backgroundColorTop"
                [class.border-gray-600]="backgroundColorTop">
                <img ngSrc="assets/logos/logo.svg" height="20" width="116" alt="" />

                <div
                    class="h-1 w-full inset-x-0 bottom-0 translate-y-full absolute overflow-hidden loader-animation transition-opacity opacity-0"
                    [class.opacity-100]="loading">
                </div>
            </div>
            <div
                class="grow flex flex-col overflow-auto max-h-full"
                [class.items-center]="centeredHorizontally"
                [class.justify-center]="centeredVertically">
                <ng-content></ng-content>
            </div>

            <div class="mt-auto border-t border-gray" *ngIf="showNavigation">
                <app-caretaker-layout-navigation
                    *ngIf="isCaretaker(); else clientNavigation"></app-caretaker-layout-navigation>
                <ng-template #clientNavigation>
                    <app-navigation
                        [showBackButton]="showBackButton"
                        [showLogoutButton]="showLogoutButton"></app-navigation>
                </ng-template>
            </div>
        </div>
    `,
    styleUrls: ['./default-layout-with-navigation.component.scss'],
    imports: [SharedModule, NgIf, NgOptimizedImage, CaretakerLayoutNavigationComponent],
})
export class DefaultLayoutWithNavigationComponent implements OnInit {
    @Input() showNavigation = true;
    @Input() showBackButton = true;
    @Input() showLogoutButton = false;
    @Input() centeredHorizontally = false;
    @Input() centeredVertically = false;
    @Input() backgroundColorTop = false;

    @Input() loading?: boolean;

    store = inject(Store);
    user = toSignal(this.store.select(selectUser));
    isCaretaker = computed(() => this.user()?.caretaker);

    constructor() {}

    ngOnInit(): void {}
}
