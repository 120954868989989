import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { CreateSubUserFragments } from '../../../caretaker/pages/caretaker-create-subuser/caretaker-create-subuser.component';

@Component({
    selector: 'app-client-profile-tab-navigation',
    standalone: true,
    imports: [RouterLinkActive, RouterLink],
    template: `
        <div class="flex flex-col sm:flex-row items-center border bg-gray-100 rounded-md p-1.5">
            <a
                [routerLink]="['.']"
                [fragment]="navigationFragments.coreData || undefined"
                [routerLinkActiveOptions]="linkActiveOptions"
                routerLinkActive="bg-white font-bold !text-primary"
                class="text-gray-400 py-3 px-5 rounded-md"
                >Stammdaten</a
            >
            <a
                [routerLink]="['.']"
                [routerLinkActiveOptions]="linkActiveOptions"
                [fragment]="navigationFragments.information"
                routerLinkActive="bg-white font-bold !text-primary"
                class="text-gray-400 py-3 px-5 rounded-md"
                >Informationen</a
            >
            <a
                [routerLink]="['.']"
                [routerLinkActiveOptions]="linkActiveOptions"
                [fragment]="navigationFragments.photo"
                routerLinkActive="bg-white font-bold !text-primary"
                class="text-gray-400 py-3 px-5 rounded-md"
                >Foto</a
            >
        </div>
    `,
    styleUrl: './client-profile-tab-navigation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfileTabNavigationComponent {
    linkActiveOptions: IsActiveMatchOptions = {
        fragment: 'exact',
        paths: 'exact',
        matrixParams: 'exact',
        queryParams: 'ignored',
    };

    navigationFragments = CreateSubUserFragments;
}
