import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { LibraryImpulseQuestionActions } from '../../+store/library-impulse-question/library-impulse-question.actions';
import { LibraryImpulseQuestion } from '../../+store/library-impulse-question/library-impulse-question.model';
import {
    selectImpulseQuestionsLoading,
    selectImpulseQuestionsNoNull,
} from '../../+store/library-impulse-question/library-impulse-question.reducer';
import { AppState } from '../../../app.reducer';
import { LibraryEntryService } from '../../services/library-entry.service';
import { LibraryImpulseQuestionService } from '../../services/library-impulse-question.service';
import { NgClass, NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../shared/shared.module';
import { BackgroundGradientComponent } from '../../../shared/components/background-gradient/background-gradient.component';

@UntilDestroy()
@Component({
    selector: 'app-impulse-question-carousel',
    template: `
        <ng-container *ngIf="questionCount() > 0; else noQuestions">
            <div
                class="flex justify-center gap-3 w-full"
                *ngIf="currentQuestion(); let q"
                style="--card-height: 426px; --card-width: 560px;">
                <!-- image-card-->
                <div
                    class="relative w-[var(--card-width)] h-[var(--card-height)] max-w-1/2 rounded-xl drop-shadow-xl overflow-hidden">
                    <app-background-gradient class="absolute inset-0 -z-10"></app-background-gradient>
                    <img
                        class="absolute inset-0 object-cover w-full h-full"
                        [src]="q.media?.location"
                        alt="Inspirationsbild"
                        *ngIf="q.media?.location" />

                    <div
                        class="absolute inset-0 w-full h-full flex flex-col p-4"
                        *ngIf="q.attribution"
                        [ngClass]="{ 'bg-black/70': showAttribution() }">
                        <div class="flex-grow flex justify-center items-center" *ngIf="showAttribution()">
                            <span class="text-white max-w-full hyphens-auto">{{ q.attribution }}</span>
                        </div>
                        <div class="mt-auto">
                            <button
                                (click)="toggleAttribution()"
                                class="bg-white/55 text-primary inline-flex rounded-lg py-1.5 px-2 text-sm shadow-lg font-bold">
                                <span *ngIf="!showAttribution()">Quellenangabe</span>
                                <span *ngIf="showAttribution()">Schließen</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    class="flex w-[var(--card-width)] h-[var(--card-height)] max-w-1/2 rounded-xl bg-[#facec3] drop-shadow-xl flex-col p-4">
                    <div class="flex-grow flex items-center text-primary justify-center">
                        <h3 class="text-center font-bold text-2xl">
                            {{ q.title }}
                        </h3>
                    </div>
                    <div class="grid grid-cols-[1fr_auto_1fr] gap-x-2">
                        <div class="flex">
                            <app-button color="white" (click)="changeQuestionIndex(-1)" *ngIf="currentIndex() !== 0">
                                <fa-icon class="text-[#384250]" [icon]="['fas', 'arrow-left']"></fa-icon>
                            </app-button>
                        </div>

                        <app-button (click)="createWithQuestion()">Erzählung beginnen</app-button>

                        <div class="flex justify-end">
                            <app-button color="white" (click)="changeQuestionIndex(1)" *ngIf="!lastQuestion()">
                                <fa-icon class="text-[#384250]" [icon]="['fas', 'arrow-right']"></fa-icon>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #noQuestions>
            <div *ngIf="!loading()">Keine Inspirationsfragen vorhanden</div>
        </ng-template>
    `,
    styleUrls: ['./impulse-question-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    host: {
        class: 'contents',
    },
    imports: [NgIf, FaIconComponent, SharedModule, NgClass, BackgroundGradientComponent],
})
export class ImpulseQuestionCarouselComponent implements OnInit {
    store = inject<Store<AppState>>(Store);
    libraryEntryService = inject(LibraryEntryService);
    router = inject(Router);

    // todo shuffle each time?
    impulseQuestions = toSignal(this.store.pipe(selectImpulseQuestionsNoNull()), { initialValue: [] });

    loading = toSignal(this.store.pipe(selectImpulseQuestionsLoading()));
    currentIndex = signal(0);
    showAttribution = signal(false);

    questionCount = computed(() => this.impulseQuestions().length);
    currentQuestion = computed(() => {
        return this.impulseQuestions()[this.currentIndex()] as LibraryImpulseQuestion;
    });
    lastQuestion = computed(() => this.questionCount() === this.currentIndex() + 1);

    constructor(private libraryImpulseQuestionService: LibraryImpulseQuestionService) {}

    ngOnInit(): void {
        this.store.dispatch(LibraryImpulseQuestionActions.loadLibraryImpulseQuestions());
    }

    changeQuestionIndex(direction: 1 | -1) {
        const currIndex = this.currentIndex();
        const nextIndex = currIndex + direction;
        const indexCount = this.questionCount() - 1;

        if (nextIndex > indexCount || nextIndex < 0) {
            return;
        }

        this.currentIndex.set(nextIndex);
    }

    toggleAttribution() {
        this.showAttribution.set(!this.showAttribution());
    }

    createWithQuestion(): void {
        const currentQuestion = this.currentQuestion();

        if (!currentQuestion) return;

        // this.libraryImpulseQuestionService.setCurrentQuestion(this.currentQuestion());

        this.libraryEntryService
            .createLibraryEntryForRecord({ impulseQuestionId: currentQuestion.id })
            .pipe(untilDestroyed(this))
            .subscribe({
                next: idsToRoute => {
                    this.router.navigate([
                        '/',
                        'lebensgeschichte',
                        'erinnerung-erstellen',
                        idsToRoute.libraryEntryId,
                        idsToRoute.audioRecordId,
                    ]);
                },
                error: error => {
                    console.log(error);
                },
            });
    }
}
