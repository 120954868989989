import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authRoutes } from './auth/auth-routing.module';
import { briefProfileRoutes } from './brief-profile/brief-profile-routes';
import { CaretakerRouteNames, caretakerRoutes } from './caretaker/caretaker-routing';
import { AntiCaretakerGuard } from './caretaker/guards/anti-caretaker.guard';
import { CaretakerGuard } from './caretaker/guards/caretaker.guard';
import { clientRouting } from './client/client-routing';
import { dashboardRoutes } from './dashboard/dashboard-routing';
import { diaryRoutes } from './diary/diary-routes';
import { libraryRoutes } from './library/library-routing';
import { componentRouts } from './whereby/components/components-routing.module';

const routes: Routes = [
    {
        path: 'auth',
        children: authRoutes,
    },
    {
        path: CaretakerRouteNames.prefix,
        canActivate: [CaretakerGuard],
        children: caretakerRoutes,
    },
    {
        path: '',
        canActivateChild: [AntiCaretakerGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },

            {
                path: 'dashboard',
                children: dashboardRoutes,
                data: {
                    position: 1,
                },
            },

            {
                path: 'tagebuch',
                children: diaryRoutes,
            },
            {
                path: 'kurzprofil',
                children: briefProfileRoutes,
            },
            {
                path: 'lebensgeschichte',
                children: libraryRoutes,
                data: {
                    position: 2,
                },
            },
            {
                path: 'mein-bereich',
                children: clientRouting,
            },
            {
                path: 'iframe',
                children: componentRouts,
            },
            {
                path: 'channel',
                children: componentRouts,
                data: {
                    position: 1,
                },
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
