import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket,
    faArrowsSpin,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faBirthdayCake,
    faCamera,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faCircle,
    faCircleInfo,
    faCirclePlus,
    faCircleUser,
    faCircleXmark,
    faClipboard,
    faCloudArrowUp,
    faDove,
    faEllipsisVertical,
    faFamily,
    faFilesMedical,
    faGenderless,
    faGrid2,
    faHandHoldingMedical,
    faHandshake,
    faHeadphones,
    faHeart,
    faHome,
    faImage,
    faLanguage,
    faListUl,
    faLoader,
    faLocationDot,
    faMagnifyingGlass,
    faMars,
    faMemoCircleInfo,
    faMicrophone,
    faPalette,
    faPause,
    faPaw,
    faPencil,
    faPeople,
    faPlaceOfWorship,
    faPlay,
    faPlus,
    faRoad,
    faSpinner,
    faSun,
    faTrashXmark,
    faTrophy,
    faUsers,
    faUtensils,
    faVenus,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { TippyDirective } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { ErrorToastyEffects } from './+store/error-toasty/error-toasty.effects';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { AvatarUploadControlComponent } from './components/avatar-upload-control/avatar-upload-control.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ButtonNoShadowComponent } from './components/button-no-shadow/button-no-shadow.component';
import { DatepickerInputComponent } from './components/datepicker-input/datepicker-input.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeCardAboutComponent } from './components/home-card-about/home-card-about.component';
import { HomeCardBiographyComponent } from './components/home-card-biography/home-card-biography.component';
import { HomeCardConditionComponent } from './components/home-card-condition/home-card-condition.component';
import { HomeCardContactComponent } from './components/home-card-contact/home-card-contact.component';
import { HomeCardPlannerComponent } from './components/home-card-planner/home-card-planner.component';
import { HomeCardProfileComponent } from './components/home-card-profile/home-card-profile.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { MainModuleSelectionComponent } from './components/main-module-selection/main-module-selection.component';
import { MediaComponent } from './components/media/media.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { ReminderButtonComponent } from './components/reminder-button/reminder-button.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { VoiceRecorderPlayerComponent } from './components/voice-recorder/voice-recorder-player/voice-recorder-player.component';
import { VoiceRecorderRecorderComponent } from './components/voice-recorder/voice-recorder-recorder/voice-recorder-recorder.component';
import { VoiceRecorderComponent } from './components/voice-recorder/voice-recorder.component';
import { AvatarSrcDirective } from './directives/avatar-src/avatar-src.directive';
import { DropdownContainerDirective } from './directives/dropdown/dropdown-container.directive';
import { DropdownToggleDirective } from './directives/dropdown/dropdown-toggle.directive';
import { DropdownDirective } from './directives/dropdown/dropdown.directive';
import { MediaTypePipe } from './pipes/media-type.pipe';
import { ButtonComponent } from './components/button/button.component';

@NgModule({
    declarations: [
        HeaderComponent,
        DropdownContainerDirective,
        DropdownToggleDirective,
        DropdownDirective,
        NavigationComponent,
        HomepageComponent,
        PrimaryButtonComponent,
        ReminderButtonComponent,
        HomeCardBiographyComponent,
        HomeCardContactComponent,
        HomeCardConditionComponent,
        HomeCardProfileComponent,
        HomeCardAboutComponent,
        BadgeComponent,
        MediaComponent,
        MediaTypePipe,
        ButtonNoShadowComponent,
        HomeCardPlannerComponent,
        ValidationErrorComponent,
        ProfileHeaderComponent,
        MainModuleSelectionComponent,
        DatepickerInputComponent,
        AvatarSrcDirective,
        PageTitleComponent,
        ActionMenuComponent,
        VoiceRecorderComponent,
        VoiceRecorderPlayerComponent,
        VoiceRecorderRecorderComponent,
        ButtonComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        TippyDirective,
        NgOptimizedImage,
        EffectsModule.forFeature([ErrorToastyEffects]),
    ],
    exports: [
        HeaderComponent,
        DropdownContainerDirective,
        DropdownToggleDirective,
        DropdownDirective,
        NavigationComponent,
        HomepageComponent,
        ReminderButtonComponent,
        HomeCardBiographyComponent,
        BadgeComponent,
        MediaComponent,
        HomeCardAboutComponent,
        MediaTypePipe,
        ButtonNoShadowComponent,
        FontAwesomeModule,
        HomeCardPlannerComponent,
        HomeCardProfileComponent,
        HomeCardContactComponent,
        HomeCardConditionComponent,
        ValidationErrorComponent,
        ProfileHeaderComponent,
        MainModuleSelectionComponent,
        DatepickerInputComponent,
        AvatarSrcDirective,
        PageTitleComponent,
        VoiceRecorderComponent,
        ActionMenuComponent,
        ButtonComponent,
    ],
})
export class SharedModule {
    constructor(iconLibrary: FaIconLibrary) {
        iconLibrary.addIcons(
            faChevronLeft,
            faChevronDown,
            faClipboard,
            faArrowsSpin,
            faPalette,
            faSun,
            faTrophy,
            faHandHoldingMedical,
            faTrashXmark,
            faCircle,
            faPlay,
            faPause,
            faMicrophone,
            faImage,
            faPlus,
            faPencil,
            faMagnifyingGlass,
            faEllipsisVertical,
            faArrowLeft,
            faArrowRight,
            faGrid2,
            faListUl,
            faCloudArrowUp,
            faLoader,
            faHeadphones,
            faCamera,
            faSpinner,
            faArrowRightFromBracket,
            faCircleInfo,
            faCircleUser,
            faCircleXmark,
            faXmark,
            faFilesMedical,
            faPeople,
            faPlaceOfWorship,
            faUtensils,
            faCheck,
            faCirclePlus,
            faArrowUpRightAndArrowDownLeftFromCenter,
            faHome,
            faUsers,
            faPaw,
            faFamily,
            faGenderless,
            faVenus,
            faMars,
            faHeart,
            faHandshake,
            faLanguage,
            faBirthdayCake,
            faLocationDot,
            faDove,
            faRoad,
            faMemoCircleInfo,
        );
    }
}
