import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { UserActions } from '../+store/user.actions';

export const getMeResolver: ResolveFn<Action> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    inject(Store).dispatch(UserActions.loadMe());
    return inject(Actions).pipe(ofType(UserActions.loadMeSuccess, UserActions.loadMeFailure), take(1));
};
