import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { loginSuccess } from '../auth/+store/auth.actions';
import { SubUser } from './+store/sub-user.model';

@Injectable({
    providedIn: 'root',
})
export class CaretakerService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private store: Store,
    ) {}

    loadAllSubUsers() {
        return this.http.get<SubUser[]>(`${environment.api}/tenant/clients`);
    }

    impersonate(id: string) {
        this.http
            .get<{ accessToken: string; refreshToken: string }>(`${environment.api}/tenant/impersonate/${id}`)
            .subscribe(({ accessToken, refreshToken }) => {
                this.store.dispatch(loginSuccess({ accessToken, refreshToken }));
                this.router.navigate(['/']);
            });
    }
}
