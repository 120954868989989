import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';
import { AppButtonColorVariants } from '../button/button.component';

@Component({
    selector: 'app-page-title',
    template: `
        <div class="w-full grid grid-cols-[1fr_auto_1fr] items-center h-14" [ngClass]="classes()">
            <div>
                <app-button
                    [outline]="true"
                    [link]="backButtonLink"
                    *ngIf="backButtonLink"
                    [wrap]="false"
                    [color]="buttonColor">
                    <fa-icon [icon]="['fas', 'chevron-left']" class="mr-2"></fa-icon>
                    <span>{{ buttonText ? buttonText : 'Zurück' }}</span>
                </app-button>
            </div>

            <h2 class="font-serif">
                <ng-content></ng-content>
            </h2>

            <div class="flex justify-end items-center">
                <ng-content select="[right-slot]"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./page-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'w-full',
    },
})
export class PageTitleComponent {
    containerSpacingClasses = signal(['px-5 mb-5']);
    containerColorClasses = signal(['text-primary']);

    classes = computed(() => {
        return [...this.containerSpacingClasses(), ...this.containerColorClasses()];
    });

    @Input()
    set spacingClasses(value: string) {
        this.containerSpacingClasses.set([value]);
    }

    @Input()
    set colorClasses(value: string) {
        this.containerColorClasses.set([value]);
    }

    @Input() backButtonLink?: any[];

    @Input() buttonText?: string;

    @Input() buttonColor: AppButtonColorVariants = 'primary';
}
