import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filterNullish } from '../../shared/utility/filter-nullish.operator';
import { userFeatureKey, UserState } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUserLoading = () => select(createSelector(selectUserState, state => state.loading));
export const selectUserSavingInProgress = () =>
    select(createSelector(selectUserState, state => state.savingInProgress));

export const selectUser = createSelector(selectUserState, state => state.user);
export const selectNonNullUser = () => pipe(select(selectUser), filterNullish());

export const selectUserId = createSelector(selectUserState, state => state.user?.id);

export const selectUserShortProfile = createSelector(selectUserState, state => state.user?.shortProfile);

export const selectUserNotes = createSelector(selectUserState, state => state.user?.notes);
