import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { DialogModalWrapperComponent } from '../dialog-modal-wrapper/dialog-modal-wrapper.component';
import { DialogEventResponse, IsDialogModal } from '../interfaces/is-dialog-modal.interface';
import { DialogEvents, DialogModalFooterComponent } from '../dialog-modal-footer/dialog-modal-footer.component';
import { DialogModalHeaderComponent } from '../dialog-modal-header/dialog-modal-header.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-modal-confirm',
    standalone: true,
    imports: [DialogModalWrapperComponent, DialogModalFooterComponent, DialogModalHeaderComponent, NgIf],
    template: `
        <app-dialog-modal-wrapper maxWidth="400px">
            <app-dialog-modal-header *ngIf="dialogTitle" [closeButton]="false">
                <span class="font-bold text-primary">{{ dialogTitle }}</span>
            </app-dialog-modal-header>
            <div class="text-primary text-2xl px-4 py-6 text-center font-bold">
                {{ dialogBody }}
            </div>
            <app-dialog-modal-footer
                [confirmButtonText]="dialogConfirmBtn"
                (dialogEvent)="onDialogEvent($event)"
                [cancelButtonText]="dialogCancelBtn"></app-dialog-modal-footer>
        </app-dialog-modal-wrapper>
    `,
    styleUrl: './modal-confirm.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent implements IsDialogModal {
    @Input() dialogTitle?: string;
    @Input() dialogBody: string = 'Möchtest du wirklich abbrechen?';
    @Input() dialogCancelBtn: string = 'Abbrechen';
    @Input() dialogConfirmBtn: string = 'Bestätigen';
    @Input() confirmCancel? = false;

    dialogEventResponse = new EventEmitter<DialogEventResponse<undefined>>();

    onDialogEvent(type: DialogEvents) {
        this.dialogEventResponse.emit({ type });
    }
}
