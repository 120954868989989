import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../../app.reducer';
import {
    GroupCollapsableComponent,
    GroupCollapse,
} from '../../../shared/components/group-collapsable/group-collapsable.component';
import { InputEditorModalComponent } from '../../../shared/custom-inputs/input-editor-modal/input-editor-modal.component';
import { ControlsOf } from '../../../shared/types/controls-of.type';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';
import { UserActions } from '../../../user/+store/user.actions';
import { UserShortProfile } from '../../../user/+store/user.model';
import { selectUserShortProfile } from '../../../user/+store/user.selectors';
import { ClientShortProfileInputComponent } from '../client-short-profile-input/client-short-profile-input.component';

export interface ShortProfileGroup extends GroupCollapse {
    inputs: {
        key: keyof UserShortProfile;
        info?: string;
        label?: string;
        iconDef?: IconProp;
    }[];
}

@UntilDestroy()
@Component({
    selector: 'app-client-short-profile',
    standalone: true,
    imports: [
        GroupCollapsableComponent,
        NgForOf,
        ClientShortProfileInputComponent,
        ReactiveFormsModule,
        InputEditorModalComponent,
        FaIconComponent,
        NgIf,
    ],
    template: `
        <form class="space-y-4" [formGroup]="form">
            <div *ngFor="let group of shortProfileGroups">
                <app-group-collapsable
                    [infoHeadline]="group.infoHeadline"
                    [headline]="group.headline"
                    [subline]="group.subline"
                    [iconDef]="group.iconDef">
                    <div class="flex flex-col gap-y-6">
                        <app-input-editor-modal
                            *ngFor="let input of group.inputs"
                            [label]="input.label"
                            [labelIcon]="input.iconDef"
                            [labelInfo]="input.info"
                            [modalLabel]="group.headline + (input.label ? ' - ' + input.label : '')"
                            [formControlName]="input.key"
                            [editorPlaceholder]="input.info"
                            [beforeValueChange]="inputChange(input.key)"
                            [showSaveAndCancel]="true">
                        </app-input-editor-modal>
                    </div>
                </app-group-collapsable>
            </div>
        </form>
    `,
    styleUrl: './client-short-profile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientShortProfileComponent implements OnInit {
    shortProfileGroups: ShortProfileGroup[] = [
        {
            headline: 'Bezugspersonen',
            subline: 'Familie, Freunde und treue Wegbegleiter*innen',
            iconDef: ['fas', 'people'],
            inputs: [
                {
                    key: 'family',
                    label: 'Familie',
                    info:
                        'Bitte trage hier mindestens den Vornamen ein und \n' +
                        'welches Verhältnis zu der Person besteht, z. B. Familie: älteste Tochter, \n' +
                        'Lebensgefährt*in, Ehepartner*in oder Schulfreund*in.',
                },
                {
                    key: 'friends',
                    label: 'Freunde',
                },
                {
                    key: 'companions',
                    label: 'Wegbegleiter',
                },
            ],
        },
        {
            headline: 'Umgang miteinander',
            subline: 'Werte für den alltäglichen Umgang',
            iconDef: ['fas', 'handshake'],
            inputs: [
                {
                    key: 'socialInteraction',
                    label: 'Umgang miteinander',
                    info:
                        'Hier geht es um Werte für den Umgang im Alltag, wie z. B. Pünktlichkeit, \n' +
                        'Verschwiegenheit oder konkrete Beispiele für den Umgang miteinander: Leiser Umgangston,  \n' +
                        'möchte vor körperlichen Berührungen um Erlaubnis gefragt werden, etc.',
                },
            ],
        },
        {
            headline: 'Ausbildung & Berufe',
            subline: 'Tätigkeiten und Aufgaben im Laufe des Lebens',
            iconDef: ['fas', 'clipboard'],
            inputs: [
                {
                    key: 'education',
                    label: 'Ausbildung',
                    info:
                        'Hier kann auch notiert werden, weshalb eventuell  keine Ausbildung möglich war. \n' +
                        'Zum Beispiel sollte Hausfrau als Tätigkeit, mit Beschreibung der Aufgabe, wertschätzend eingetragen werden.',
                },
                {
                    key: 'jobs',
                    label: 'Berufe',
                },
            ],
        },
        {
            headline: 'Essen & Trinken',
            subline: 'Kulinarische Gewohnheiten zu verschiedenen Tageszeiten',
            infoHeadline:
                'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                ' Auch starke Abneigungen können hier notiert werden.',
            iconDef: ['fas', 'utensils'],
            inputs: [
                {
                    key: 'foodMorning',
                    label: 'Morgen',
                    info:
                        'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                        ' Auch starke Abneigungen können hier notiert werden.',
                },
                {
                    key: 'foodMidday',
                    label: 'Mittag',
                    info:
                        'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                        ' Auch starke Abneigungen können hier notiert werden.',
                },
                {
                    key: 'foodAfternoon',
                    label: 'Kaffee & Kuchen',
                    info:
                        'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                        ' Auch starke Abneigungen können hier notiert werden.',
                },
                {
                    key: 'foodEvening',
                    label: 'Abend',
                    info:
                        'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                        ' Auch starke Abneigungen können hier notiert werden.',
                },
                {
                    key: 'foodSnacks',
                    label: 'Zwischendurch',
                    info:
                        'Hier kann notiert werden, welche Speisen zu welchen Tageszeiten besonders gerne eingenommen werden. \n' +
                        ' Auch starke Abneigungen können hier notiert werden.',
                },
            ],
        },
        {
            headline: 'Rituale',
            subline: 'Sicherheit und Orientierung durch feste Abläufe',
            iconDef: ['fas', 'arrows-spin'],
            inputs: [
                {
                    key: 'habits',
                    label: 'Alltägliche Rituale',
                    info:
                        'Rituale sind Gewohnheiten, feste Abläufe und bekannte Reihenfolgen mit einem bestimmten Symbolgehalt, die meist nicht so schnell vergessen werden. \n' +
                        'Sie erinnern an etwas Vergangenes und geben gleichzeitig Sicherheit und Orientierung. Dadurch haben sie eine beruhigende Wirkung. \n' +
                        'Das kann ein "Guten Morgen" mit Händeschütteln sein, wenn die Betreuungskraft morgens ins Zimmer tritt, \n' +
                        'das Essen zu den gleichen Uhrzeiten oder eine wiederkehrende Aktivität am Nachmittag, wie z. B. eine besondere Sorte Kekse zum Kaffee.',
                },
                {
                    key: 'spiritualHabits',
                    label: 'Spirituelle Rituale',
                    info: 'Für viele Menschen sind spirituelle oder religiöse Rituale ein fester Bestandteil ihres Lebens. Notiere hier tägliche Rituale, wie z. B. Gebete, oder auch Feste, die über das Jahr verteilt gefeiert werden.',
                },
            ],
        },
        {
            headline: 'Interessen & Hobbies',
            subline: 'Interessen und Hobbies von früher und heute',
            iconDef: ['fas', 'palette'],
            inputs: [
                {
                    key: 'interests',
                    label: 'Interessen',
                    info: 'Dies können Interessen von heute und früher sein.',
                },
                {
                    key: 'hobbies',
                    label: 'Hobbies',
                    info: 'Dies können Hobbies von heute und früher sein.',
                },
            ],
        },
        {
            headline: 'Freuden',
            subline: 'Dinge, die positive Gefühle auslösen',
            iconDef: ['fas', 'sun'],
            inputs: [
                {
                    key: 'joys',
                    label: 'Freuden',
                    info:
                        'Dies können kleine Dinge sein, die Freude machen, z. B. bestimmte Kleidung, Blumen, Farben oder Düfte. \n' +
                        ' Vielleicht auch so etwas wie die Interaktion mit Tieren oder der Natur.',
                },
            ],
        },
        {
            headline: 'Ressourcen & Fähigkeiten',
            subline: 'Charakter, Talente, Stärken, Errungenschaften',
            iconDef: ['fas', 'trophy'],
            inputs: [
                {
                    key: 'characterTraits',
                    label: 'Charaktereigenschaften',
                    info: 'Z. B. ruhig, zurückhaltend, extrovertiert, humorvoll…',
                },
                {
                    key: 'talents',
                    label: 'Talente & Fähigkeiten',
                    info: 'Dies können angeborene Talente oder erlernte Fähigkeiten sein…',
                },
                {
                    key: 'personalStrengths',
                    label: 'Persönliche Stärken',
                    info: 'Z. B. was half Krisen zu überwinden, wie z. B. Durchhaltevermögen, der Glaube an sich selbst…',
                },
                {
                    key: 'proud',
                    label: 'Stolz auf...',
                    info: 'Erreichtes im Leben, beruflich oder persönlich.',
                },
            ],
        },
        {
            headline: 'Gesundheit',
            subline: 'Wichtiges rund um Gesundheit, Medikamente und Unverträglichkeiten',
            iconDef: ['fas', 'hand-holding-medical'],
            inputs: [
                {
                    key: 'mentalHealth',
                    label: 'Allgemeiner seelischer Gesundheitszustand',
                },
                {
                    key: 'physicalHealth',
                    label: 'Allgemeiner körperlicher Gesundheitszustand',
                },
                {
                    key: 'mobility',
                    label: 'Mobilität',
                },
                {
                    key: 'allergies',
                    label: 'Allergien & Unverträglichkeiten',
                },
                {
                    key: 'medication',
                    label: 'Medikamente',
                    info: 'Siehe Pflegedokumentation.',
                },
            ],
        },
        {
            headline: 'Letzte Lebensphase',
            subline: 'Gestaltung der kommenden Zeit',
            iconDef: ['fas', 'road'],
            inputs: [
                {
                    key: 'finalLifeStageDescription',
                    label: 'Letzte Lebensphase',
                    info: 'Hier kann notiert werden, wie die letzte Lebensphase gestaltet werden soll.',
                },
            ],
        },
        {
            headline: 'Sonstiges',
            subline: 'Platz für weitere Informationen',
            iconDef: ['fas', 'memo-circle-info'],
            inputs: [
                {
                    key: 'additionalInformation',
                    label: 'Sonstiges',
                    info: 'Eine Rubrik fehlt? Hier findet die Information ihren Platz.',
                },
            ],
        },
    ];

    store = inject(Store<AppState>);
    actions = inject(Actions);
    form!: FormGroup<ControlsOf<UserShortProfile>>;

    ngOnInit() {
        let controls = this.shortProfileGroups.reduce((acc, curr) => {
            for (let input of curr.inputs) {
                acc[input.key] = new FormControl('', { nonNullable: true });
            }
            return acc;
        }, {} as ControlsOf<UserShortProfile>);

        this.form = new FormGroup(controls);

        this.store
            .pipe(select(selectUserShortProfile), filterNullish(), untilDestroyed(this))
            .subscribe(shortProfile => this.form.patchValue(shortProfile, { emitEvent: false }));
    }

    inputChange(key: keyof UserShortProfile) {
        return (value: string) => {
            this.store.dispatch(UserActions.updateShortProfile({ shortProfile: { [key]: value } }));

            return firstValueFrom(
                this.actions.pipe(
                    ofType(UserActions.updateShortProfileSuccess, UserActions.updateShortProfileFailure),
                    map(action => action.type === UserActions.updateShortProfileSuccess.type),
                    untilDestroyed(this),
                ),
            );
        };
    }
}
