import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DiaryService } from '../diary.service';
import {
    createDiaryEntry,
    createDiaryEntryFailure,
    createDiaryEntrySuccess,
} from './diary.actions';

@Injectable()
export class DiaryEffects {
    createDiaryEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createDiaryEntry),
            switchMap(action =>
                this.diaryService.createEntry(action.data).pipe(
                    map(entry => createDiaryEntrySuccess({ entry })),
                    catchError(error => of(createDiaryEntryFailure({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private diaryService: DiaryService,
    ) {}
}
