export class CustomFormData extends FormData {
    constructor(jsonObject: any, parentKey: string = '') {
        super();
        this.convert(jsonObject, parentKey);
    }

    private convert(jsonObject: any, parentKey: any, options?: any) {
        if (options && options.initialFormData) {
            if (!this.isAppendFunctionPresent(options.initialFormData)) {
                throw new Error(
                    'initialFormData must have an append function.',
                );
            }
        } else if (!this.isGlobalFormDataPresent()) {
            throw new Error(
                'This environment does not have global form data. options.initialFormData must be specified.',
            );
        }

        const defaultOptions = {
            initialFormData: this.getDefaultFormData(),
            showLeafArrayIndexes: true,
            includeNullValues: false,
            mapping: (value: any) => {
                if (typeof value === 'boolean') {
                    return +value ? '1' : '0';
                }
                return value;
            },
        };

        const mergedOptions = this.mergeObjects(defaultOptions, options || {});
        return this.convertRecursively(
            jsonObject,
            mergedOptions,
            mergedOptions.initialFormData,
            parentKey,
        );
    }

    private convertRecursively(
        jsonObject: any,
        options: any,
        formData: any,
        parentKey?: any,
    ) {
        let index = 0;

        // tslint:disable-next-line:forin
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                let propName = parentKey || key;
                const value = options.mapping(jsonObject[key]);

                if (parentKey && this.isJsonObject(jsonObject)) {
                    propName = parentKey + '[' + key + ']';
                }

                if (parentKey && this.isArray(jsonObject)) {
                    if (this.isArray(value) || options.showLeafArrayIndexes) {
                        propName = parentKey + '[' + index + ']';
                    } else {
                        propName = parentKey + '[]';
                    }
                }

                if (this.isArray(value) || this.isJsonObject(value)) {
                    this.convertRecursively(value, options, formData, propName);
                } else if (value instanceof FileList) {
                    for (let j = 0; j < value.length; j++) {
                        formData.append(
                            propName + '[' + j + ']',
                            value.item(j),
                        );
                    }
                } else if (value instanceof Blob) {
                    // @ts-ignore
                    formData.append(propName, value, value.name);
                } else if (value instanceof Date) {
                    formData.append(propName, value.toISOString());
                } else if (
                    ((value === null && options.includeNullValues) ||
                        value !== null) &&
                    value !== undefined
                ) {
                    formData.append(propName, value);
                }
            }
            index++;
        }
        return formData;
    }

    private mergeObjects(object1: any, object2: any) {
        return [object1, object2].reduce((carry, objectToMerge) => {
            Object.keys(objectToMerge).forEach(objectKey => {
                carry[objectKey] = objectToMerge[objectKey];
            });
            return carry;
        }, {});
    }

    private isArray(val: any) {
        return {}.toString.call(val) === '[object Array]';
    }

    private isJsonObject(val: any) {
        return (
            !this.isArray(val) &&
            typeof val === 'object' &&
            !!val &&
            !(val instanceof Blob) &&
            !(val instanceof Date)
        );
    }

    private isAppendFunctionPresent(formData: any) {
        return typeof formData.append === 'function';
    }

    private isGlobalFormDataPresent() {
        return typeof FormData === 'function';
    }

    private getDefaultFormData() {
        if (this.isGlobalFormDataPresent()) {
            return this;
        }

        return undefined;
    }
}
