import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-legal-links',
    standalone: true,
    imports: [],
    template: `
        <div class="mt-4 flex justify-center w-full font-sans">
            <a href="assets/legal/datenschutz.pdf" target="_blank">Datenschutz</a>
            <a href="assets/legal/impressum.pdf" target="_blank" class="ml-4">Impressum</a>
        </div>
    `,
    styleUrl: './legal-links.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalLinksComponent {}
