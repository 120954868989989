import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { StoryCollectionListItemComponent } from '../story-collection-list-item/story-collection-list-item.component';
import { NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'app-story-collection-list',
    standalone: true,
    template: `
        <div class="flex flex-col w-full">
            <div *ngFor="let libraryEntry of libraryEntries; let l = last">
                <app-story-collection-list-item [libraryEntry]="libraryEntry"></app-story-collection-list-item>
                <div class="w-full h-px bg-[#C6C5C5] my-6" *ngIf="!l"></div>
            </div>
        </div>
    `,
    styleUrls: ['./story-collection-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
    imports: [StoryCollectionListItemComponent, NgForOf, NgIf],
})
export class StoryCollectionListComponent {
    @Input() libraryEntries?: LibraryEntry[];
}
