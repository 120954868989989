import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DefaultLayoutWithNavigationComponent } from '../shared/components/default-layout-with-navigation/default-layout-with-navigation.component';
import { SharedModule } from '../shared/shared.module';
import { SubUserEffects } from './+store/sub-user.effects';
import * as fromCaretaker from './+store/sub-user.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterLink,
        RouterLinkActive,
        StoreModule.forFeature(fromCaretaker.subUserFeatureKey, fromCaretaker.subUserReducer),
        EffectsModule.forFeature([SubUserEffects]),
        NgOptimizedImage,
        DefaultLayoutWithNavigationComponent,
    ],
})
export class CaretakerModule {}
