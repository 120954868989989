import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { NgClass, NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-dialog-modal-header',
    standalone: true,
    imports: [NgIf, SharedModule, NgClass],
    template: `
        <div class="w-full flex pl-4 pr-2 py-2 items-center" [ngClass]="bgColorClass">
            <ng-content></ng-content>
            <button class="w-10 h-10 text-2xl ml-auto text-primary" (click)="close()" *ngIf="closeButton">
                <fa-icon [icon]="['fas', 'circle-xmark']"></fa-icon>
            </button>
        </div>
    `,
    styleUrl: './dialog-modal-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogModalHeaderComponent {
    @Input() closeButton = true;
    @Input() bgColorClass = 'bg-beige';
    dialogRef = inject(DialogRef);

    close() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
