import { Action, createReducer, on } from '@ngrx/store';
import {
    login,
    loginFailure,
    loginSuccess,
    setPassword,
    setPasswordFailure,
    setPasswordSuccess,
    signup,
    signupFailure,
    signupSuccess,
} from './auth.actions';

export interface Credentials {
    email: string;
    password: string;
}

export const authFeatureKey = 'auth';

export interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    isLoading: boolean;
    error?: any;
    redirectUrl: string | null;
    exp: string | null;
}

export const initialAuthState: AuthState = {
    accessToken: null,
    refreshToken: null,
    isLoading: false,
    error: null,
    redirectUrl: null,
    exp: null,
};

export const authReducer = createReducer(
    initialAuthState,

    on(login, state => ({ ...state, isLoading: true, error: null })),
    on(loginSuccess, (state, { accessToken, refreshToken }) => ({
        ...state,
        accessToken,
        refreshToken,
        isLoading: false,
        error: null,
    })),
    on(loginFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error: error,
    })),

    on(signup, state => ({ ...state, isLoading: true, error: null })),
    on(signupSuccess, state => ({ ...initialAuthState })),
    on(signupFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),

    on(setPassword, state => ({ ...state, isLoading: true, error: null })),
    on(setPasswordSuccess, state => ({ ...initialAuthState })),
    on(setPasswordFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error: error.error.message,
    })),
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
    return authReducer(state, action);
}
