import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpErrorResponse } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { HotToastModule } from '@ngneat/hot-toast';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { metaReducers, reducers } from './app.reducer';
import { AuthModule } from './auth/auth.module';
import { TokenInterceptor } from './auth/token.interceptor';
import { BriefProfileModule } from './brief-profile/brief-profile.module';
import { CaretakerModule } from './caretaker/caretaker.module';
import { DiaryModule } from './diary/diary.module';
import { UserEffects } from './user/+store/user.effects';
import { ComponentsModule } from './whereby/components/components.module';
import { LibraryEntryEffects } from './library/+store/library-entry/library-entry.effects';
import { LibraryImpulseQuestionEffects } from './library/+store/library-impulse-question/library-impulse-question.effects';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@Injectable()
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AuthModule,
        DiaryModule,
        BriefProfileModule,
        CaretakerModule,
        ComponentsModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        EffectsModule.forRoot([UserEffects]),
        EffectsModule.forFeature([LibraryEntryEffects, LibraryImpulseQuestionEffects]),
        !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
        HotToastModule.forRoot(),
        StoreRouterConnectingModule.forRoot(),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        provideTippyConfig({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: tooltipVariation,
                popper: popperVariation,
            },
        }),
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue: 'tinymce/tinymce.min.js',
        },
        {
            provide: APP_INITIALIZER,
            deps: [HttpClient],
            multi: true,
            useFactory: (http: HttpClient) => {
                return () =>
                    new Promise<void>(resolve => {
                        http.post(`${environment.api}`, {}).subscribe({
                            next: () => resolve(),
                            error: (err: HttpErrorResponse) => {
                                if (err.status === 404 || err.status === 0 || err.status === 405) {
                                    environment.api = environment.defaultApi;
                                }
                                resolve();
                            },
                        });
                    });
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
