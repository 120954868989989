import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { getMeResolver } from '../user/resolver/get-me.resolver';
import { ClientMySpaceComponent } from './pages/client-my-space/client-my-space.component';
import { ClientProfileEditComponent } from './pages/client-profile-edit/client-profile-edit.component';
import { canDeactivateGuard } from '../shared/guards/can-deactivate.guard';

export const clientRouting: Routes = [
    {
        path: '',
        component: ClientMySpaceComponent,
        canActivate: [AuthGuard],
        resolve: {
            userAction: getMeResolver,
        },
        children: [
            {
                path: '',
                redirectTo: 'profil',
                pathMatch: 'full',
            },
            {
                path: 'profil',
                component: ClientProfileEditComponent,
                canDeactivate: [canDeactivateGuard],
            },
            // {
            //     path: 'alltagshelfer',
            //     component: ClientCaretakerSelectionComponent,
            // },
        ],
    },
];
