import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DiaryEntry, selectAllDiaryEntries } from '../+store/diary.reducer';
import { AppState } from '../../app.reducer';

@Component({
    selector: 'app-diary-index',
    templateUrl: './diary-index.component.html',
    styleUrls: ['./diary-index.component.scss'],
})
export class DiaryIndexComponent implements OnInit {
    diaryEntries$!: Observable<DiaryEntry[]>;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.diaryEntries$ = this.store.pipe(select(selectAllDiaryEntries));
    }
}
