import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { getNames } from 'i18n-iso-countries';
import { setPassword } from '../../+store/auth.actions';
import { AppState } from '../../../app.reducer';
import { LegalLinksComponent } from '../../../shared/components/legal-links/legal-links.component';
import { SharedModule } from '../../../shared/shared.module';

@Component({
    selector: 'app-complete-signup',
    template: `
        <div class="flex items-center bg-beige h-full w-full font-sans">
            <div class="w-1/2 p-10 flex justify-end">
                <div>
                    <div class="relative overflow-hidden rounded-lg shadow-lg">
                        <img
                            class="object-cover w-full h-full border-2 border-l-4 border-b-4 border border-blue rounded-md"
                            [src]="image"
                            alt="" />
                        <div class="absolute bottom-0 left-0 p-2 m-4 bg-blue rounded-xl text-center">
                            <h3 class="mb-3 text-white font-sans">
                                Wir haben alle eine individuelle Geschichte, darin sind wir uns gleich.
                            </h3>
                        </div>
                    </div>
                    <app-legal-links></app-legal-links>
                </div>
            </div>
            <div class="w-1/2 p-10 flex">
                <div class="flex flex-col">
                    <div
                        class="sm:mx-auto sm:w-full sm:max-w-md border-2 border-l-4 border-b-4 border border-blue rounded-md">
                        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div>
                                <img class="mx-auto h-12 w-auto" src="/assets/logos/logo.png" alt="TeresaLogo" />
                            </div>
                            <h2 class="m-4 text-2xl flex justify-center font-sans">Jetzt Passwort festlegen</h2>
                            <form [formGroup]="setPasswordForm" (ngSubmit)="submit()" class="space-y-6">
                                <div>
                                    <div class="m-2">
                                        <input
                                            formControlName="password"
                                            type="password"
                                            placeholder="Passwort"
                                            autocomplete="current-password"
                                            required
                                            class="form-control" />
                                        <div class="ml-2">
                                            <app-validation-error controlName="password"></app-validation-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-2">
                                    <div class="mt-1">
                                        <input
                                            formControlName="passwordConfirm"
                                            type="password"
                                            required
                                            placeholder="Passwort bestätigen"
                                            class="form-control" />
                                        <div class="ml-2">
                                            <app-validation-error controlName="passwordConfirm"></app-validation-error>
                                            <app-validation-error
                                                errorName="passwordConfirm"
                                                errorText="Die eingegebenen Passwörter sind nicht identisch."></app-validation-error>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex items-center ml-4 mb-2 mt-4">
                                        <input
                                            id="terms"
                                            type="checkbox"
                                            formControlName="terms"
                                            [value]="true"
                                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label for="terms" class="ml-2 text-sm font-medium font-sans">
                                            Ich habe die
                                            <a href="assets/legal/datenschutz.pdf" target="_blank"
                                                >Datenschutzbestimmung</a
                                            >
                                            gelesen.
                                        </label>
                                    </div>

                                    <div class="ml-4">
                                        <app-validation-error
                                            controlName="terms"
                                            errorText="Du musst den Datenschutzbestimmungen zustimmen."></app-validation-error>
                                    </div>
                                </div>

                                <app-button type="submit" class="flex justify-center font-sans">
                                    Registrieren
                                </app-button>
                            </form>
                        </div>
                    </div>
                    <h3 class="flex justify-center m-2 font-sans"> Du hast schon ein Konto? </h3>
                    <app-button
                        class="flex justify-center m-2 font-sans"
                        [routerLink]="['/auth', 'login']"
                        color="white">
                        Einloggen
                    </app-button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./complete-signup.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, SharedModule, RouterLink, LegalLinksComponent],
})
export class CompleteSignupComponent implements OnInit {
    setPasswordForm!: FormGroup;
    image = `/assets/images/woman-${Math.floor(Math.random() * 3) + 1}.jpg`;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.setPasswordForm = new UntypedFormGroup(
            {
                password: new UntypedFormControl('', [Validators.required]),
                passwordConfirm: new UntypedFormControl('', [Validators.required]),
                caretaker: new FormControl<boolean>(true),
                terms: new FormControl<boolean>(false, [Validators.requiredTrue]),
            },
            {
                validators: [(control: AbstractControl) => this.comparePasswords(control as FormGroup)],
            },
        );
    }

    comparePasswords(control: UntypedFormGroup): any {
        const password = control.get('password');
        const passwordConfirm = control.get('passwordConfirm');
        return !!password && password.value === passwordConfirm!.value ? null : { passwordConfirm: true };
    }

    submit(): void {
        if (this.setPasswordForm.valid) {
            this.store.dispatch(
                setPassword({
                    password: this.setPasswordForm.value.password,
                    token: this.route.snapshot.paramMap.get('token')!,
                    caretaker: this.setPasswordForm.value.caretaker,
                }),
            );
        }
    }

    getCountries() {
        return Object.entries(getNames('de'));
    }
}
