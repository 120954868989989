import { Directive, ElementRef, HostListener } from '@angular/core';
import { DropdownContainerDirective } from './dropdown-container.directive';

@Directive({
    selector: '[appDropdownToggle]',
})
export class DropdownToggleDirective {
    constructor(
        private dropdownContainer: DropdownContainerDirective,
        public el: ElementRef,
    ) {}

    @HostListener('click')
    click() {
        this.dropdownContainer.toggle();
    }
}
