import { reduceToValueLabelMap } from '../../shared/utility/reduce-label-value-array.helper';

export const languagesData = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Arabisch', value: 'ar' },
    { label: 'Bengalisch', value: 'bn' },
    { label: 'Chinesisch', value: 'zh' },
    { label: 'Deutsch', value: 'de' },
    { label: 'Dänisch', value: 'da' },
    { label: 'Englisch', value: 'en' },
    { label: 'Französisch', value: 'fr' },
    { label: 'Griechisch', value: 'el' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Holländisch', value: 'nl' },
    { label: 'Italienisch', value: 'it' },
    { label: 'Japanisch', value: 'ja' },
    { label: 'Javanisch', value: 'jv' },
    { label: 'Koreanisch', value: 'ko' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Norwegisch', value: 'no' },
    { label: 'Polnisch', value: 'pl' },
    { label: 'Portugiesisch', value: 'pt' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Russisch', value: 'ru' },
    { label: 'Schwedisch', value: 'sv' },
    { label: 'Spanisch', value: 'es' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Türkisch', value: 'tr' },
    { label: 'Ungarisch', value: 'hu' },
    { label: 'Vietnamesisch', value: 'vi' },
];

export const languageValueLabelMap = reduceToValueLabelMap(languagesData);
