import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '../../../user/+store/user.selectors';

@Component({
    selector: 'app-story-collection-empty',
    standalone: true,
    template: `
        <div class="flex justify-center pt-10 w-[500px] max-w-full mx-auto">
            <div>
                <div class="flex justify-center">
                    <img src="../../../../assets/artwork/book.png" alt="buch" />
                </div>
                <div class="text-center">
                    <h3 class="text-center">{{ user()?.firstName }} hat noch keine Lebenserinnerungen notiert</h3>
                    <div class="flex mt-5">
                        <p>
                            Mit Klick auf "Neue Erinnerung" kannst du gemeinsam mit {{ user()?.firstName }} beginnen
                            Erinnerungen aufzuzeichnen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./story-collection-empty.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryCollectionEmptyComponent {
    store = inject(Store);
    user = this.store.selectSignal(selectUser);
}
