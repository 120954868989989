import { NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { SubUser } from '../../+store/sub-user.model';
import { SharedModule } from '../../../shared/shared.module';
import { CaretakerService } from '../../caretaker.service';

@Component({
    selector: 'app-subuser-tile',
    standalone: true,
    template: `
        <div class="h-full w-full rounded-lg bg-gray-100 flex flex-col justify-center items-center relative shadow">
            <div class="flex justify-center">
                <img
                    class="aspect-square rounded-full object-cover"
                    ngSrc="assets/images/avatar-placeholder.svg"
                    width="90"
                    height="90"
                    [avatarSrc]="profile"
                    alt="profilbild" />
            </div>
            <div class="pt-4 text-center">
                <h4 class="font-semibold"> {{ profile.firstName }} {{ profile.lastName }} </h4>
                <span class="text-sm text-gray-400 mt-2" *ngIf="profile.city">
                    <fa-icon [icon]="faLocationDot"></fa-icon>
                    {{ profile.city }}
                </span>
            </div>
            <a class="absolute cursor-pointer inset-0" type="button" (click)="impersonate(profile.id)"></a>
        </div>
    `,
    styleUrls: ['./subuser-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'w-full',
    },
    imports: [SharedModule, NgIf, NgOptimizedImage],
})
export class SubuserTileComponent {
    faLocationDot = faLocationDot;

    @Input() profile!: SubUser;

    constructor(private caretakerService: CaretakerService) {}

    impersonate(id: string) {
        this.caretakerService.impersonate(id);
    }
}
