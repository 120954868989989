import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TippyDirective } from '@ngneat/helipopper';
import { Store } from '@ngrx/store';
import { SharedModule } from '../../../shared/shared.module';
import { selectUser } from '../../../user/+store/user.selectors';
import { CaretakerRouteNames } from '../../caretaker-routing';
import { UserActions } from '../../../user/+store/user.actions';
import { AuthActions } from '../../../auth/+store/auth.actions';

@Component({
    selector: 'app-caretaker-layout-navigation',
    standalone: true,
    imports: [AsyncPipe, FaIconComponent, NgIf, RouterLink, SharedModule, TippyDirective],
    template: `
        <nav class="bg-white h-[75px] px-12">
            <div class="flex items-center h-full">
                <div class="mr-auto items-center flex">
                    <a [routerLink]="['/', routeNames.prefix]" class="flex flex-col text-gray-500 items-center px-10">
                        <fa-icon [icon]="['fas', 'users']"></fa-icon>
                        <div class="mt-1 text-sm">Übersicht</div>
                    </a>
                </div>

                <div class="ml-auto flex items-center w-[200px] shrink justify-end">
                    <button type="button" class="flex flex-col text-gray-500 items-center px-10" (click)="logout()">
                        <fa-icon [icon]="['fas', 'arrow-right-from-bracket']"></fa-icon>
                        <div class="mt-1 text-sm">Logout</div>
                    </button>

                    <!--                    disabled until pages filled-->

                    <!--                    <a-->
                    <!--                        class="flex flex-col text-gray-500 items-center px-10"-->
                    <!--                        [routerLink]="['/', routeNames.prefix, routeNames.mySpace]"-->
                    <!--                        *ngIf="user()">-->
                    <!--                        <fa-icon [icon]="['fas', 'circle-user']"></fa-icon>-->
                    <!--                        <div class="mt-1 text-sm">Mein Bereich</div>-->
                    <!--                    </a>-->
                </div>
            </div>
        </nav>
    `,
    styleUrl: './caretaker-layout-navigation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaretakerLayoutNavigationComponent {
    routeNames = CaretakerRouteNames;
    store = inject(Store);
    user = toSignal(this.store.select(selectUser));

    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
