import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LibraryEntry } from '../../+store/library-entry/library-entry.model';
import { SharedModule } from '../../../shared/shared.module';
import { DialogModalWrapperComponent } from '../../../shared/modal/dialog-modal-wrapper/dialog-modal-wrapper.component';
import { DialogEventResponse, IsDialogModal } from '../../../shared/modal/interfaces/is-dialog-modal.interface';
import {
    DialogEvents,
    DialogModalFooterComponent,
} from '../../../shared/modal/dialog-modal-footer/dialog-modal-footer.component';
import { DialogModalHeaderComponent } from '../../../shared/modal/dialog-modal-header/dialog-modal-header.component';

export interface LibraryEntryCreateFinishFormValues {
    title: string;
    finished: boolean;
}

@Component({
    selector: 'app-library-entry-finish-creation-modal',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SharedModule,
        NgIf,
        DialogModalWrapperComponent,
        DialogModalFooterComponent,
        DialogModalHeaderComponent,
        NgClass,
    ],
    template: `
        <app-dialog-modal-wrapper>
            <app-dialog-modal-header>
                <span class="text-primary font-bold">Titel deiner Erinnerung</span>
            </app-dialog-modal-header>
            <form [formGroup]="form" class="flex flex-col gap-y-3 p-4" [class.ng-submitted]="submitted">
                <input
                    formControlName="title"
                    class="form-control"
                    placeholder="Vergib einen Titel für deine Erinnerung (Pflichtfeld)"
                    type="text" />
            </form>
            <app-dialog-modal-footer
                confirmButtonText="Speichern"
                [cancelConfirm]="form.dirty"
                (dialogEvent)="onDialogEvent($event)"></app-dialog-modal-footer>
        </app-dialog-modal-wrapper>
    `,
    styleUrl: './library-entry-finish-creation-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class LibraryEntryFinishCreationModalComponent implements IsDialogModal {
    public errors = false;
    public submitted = false;
    dialogEventResponse = new EventEmitter<DialogEventResponse<{ title: string; finished: true }>>();

    onDialogEvent(type: DialogEvents) {
        if (type === DialogEvents.confirm) {
            this.submitted = true;

            if (!this.form.valid) {
                return;
            }

            this.dialogEventResponse.emit({ type, value: { title: this.form.value.title!, finished: true } });
        } else {
            this.dialogEventResponse.emit({ type });
        }
    }

    @Input()
    set libraryEntry(value: LibraryEntry) {
        this._libraryEntry = value;

        if (this.form) {
            this.form.patchValue(value);
        }
    }

    private _libraryEntry?: LibraryEntry;

    fb = inject(FormBuilder);
    form = this.fb.nonNullable.group({
        title: ['', [Validators.required]],
    });
}
