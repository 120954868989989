import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { DateTime } from 'luxon';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullish } from '../../../shared/utility/filter-nullish.operator';
import { adapter, librariesFeatureKey, LibraryEntryState } from './library-entry.reducer';

export const selectLibraryEntryState = createFeatureSelector<LibraryEntryState>(librariesFeatureKey);

export const {
    selectIds: selectLibraryEntryIds,
    selectEntities: selectLibraryEntryEntities,
    selectAll,
    selectTotal: selectLibraryEntryTotal,
} = adapter.getSelectors(selectLibraryEntryState);

export const selectLibraryEntryLoading = () => select(createSelector(selectLibraryEntryState, state => state.loading));

export const selectLibraryEntryWithImpulse = () =>
    pipe(
        select(selectLibraryEntryAll),
        map(entities => entities.filter(entry => !!entry.impulseQuestionId)),
    );

export const selectLibraryEntryWithoutImpulse = () =>
    pipe(
        select(selectLibraryEntryAll),
        map(entities => entities.filter(entry => !entry.impulseQuestionId)),
    );

export const selectLibraryEntryUnfinished = () =>
    pipe(
        select(selectLibraryEntryAll),
        map(entities => entities.filter(entry => !entry.finished)),
    );

export const selectLibraryEntryByIdNoNull = (id: string) =>
    pipe(
        select(selectLibraryEntryEntities),
        map(entities => entities[id]),
        filterNullish(),
    );

export const selectLibraryEntryById = (id: string) =>
    pipe(
        select(selectLibraryEntryEntities),
        map(entities => entities[id]),
    );

export const selectLibraryEntryAll = createSelector(selectAll, libraryEntries =>
    libraryEntries.sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()),
);
