import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InfoService } from '../../../shared/info.service';

@Component({
    selector: 'app-channel-three',
    template: `
        <app-default-layout-with-navigation>
            <div class="p-4 grow flex flex-col overflow-hidden">
                <div class="bg-white grow rounded-lg shadow-lg flex relative overflow-hidden">
                    <iframe
                        class="flex w-full"
                        src="https://teresa.whereby.com/teresa-three189b03f68-46aa-4228-8369-331654b0bdbf"
                        allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"></iframe>
                </div>
            </div>
        </app-default-layout-with-navigation>
    `,
    styleUrls: ['./channel-three.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelThreeComponent implements OnInit {
    constructor(private infoService: InfoService) {}

    ngOnInit(): void {
        this.infoService.setCurrentPage('channel-voice');
    }
}
