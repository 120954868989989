import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    asyncScheduler,
    debounceTime,
    fromEvent,
    observeOn,
    of,
    retry,
    Subject,
    switchMap,
    takeUntil,
    tap,
    throttle,
    throttleTime,
} from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LibraryEntryActions } from '../../../../library/+store/library-entry/library-entry.actions';
import { LibraryEntryMedia } from '../../../../library/+store/library-entry/library-entry.model';
import { VoiceRecorderComponent } from '../voice-recorder.component';

@UntilDestroy()
@Component({
    selector: 'app-voice-recorder-player',
    template: `
        <div class="col-start-1 row-start-1 ml-3">
            <app-button rounded="rounded-full" [square]="true" (click)="togglePlay()">
                <fa-icon
                    [icon]="['fas', 'play']"
                    size="lg"
                    [fixedWidth]="true"
                    *ngIf="!playing() && !waitingForPlaying()"></fa-icon>
                <fa-icon
                    [icon]="['fas', 'loader']"
                    size="lg"
                    [fixedWidth]="true"
                    *ngIf="waitingForPlaying()"
                    class="animate-spin inline-block"></fa-icon>

                <fa-icon [icon]="['fas', 'pause']" size="lg" [fixedWidth]="true" *ngIf="playing()"></fa-icon>
            </app-button>
        </div>

        <div class="row-start-1 col-start-2 pr-3 py-3">
            <ng-container *ngIf="durationForSeeker() > 0">
                <div class="seeker-bar w-full bg-beige rounded-full">
                    <input
                        type="range"
                        name="seeking"
                        #seekerInput
                        [attr.min]="0"
                        [attr.max]="durationForSeeker()"
                        [value]="roundUp(audioPlayer.currentTime)"
                        (input)="seekerChange($event)" />
                </div>
            </ng-container>
        </div>

        <div class="flex flex-col items-center justify-center row-start-2 col-span-3 mt-3">
            <div class="px-4 py-1 bg-yellow-200 text-yellow-700 font-semibold rounded-full" *ngIf="conversionRunning()">
                Die Aufnahme ist noch nicht komplett verarbeitet
            </div>
            <div class="px-4 py-1 bg-red-200 text-red-700 font-semibold rounded-full" *ngIf="errorMsg()">
                {{ errorMsg() }}
            </div>
            <div class="tabular-nums row-start-2 col-start-2 w-full text-center mt-3"
                >{{ currentTime() | date: 'mm:ss' }} / {{ duration() | date: 'mm:ss' }}</div
            >
        </div>

        <div class="bg-blue-500 row-start-3 col-span-full hidden">
            <audio
                [src]="mediaSrc"
                [controls]="false"
                #audioPlayer
                (play)="playerPlaying()"
                (pause)="playerPaused()"
                (timeupdate)="timeChange($event)"></audio>
        </div>
    `,
    styleUrl: './voice-recorder-player.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'contents',
    },
})
export class VoiceRecorderPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('audioPlayer', { static: true })
    audioPlayer!: ElementRef<HTMLAudioElement>;

    @Input() libraryEntryId!: string;

    @Input()
    set mediaEntry(entry: LibraryEntryMedia) {
        this._mediaEntry.set(entry);
        this.mediaSrc = entry.location;
        this.mediaId = entry.id;
    }

    $playingStopped = new Subject<boolean>();

    wrapperComponent = inject(VoiceRecorderComponent);
    store = inject(Store);
    actions = inject(Actions);
    mediaSrc!: string;
    mediaId!: string;

    _mediaEntry = signal<LibraryEntryMedia | null>(null);

    duration = computed(() => {
        return this._mediaEntry()?.duration ?? 0;
    });

    durationForSeeker = computed(() => Math.ceil(this.duration() / 1000));

    conversionRunning = computed(() => {
        return !!this._mediaEntry()?.conversionRunning;
    });

    errorMsg = signal<string | null>(null);
    infoMsg = signal<string | null>(null);

    playing = signal<boolean>(false);
    waitingForPlaying = signal<boolean>(false);
    currentTime = signal(0);

    ngOnInit() {
        this.wrapperComponent?.currentTime.emit(0);
    }

    ngOnDestroy() {
        this.stopPlaying();
    }

    playerPlaying() {
        // console.log('PLAY EVENT', event);
        this.waitingForPlaying.set(false);
        this.playing.set(true);
    }

    roundUp(number: number) {
        return Math.ceil(number);
    }

    playerPaused() {
        // console.log('PAUSE EVENT', event);
        this.playerStopped();
    }

    timeChange(event: any) {
        const timeInMs = event.target.currentTime * 1000;
        this.currentTime.set(timeInMs);
        this.wrapperComponent?.currentTime.emit(timeInMs);
    }

    seekerChange(event: any) {
        this.audioPlayer.nativeElement.currentTime = event.target.value;
    }

    async togglePlay() {
        if (this.playing() || this.waitingForPlaying()) {
            await this.stopPlaying();
        } else {
            this.waitingForPlaying.set(true);

            if (this.mediaSrc) {
                this.startPlaying();
            } else {
                this.reloadLibraryEntryForAudioSrc();
            }
        }
    }

    async startPlaying() {
        await this.audioPlayer.nativeElement.play();
    }

    async stopPlaying() {
        this.audioPlayer.nativeElement.pause();
        this.playerStopped();
    }

    playerStopped() {
        this.$playingStopped.next(true);
        this.waitingForPlaying.set(false);
        this.playing.set(false);
    }

    // this will trigger a reload of the current library entry
    // on error or if media has no location we try again 10 times with 10s delay
    // then we stop playing and show error
    reloadLibraryEntryForAudioSrc() {
        of(this.libraryEntryId)
            .pipe(
                tap(id => console.log('DISPATCH LOAD ACTION', id)),
                tap(id => this.store.dispatch(LibraryEntryActions.loadLibraryEntryByID({ id }))),
                switchMap(() =>
                    this.actions.pipe(
                        ofType(
                            LibraryEntryActions.loadLibraryEntryByIDSuccess,
                            LibraryEntryActions.loadLibraryEntryByIDFailure,
                        ),
                        first(),
                        map(action => {
                            if (
                                action.type === LibraryEntryActions.loadLibraryEntryByIDSuccess.type &&
                                !!action.libraryEntry.media.find(media => media.id === this.mediaId)?.location
                            ) {
                                return action;
                            }

                            throw new Error();
                        }),
                    ),
                ),
                retry({ delay: 1000, count: 10 }),
                observeOn(asyncScheduler),
                takeUntil(this.$playingStopped),
                untilDestroyed(this),
            )
            .subscribe({
                next: () => {
                    this.startPlaying();
                },
                error: err => {
                    console.log('too many retries to load try later');
                    this.stopPlaying();
                },
            });
    }
}
