import { AsyncPipe, JsonPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CreateSubUserFragments } from '../../../caretaker/pages/caretaker-create-subuser/caretaker-create-subuser.component';
import { AvatarUploadControlMode } from '../../../shared/components/avatar-upload-control/avatar-upload-control.component';
import { SharedModule } from '../../../shared/shared.module';
import { UserActions } from '../../../user/+store/user.actions';
import { User } from '../../../user/+store/user.model';
import { selectNonNullUser, selectUserSavingInProgress } from '../../../user/+store/user.selectors';
import { UpdateUserDto } from '../../../user/dtos/update-user.dto';
import { ClientProfileCoreFormComponent } from '../../components/client-profile-core-form/client-profile-core-form.component';
import { ClientProfileInfoFormComponent } from '../../components/client-profile-info-form/client-profile-info-form.component';
import { ClientProfilePhotoFormComponent } from '../../components/client-profile-photo-form/client-profile-photo-form.component';
import { ClientProfileTabNavigationComponent } from '../../components/client-profile-tab-navigation/client-profile-tab-navigation.component';
import { ClientProfileEditControls } from '../../interfaces/client-profile-edit.interface';
import { CanDeactivateService } from '../../../shared/services/can-deactivate.service';

@UntilDestroy()
@Component({
    selector: 'app-client-profile-edit',
    standalone: true,
    imports: [
        FaIconComponent,
        FormsModule,
        NgIf,
        ClientProfileCoreFormComponent,
        SharedModule,
        AsyncPipe,
        ClientProfileTabNavigationComponent,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        ClientProfilePhotoFormComponent,
        ReactiveFormsModule,
        ClientProfileInfoFormComponent,
        JsonPipe,
        RouterOutlet,
    ],
    template: `
        <div class="flex flex-col bg-white min-h-full max-w-[1000px] w-full mx-auto">
            <app-client-profile-tab-navigation class="mx-auto"></app-client-profile-tab-navigation>

            <form (ngSubmit)="save()" class="flex flex-col mt-11">
                <app-client-profile-core-form
                    [formGroup]="form"
                    *ngIf="fragment() === fragments.coreData || !fragment()"
                    class="mx-auto"></app-client-profile-core-form>
                <app-client-profile-info-form
                    [formGroup]="form"
                    *ngIf="fragment() === fragments.information"
                    class="mx-auto"></app-client-profile-info-form>
                <app-client-profile-photo-form
                    [formGroup]="form"
                    *ngIf="fragment() === fragments.photo"
                    [user]="user"
                    [avatarMode]="AvatarUploadControlMode.update"
                    class="mx-auto">
                </app-client-profile-photo-form>

                <div class="relative mt-4 mx-auto">
                    <app-button
                        type="submit"
                        [loading]="savingInProgress$ | async"
                        [disabled]="savingInProgress$ | async"
                        [color]="valueChanged() ? 'blue' : 'primary'"
                        [class.animate-pulse]="valueChanged()"
                        >Speichern
                    </app-button>
                </div>
            </form>
        </div>

        <router-outlet></router-outlet>
    `,
    styleUrl: './client-profile-edit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProfileEditComponent {
    savingInProgress$!: Observable<boolean>;
    user?: User;
    form!: FormGroup<ClientProfileEditControls>;

    cdr = inject(ChangeDetectorRef);
    fb = inject(NonNullableFormBuilder);
    store = inject(Store);
    route = inject(ActivatedRoute);
    router = inject(Router);
    canDeactivateService = inject(CanDeactivateService);

    valueChanged = signal(false);
    fragments = CreateSubUserFragments;
    fragment = signal('');

    ngOnInit() {
        this.form = this.fb.group({
            firstName: ['', Validators.required],
            lastName: [''],
            birthDate: [''],
            birthPlace: [''],
            city: [''],
            gender: [''],
            faith: [''],
            avatar: new FormControl<File | string>('', { nonNullable: true }),
            languages: new FormControl<string[]>([], { nonNullable: true }),
            family: [''],
            relationship: [''],
            pets: [''],
        });

        this.store.pipe(selectNonNullUser(), untilDestroyed(this)).subscribe(user => {
            this.form.patchValue(user, { emitEvent: false });
            this.user = user;
            this.cdr.markForCheck();
        });

        this.savingInProgress$ = this.store.pipe(selectUserSavingInProgress());
        this.listenForValueChange();

        //todo maybe better solution? high risk of cirular function calls
        this.route.fragment.pipe(untilDestroyed(this)).subscribe(fragment => {
            if (fragment) {
                this.fragment.set(fragment);
            } else {
                this.router.navigate([], { fragment: CreateSubUserFragments.coreData, replaceUrl: true });
            }
        });
    }

    listenForValueChange() {
        this.form.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => this.valueChanged.set(true));
    }

    save() {
        if (this.form.valid) {
            this.valueChanged.set(false);
            this.form.markAsPristine();
            this.store.dispatch(
                UserActions.updateMe({
                    user: this.form.getRawValue() as UpdateUserDto,
                }),
            );
        }
        this.listenForValueChange();
    }

    canDeactivate = () => this.canDeactivateService.canDeactivate(this.form.dirty);

    protected readonly AvatarUploadControlMode = AvatarUploadControlMode;
}
