import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { genderEnum, genderValueLabelMap } from '../../../client/data/gender.data';
import { languageValueLabelMap } from '../../../client/data/languages.data';
import { User } from '../../../user/+store/user.model';

@Component({
    selector: 'app-profile-header',
    template: `
        <div class="bg-white rounded-lg p-5 w-full shadow-blur grid grid-cols-[auto_1fr] gap-x-8 text-primary">
            <img
                class="aspect-square rounded-full object-cover"
                ngSrc="assets/images/avatar-placeholder.svg"
                width="90"
                height="90"
                [avatarSrc]="user"
                alt="profilbild" />

            <div class="flex flex-col gap-y-3">
                <div class="flex">
                    <h2 class="font-sans mr-2">
                        {{ user?.firstName }} {{ user?.lastName
                        }}<span *ngIf="user?.birthDate">, {{ getAge(user!.birthDate!) }} Jahre alt</span>
                    </h2>
                </div>
                <div class="text-primary text-sm overflow-hidden">
                    <div class="flex gap-y-2 flex-wrap -ml-[17px]">
                        <div *ngIf="user?.birthDate" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'birthday-cake']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                {{ user!.birthDate | date: 'dd.MM.yyyy'
                                }}<span *ngIf="user?.birthPlace"> - {{ user?.birthPlace }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.city" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'location-dot']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ user?.city }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.pets" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'paw']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ user?.pets }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.family" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'family']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ user?.family }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.gender; let g" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', getGenderIcon(g)]" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ getGender(g!) }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.relationship" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'heart']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ user?.relationship }}</span>
                            </div>
                        </div>

                        <div *ngIf="user?.faith" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'dove']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ user?.faith }}</span>
                            </div>
                        </div>

                        <div *ngIf="getLanguages().length > 0" class="flex">
                            <div class="border-slate-300 border-r mx-2"></div>
                            <div>
                                <fa-icon [icon]="['fas', 'language']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                <span>{{ getLanguages() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./profile-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileHeaderComponent {
    @Input() user!: User | null;

    getAge(birthDate: string) {
        const d1 = DateTime.fromISO(birthDate);
        const d2 = DateTime.now();
        return Math.floor(d2.diff(d1, ['years']).years);
    }

    getGender(gender: string) {
        return genderValueLabelMap.get(gender) ?? '';
    }

    getLanguages() {
        if (this.user?.languages) {
            return this.user.languages.map(langKey => languageValueLabelMap.get(langKey)).join(', ');
        } else {
            return '';
        }
    }

    getGenderIcon(g: string) {
        switch (g) {
            case genderEnum.man:
                return 'mars';
            case genderEnum.woman:
                return 'venus';
            case genderEnum.various:
                return 'genderless';
            default:
                return 'genderless';
        }
    }
}
